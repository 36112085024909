import React from "react";

const Label = props => {

  const classes = props.small ? "at-label small" : "at-label"

  const styles = {
    backgroundColor: props.color || ""
  }

  return(
    <div className={classes} style={styles}>
      {props.children}
    </div>
  )
}

export default Label
