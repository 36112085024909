// Import the functions you need from the SDKs you need
import firebase, { initializeApp } from "firebase/app";
import  "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyB9noLRtiYkxxqKeocjhn_Jx_UhAnKOWzo",
	authDomain: "arctrade-home.firebaseapp.com",
	projectId: "arctrade-home",
	storageBucket: "arctrade-home.appspot.com",
	messagingSenderId: "237017990973",
	appId: "1:237017990973:web:d8c5f69c1eee0576db6754",
	measurementId: "G-ZDNPL8V5D6"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const db =  firebase.firestore()
