import React from 'react';

class Column extends React.Component {
    constructor() {
        super();
        this.renderClasses = this.renderClasses.bind(this);
    }

    renderClasses() {
        let klasses = [this.props.className];
        klasses.push(`column-md-${this.props.colCount}`)

        if (this.props.top) {
            klasses.push("col-top")
        }

        return klasses.join(" ");
    }

    render() {

        const style = {
            alignItems: this.props.alignCenter ? "center" : "",
            display: this.props.flex ? "flex" : "block",
            justifyContent: this.props.justifyCenter ? "center": "",
            padding: this.props.noPadding ? 0:null
        }


        return (
            <div className={this.renderClasses()} style={style}>
                {this.props.children}
            </div>
        )
    }
}

export default Column
