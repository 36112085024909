import React, {useState, useEffect, useMemo} from "react"
import {Link} from "react-router-dom";

// import bkImage from "../../../../assets/images/background/windmills2.png"
// import bkImage from "../../../../assets/images/background/car2.jpg"
import diagram from "../../../../assets/images/arctradeops/opsdiagram2.png"

// const bkImage = "https://cdn.arctrade.com/brand/arctrade-background-1.png"
// const bkImage = "https://cdn.arctrade.com/brand/arctrade-background-5.png"
const bkImage = "https://cdn.arctrade.com/brand/Arctrade-Pattern-Cream-03.svg"
// const bkImage = "https://images.unsplash.com/photo-1562578013-a1a2cc6e877d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1286&q=80"
// const bkImage = "https://images.unsplash.com/photo-1632103996718-4a47cf68b75e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
// const bkImage = "https://images.unsplash.com/photo-1508001300512-4763bb1c5583?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1035&q=80"
// const bkImage = "https://images.unsplash.com/photo-1491469853134-73161cbf3471?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2428&q=80"
// const bkImage = "https://images.unsplash.com/photo-1605980413988-9ff24c537935?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"
// const bkImage = "https://images.unsplash.com/photo-1510524411458-984a6adc3dcb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2346&q=80"
// const bkImage = "https://images.unsplash.com/photo-1628349869310-d489423ec409?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1975&q=80"
// const bkImage = "https://images.unsplash.com/photo-1594389615321-4f50c5d7878c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80"
import styled from "styled-components";
import DotGrid from "../../../../components/DotGrid";
import {Column, Row, Visible} from "@arctrade/relay";
import Graphic, {useResizeKey} from "../../components/Graphic";

const MaxContentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #37002d;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`


const BackgroundImage = styled.div`
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${bkImage});
  background-position: center;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  //padding: 35px;
  flex-shrink: 0;
  
  @media only screen and (max-width: 1100px) {
   width: 100%;
    height: 40vh;
    border-bottom: 3px solid #fff;
  }
  
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`
const BackgroundImageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;
  padding-top: 64px;
  background: #919191;
  background: #37002d;
  //background: #fff;
  display: flex;
  @media only screen and (max-width: 1100px) {
   flex-direction: column;
    
  }
  @media only screen and (max-width: 700px) {
   flex-direction: column;
    padding: 20px;
    padding-top: 64px;
  }

`

const HeroTextContainer = styled.div`
  padding: 25px;
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-grow: 1;

  @media only screen and (max-width: 1100px) {
  }
  
  @media only screen and (max-width: 700px) {
    min-width: 100%;
    padding: 25px;
    height: auto;
    flex-grow: 1;
    justify-content: center;
    padding: 0;
  }
`
const HeroText = styled.div`
  position: relative;
  font-size: 50px;
  line-height: 60px;
  font-weight: 500;
  color: #fff;
  width: 100%;
  max-width: 700px;
  //text-shadow: 1px 1px 6px black;
  @media only screen and (max-width: 1350px) {
    font-size: 40px;
    line-height: 50px;

  }
  @media only screen and (max-width: 1100px) {
    font-size: 35px;
    line-height: 45px;
    padding: 0;
    max-width: 1000px;
  }
  
  @media only screen and (max-width: 700px) {
    font-size: 35px;
    line-height: 42px;
  }
  @media only screen and (max-width: 550px) {
    font-size: 28px;
    line-height: 38px;
  }
`

const SubHeroText = styled.div`
  //margin: 10px 0;
  font-size: 17px;
  line-height: 28px;
  font-weight: 400;
  color: #fff;
  max-width: 500px;
  margin: 18px 0;
  //text-shadow: 1px 1px 6px black;
  @media only screen and (max-width: 700px) {
    margin-top: 10px;
    font-size: 14px;
    line-height: 22px;
  }
`

const DemoButton = styled(Link)`
  //background: #37002d !important;
  background: none;
  display: inline;
  padding: 7px 13px;
  border-radius: 4px;
  color: #FFF !important;
  font-weight: 500;
  border: 2px solid #fff;
  @media only screen and (max-width: 700px) {
  }
`

const Diagram = styled.img`
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  @media only screen and (max-width: 700px) {
    width: 90%;
  }
`

const HeaderLogoContainer = styled.div`
  //width: 100%;
  //border-bottom: 3px solid #fff;
  padding-bottom: 20px;
  margin-bottom: 30px;
  position: absolute;
  top: 80px;
  left: 30px;
  @media only screen and (max-width: 700px) {
   //border: none;
    margin-bottom: 10px;
  }
`
const MobileImageDarkener = styled.div`
   //background: #37002d;
  //background: rgb(55,0,45);
  //background: linear-gradient(0deg, rgba(55,0,45,1) 0%, rgba(55,0,45,0) 100%);
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`

const Content = styled.div`
  z-index: 2;
  //padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 1100px) {
   flex-grow: 1;
    padding: 0;
  }
`


const ContentContainer = styled.div`
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  display: flex;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 1100px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
  }
`


const Header = () => {
    const [isAtTop, setIsAtTop] = useState(true)
    const resizeKey = useResizeKey()
    const handleScroll = e => {
        setIsAtTop(prev => {
            const scrollY = window.scrollY
            return scrollY === 0
        })

    }

    useEffect(() => {
        document.addEventListener("scroll", handleScroll)
    }, [])


    const isBreakpoint = useMemo(() => {
        return window.innerWidth < 1100
    },[resizeKey])

    return <div>
        <MaxContentWrapper>

            <BackgroundImageContainer>
                <ContentContainer>
                    <Content>



                        <Visible visible={   isAtTop && !isBreakpoint}>
                            <HeaderLogoContainer>
                                <img
                                    src={"https://cdn.arctrade.com/brand/Arctrade_Wordmark_White.svg"}
                                    width={460}/>
                            </HeaderLogoContainer>
                        </Visible>

                    <Row noPadding>

                        {/*<Visible visible={isAtTop &&  isBreakpoint}>*/}
                        {/*    <img*/}
                        {/*        style={{marginBottom:'30px'}}*/}
                        {/*        src={"https://cdn.arctrade.com/brand/Arctrade_Wordmark_White.svg"}*/}
                        {/*        width={320}/>*/}
                        {/*</Visible>*/}
                        <div style={{padding:"30px 0"}}>
                            <DemoButton to={"/request-demo"}>Schedule A Demo</DemoButton>
                        </div>
                        <Column noPadding >
                            <HeroText>
                                Architects of the digital energy infrastructure
                            </HeroText>
                        </Column>
                        <Column  noPadding>
                            <SubHeroText>
                                Arctrade is transforming the energy sector by integrating data, operations, markets, and systems
                                to enhance efficiency and create comprehensive capabilities for developing new energy products
                                and services.
                                {/*This innovative approach drives progress and expansion throughout the industry,*/}
                                {/*ultimately resulting in substantial advantages for energy companies and consumers alike.*/}
                                {/*We are the backbone for retail energy transactions*/}
                            </SubHeroText>
                        </Column>

                    </Row>

                    {/*<DotGrid*/}
                    {/*    width={500}*/}
                    {/*    height={200}*/}
                    {/*    fill={"#fff"}*/}
                    {/*    spacing={30} radius={1} style={{*/}
                    {/*    position: "absolute",*/}
                    {/*    top: 0,*/}
                    {/*    bottom :0,*/}
                    {/*    left: -100*/}
                    {/*}}/>*/}

                    </Content>
                <BackgroundImage>
                    <Graphic/>
                </BackgroundImage>
                    <MobileImageDarkener/>
                </ContentContainer>
            </BackgroundImageContainer>

            {/*<HeroTextContainer>*/}

            {/*<Diagram src={diagram}/>*/}
            {/*<HeroText>*/}
            {/*    <i><b>The Operating System</b></i><br/> for Retail Energy*/}
            {/*    /!*<GridDots/>*!/*/}
            {/*</HeroText>*/}
            {/*/!*<HeroText>*!/*/}
            {/*/!*    <i><b>Transforming</b></i><br/> Retail Energy Operations*!/*/}
            {/*/!*    /!*<GridDots/>*!/*!/*/}
            {/*/!*</HeroText>*!/*/}
            {/*<SubHeroText>*/}
            {/*    Energy markets are complicated, <i>ArcTrade makes them simple.</i>*/}
            {/*</SubHeroText>*/}
            {/*<DemoButton href={"https://calendly.com/arctrade-demo/arctrade-demo"} target="_blank">Schedule A Demo</DemoButton>*/}
            {/*</HeroTextContainer>*/}
        </MaxContentWrapper>
    </div>
}

export default Header
