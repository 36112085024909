import React from "react";
import styled from "styled-components"
import ViewTemplate from "../view_template";
import Carousel from "../../components/carousel/carousel";
import SupplierAnimation from "../../components/animations/SupplierAnimation";
import CustomerAnimation from "../../components/animations/CustomerAnimation"
import MarketAnimation from "../../components/animations/MarketAnimation"
import SrcrollAnimation from "../../components/ScrollAnimation"

const MaxContentWrapper = styled.div`
     max-width: 1400px;
     margin: 0 auto;
     position: relative;
     padding: 0 15px;
`

function Home(props) {
        return (
        <ViewTemplate pageTitle={"Home"}>
            <MaxContentWrapper>
            <Carousel
                height={"calc(90vh - 140px)"}
                slideSpeed={6000}
                arrows={false}
                slides={[
                    {
                        title: {
                            type: "text",
                            text: "Enterprise Solutions"
                        },
                        content: <SupplierAnimation />,
                        bkColor: "#000",
                        description: "End-to-end digitization of the entire retail energy operations"
                    },
                    {
                        title: {
                            type: "text",
                            text: "Enterprise Customers"
                        },
                        content: <CustomerAnimation />,
                        bkColor: "#000",
                        description: "ArcTrade's proprietary platform enables centralizing your energy portfolio management needs"
                    },
                    {
                        title: {
                            type: "text",
                            text: "The Energy Marketplace"
                        },
                        content: <MarketAnimation/>,
                        bkColor: "#000",
                        description: "ArcTrade's end-to-end online energy trading marketplace directly connects energy retailers and consumers\n"
                    },
                ]}/>
            </MaxContentWrapper>
            {/*<SrcrollAnimation/>*/}
        </ViewTemplate>
    )
}

export default Home
