import React from "react";
import arcLogo from "../../assets/images/logo/arctradeLogo_icon.png"
import ModuleRect from "./_ModuleRect";
import ConnectLine from "./_ConnectLine"

const getRandomConnection = connections => {
    return connections[Math.floor(Math.random()*connections.length)]
}

class SupplierAnimation extends React.Component{
    constructor(){
        super();

        this.INNER_RADIUS = 190
        this.RECT_WIDTH = 80

        this.CONNECTIONS = [
            {
                name: "CRM_ACCOUNT_MANAGEMENT",
                nodes: ["CRM", "ACCOUNT_MANAGEMENT"],
                path: "M 300 100 V 200 H 320 V 300 H 400 V 240 H 440 V 140",
            },
            {
                name: "ISO_SETTLEMENT_BILLING",
                nodes: ["ISO_SETTLEMENTS", "BILLING"],
                path: "M 120 300 H 180 V 320 H 300 V 500",
            },
            {
                name: "PRICING_CONTRACT",
                nodes: ["PRICING", "CONTRACTS"],
                path: "M 520 300 H 480 V 320 H 340 V 420 H 360 V 440 H 440",
            }


        ];

        this.state = {
            currentConnect: getRandomConnection(this.CONNECTIONS)
        }
    }

    setMargin = () => {

    }

    componentDidMount() {
        this.setMargin()

        setInterval(() => {
            this.setState({currentConnect: getRandomConnection(this.CONNECTIONS)})
        }, 2000)

    }

    renderConnections = () => {
        return this.CONNECTIONS.map((connection, i) => {
            return <ConnectLine key={i} name={connection.name} path={connection.path} show={this.state.currentConnect.name === connection.name}/>
        })
    }

    renderModules = () => {
        const mods = [
            {id: "PRICING",name: "Contracts", deg: 0},
            {id: "CONTRACTS",name: "Risk Management", deg: 45},
            {id: "EDI",name: "EDI", deg: 90},
            {id: "BILLING",name: "Billing",  deg: 135},
            {id: "ISO_SETTLEMENTS",name: "ISO Settlements", deg: 180},
            {id: "MARKET_DATA",name: "Data Management", deg: 225},
            {id: "CRM", name: "CRM", posX: 100, posY: 240, deg: 270},
            {id: "ACCOUNT_MANAGEMENT", name: "Pricing Engine", posX: 100, posY: 240, deg: 315},
        ]

        return mods.map((module, i) => <ModuleRect key={i} module={module} RECT_WIDTH={this.RECT_WIDTH} INNER_RADIUS={this.INNER_RADIUS} currentConnect={this.state.currentConnect}/>)

    }

    renderGrid = () => {
        const lines = [];
        let countX = 0,
            countY = 0,
            distance = 20

        while (countX <= 50) {
            lines.push(
                <line x1="0" y1={countX * distance} x2="600" y2={countX * distance}
                      style={{stroke: "#00b575", strokeWidth: 0.5}}/>
            )
            countX++
        }
        while (countY <= 50) {
            lines.push(
                <line x1={countY * distance} y1="0" x2={countY * distance} y2="600"
                      style={{stroke: "#00b575", strokeWidth: 0.5}}/>
            )
            countY++
        }

        return lines
    }


    render(){
        return (
            <svg width={600} height={600} style={{marginLeft: "auto", marginRight: "auto"}}>
                <defs>
                    <radialGradient id="myGradient">
                        <stop offset="60%" stop-color="#000" stopOpacity={0}/>
                        <stop offset="100%" stop-color="#000"/>
                    </radialGradient>
                    <linearGradient id="grad1">
                        <stop offset="0%" stop-color="#fff"/>
                        <stop offset="100%" stop-color="#fff" stopOpacity="1"/>
                    </linearGradient>
                    <filter id="f2" x="0" y="0" width="200%" height="200%">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="10"/>
                        <feOffset dx="0" dy="20"/>
                        <feColorMatrix values="0     0     0     0     0
                                      0     0     0     0     0
                                      0     0     0     0     0
                                      0     0     0     0.7     0"/>
                        <feMerge>
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>

                    </filter>
                </defs>
                <g mask="url(#mask1)">
                    {this.renderGrid()}
                </g>
                <rect width={600} height={600} fill="url(#myGradient)" />
                {this.renderConnections()}
                {this.renderModules()}
                <circle cx={300} cy={300} r={80} fill="#027566" filter="url(#f2)"/>
                <image href={arcLogo} x={300 - 60} y={300 - 60} height="120" width="120"/>
            </svg>
        )
    }

}

export default SupplierAnimation
