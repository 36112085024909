import React, { useEffect} from "react";
import {motion, AnimatePresence, useAnimation} from "framer-motion"

function Message(props) {
    const controls = useAnimation()


    const icon = {
        hidden: {
            y: 300,
            opacity: 0
        },
        visible: {
            y: 280,
            opacity: 1
        }
    }

    const text = {
        hidden: {
            y: 300,
            opacity: 0
        },
        visible: {
            y: 280,
            opacity: 1
        }
    }


    useEffect(() => {
        controls.start("hidden")
        controls.start("visible")
    })

    const renderText = () => {
        const t = props.text

        if(t.length === 1){
            return(<motion.text
                textAnchor="middle"
                x={300}
                y={60}
                fontSize={"20pt"}
                fontWeight={"bold"}
                variants={text}
                initial="hidden"
                animate={controls}
                transition={{
                    delay: 0.8,
                }}
                exit={{ opacity: 0 }}
            >{t}</motion.text>)
        } else {
            return(
                <React.Fragment>
                    <motion.text
                        textAnchor="middle"
                        x={300}
                        y={50}
                        fontSize={"20pt"}
                        fontWeight={"bold"}
                        variants={text}
                        initial="hidden"
                        animate={controls}
                        transition={{
                            delay: 0.8,
                        }}
                        exit={{ opacity: 0 }}
                    >{t[0]}</motion.text>
                    <motion.text
                        textAnchor="middle"
                        x={300}
                        y={80}
                        fontSize={"20pt"}
                        fontWeight={"bold"}
                        variants={text}
                        initial="hidden"
                        animate={controls}
                        transition={{
                            delay: 0.8,
                        }}
                        exit={{ opacity: 0 }}
                    >{t[1]}</motion.text>
                </React.Fragment>

            )
        }

    }

    return (
        <AnimatePresence>
            {/*<text>{props.rand}</text>*/}
            {
                props.show && <React.Fragment>
                    {renderText()}
                    <motion.rect
                        x={200}
                        y={120}
                        width={200}
                        height={30}
                        rx={4}
                        fill={"#00b575"}
                        variants={icon}
                        initial="hidden"
                        animate={controls}
                        exit={{ opacity: 0 }}
                        opacity={1}
                        transition={{
                            delay: 0.8,
                        }}

                        style={{strokeWidth: 1}}
                        // onAnimationComplete={onComplete}
                    />
                    <motion.text
                        textAnchor="middle"
                        x={300}
                        y={140}
                        fontSize={"10pt"}
                        fontWeight={"bold"}
                        variants={text}
                        initial="hidden"
                        animate={controls}
                        transition={{
                            delay: 0.8,
                        }}
                        exit={{ opacity: 0 }}
                    >{props.btnText}</motion.text>
                </React.Fragment>
            }
        </AnimatePresence>

    )

}

export default Message

