import React from "react";
import {connect} from 'react-redux';

import ViewTemplate from "./../view_template";
import PageHeader from "../../components/page-header/page-header";
import Section from "../../components/section/section";
import Column from "../../components/column/column";
import Form from "../../components/forms/form";
import Input from "../../components/forms/input";

import styled from "styled-components";

const PAGE_TITLE = "For Retail Suppliers";


const MaxContentWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

const SectionContainer = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
  width: 100%;
`
const ContentTitle = styled.div`
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 10px;
  @media only screen and (max-width: 700px){
    font-size: 20px;
    line-height: 25px;
  }
`
const ContentSubTitle = styled.div`
  font-size: 18px;
  line-height: 26px;
  //font-weight: 500;
  @media only screen and (max-width: 700px){
    font-size: 16px;
    line-height: 24px;
  }
`

const SectionBody = styled.div`
  font-size: 14px;
  line-height: 22px;
`

const ForRetailSuppliers =() => {
	return <ViewTemplate pageTitle={PAGE_TITLE}>
		<PageHeader subtitle={"The Energy Operating System"} title={"eOS"}/>
		<Section>
			<MaxContentWrapper>

				<Column colCount="7">
				<ContentTitle>
					Your one platform solution.
				</ContentTitle>
					<ContentSubTitle>
						Arctrade's Energy Operating System (eOS) is the only fully end-to-end platform
						that enables retailers of all sizes and stages to operate on one system.</ContentSubTitle>
				</Column>
				<Column colCount="5">
					{/*<img src={"https://cdn.arctrade.com/brand/Arctrade_Brand_White_Pattern1.svg"}*/}
					{/*	 width={"100%"}/>*/}
				</Column>
				<Column colCount="7">
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>EDI & Data Services</SectionTitle>
							<SectionBody>
								We offer digital data services connecting you with utilities through EDI, XML, web crawling, SMT and data sync. Our system processes data promptly with easy exception handling.
								Our EDI experts complete utility registration and EDI certification tests for fast entry to market.
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Pricing Engine</SectionTitle>
							<SectionBody>
								Our pricing engine is highly adaptable, allowing you to easily create customized products such as block & index with collars, solar/storage buybacks, 24x7 CFE, etc., empowering
								your innovation in the evolving energy market. Our digital contracts and evergreen automation guarantee timely and accurately billing.
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Billing Engine</SectionTitle>
							<SectionBody>
								Billing is automated for your unique products. The engine accepts pre-pay, budget pay, and other payment plans.
								It handles single/dual bills for one or thousands of accounts, tracks swings, material change, tag or rate change,
								re-settlements, new charge codes, and offers shadow billing for transparency.
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Broker Commission</SectionTitle>
							<SectionBody>
								We offer flexible commission structures, including pre-pay, residual pay, true ups,
								and cancel rebills. Our system automates commission calculation and delivery for timely payments and better broker relationships. Monthly
								reports provide you insights to optimize sales strategies.
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Load Forecasting</SectionTitle>
							<SectionBody>
								Our algorithm uses meter life cycle data to project hourly load, analyzing usage patterns, weather data, and updating meter records in real-time.
								Allowing you to promptly reconcile discrepancies between forecast, ISO settled, and utility-billed usage.
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Scheduling & Settlements</SectionTitle>
							<SectionBody>
								Our platform offers automated daily scheduling with the flexibility to make changes before the ISO deadline.
								It utilizes a shadow settlement process for accurate and complete settlement data, enabling easy
								retail and wholesale cost reconciliation.
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Risk Management ETRM</SectionTitle>
							<SectionBody>
								We manage risk across data, market, operations, and credit. Our ETRM ensures data integrity and accuracy through a single source of truth,
								and offers wholesale/retail trade capture, position management, and real time enterprise value by customer segment, market, sales brand.
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Accounting & Finance</SectionTitle>
							<SectionBody>
								Our GL system generates detailed journal entries for billed/unbilled usage, revenue, cost, write-offs, supports accrual and actualization
								for calendar months, and integrates with accounting software to streamline month-end closing.
							</SectionBody>
						</SectionContainer>
					</div>
				</Column>
				<Column colCount="5" noPadding >

				</Column>
			</MaxContentWrapper>
		</Section>
	</ViewTemplate>
}

export default ForRetailSuppliers
