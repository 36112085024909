import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {motion} from "framer-motion";

import {Link} from "react-router-dom"
import Portal from "../Portal";
import {Popup, Title} from "@arctrade/relay";


const DropdownContainer = styled.div`
    background: #fff;
    border-radius: 3px;
    max-width: 350px;
    z-index: 9999;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
    padding: 7px 15px;
`

const DropdownTitle = styled.div`
    font-size: 9pt;
    color: gray;
    font-family: "IBM Plex Serif";
    padding: 7px 0 ;
    //margin-left: 10px;
    //margin-right: 10px;
     @media only screen and (max-width: 700px){
        padding-top: 0px;
        font-size: 25px;
    } 
`
const DropdownListItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px 10px 10px;
`
const DropdownItem = styled(Link)`
    width: 100%;
    max-width: 100%;
    padding: 15px;
    
    @media only screen and (max-width: 700px){
        background: red;
    } 
`

const DropdownItemText = styled.div`
    font-size: 17px;
    font-weight: 600;
    padding: 6px 0;
    color: #37002d;
     @media only screen and (max-width: 700px){
        padding-top: 30px;
        font-size: 25px;
    } 
`
const DropdownItemDescription = styled.div`
    font-size: 13px;
    color: #000;
`

const StyledLink = styled(Link)`
    color: ${props => {
        if(!props.isAtTop) {
            return  "#37002d"
        }
            
        return props.isHome? "#fff" :"#fff"
    }} !important;
    font-size: 9pt;
    font-weight: 400;
    //margin-left: 15px;
    //padding: 4px 12px;
    font-size: 13px !important;
    border-radius: 3px;
      
    @media only screen and (max-width: 700px){
        padding-top: 0px;
        padding-left: 30px;
        font-size: 25px;
        margin-left: 0;
    } 
`
const StyledDropdownLink = styled.button`
    font-size: 13px;
    font-weight: 400;
    //margin: 0px 10px;
    background: none;
    border: none;
    padding: 6px 9px; 
    border-radius: 3px;
    font-weight: 500;
    color: ${props => {
        if(!props.isAtTop) {
            return  "#37002d"
        }

        return props.isHome? "#fff" :"#fff"
    }} !important;
    cursor: pointer;
`
const StyledButton = styled.button`
    background: ${props => {
        if(!props.isAtTop) {
            return  "#37002d"
        }

        return props.isHome? "#37002d" :"#e1d7f0"
    }};
   
    font-size: 9pt;
    font-weight: 400;
    margin-left: 15px;
    background: none;
    border: none;
    padding: 6px 9px;
    border-radius: 3px;
    cursor: pointer;
     @media only screen and (max-width: 700px){
        margin-top: 30px;
        margin-left: 30px;
         width: 50%;
         font-size: 15px;
         padding: 10px 13px;
    } 
`

const Badge = styled.label`
    font-size: 11px;
    font-size: 11px;
    margin-left: 10px;
    border-radius: 9999px;
    padding: 1px 9px;
    color: rgb(0, 181, 117);
    border: 1px solid rgb(0, 181, 117);
`

function NavItem(props) {
    let linkRef = React.createRef();
    let dropdownRef = React.createRef()
    const [open, setOpen] = useState(false)
    const [dropdownAnchor, setDropdownAnchor] = useState([0, 0])
    const [dropdownWidth, setDropdownWidth] = useState(0)


        useEffect(() => {
            if(open){
                const bound = linkRef.current.getBoundingClientRect()

                setDropdownAnchor([bound.x + bound.width, bound.y + bound.height + 10])
            }
        }, [open])

    useEffect(() => {
        if (linkRef.current) {
            setDropdownAnchorPos()
            window.addEventListener("resize", setDropdownAnchorPos)
        }

        if (dropdownRef.current) {
            const bound = dropdownRef.current.getBoundingClientRect()

            setDropdownWidth(bound.width)
        }

        return () => {
            window.removeEventListener("resize", setDropdownAnchorPos)
        }
    }, dropdownAnchor)

    const setDropdownAnchorPos = () => {
        if (linkRef.current) {
            const bound = linkRef.current.getBoundingClientRect()

            setDropdownAnchor([bound.x + bound.width, bound.y + bound.height + 10])
        }
    }

    const closeDropdown = (e) => {
        e.stopPropagation()
        setOpen(false)
        document.removeEventListener("click", closeDropdown)
        window.removeEventListener("resize", closeDropdown)
    }

    const openDropdown = (e) => {
        e.stopPropagation()

        if (open) {
            return
        }

        setOpen(true)
        document.addEventListener("click", closeDropdown)
        window.addEventListener("resize", closeDropdown)
    }

    const renderDropdown = (list) => {
        return list.map(listItem => {
            return (
                <div>
                    <DropdownTitle
                        isMobile={props.isMobile}
                    >
                        <StyledLink
                            isMobile={props.isMobile}
                            to={listItem.route}>{listItem.text}</StyledLink>
                    </DropdownTitle>

                </div>
            )
        })
    }

    if(props.isMobile){
        if(props.dropdown){
           return <div  style={{width: '100%'}}>
                <Title style={{marginTop:"10px",paddingLeft:"20px"}}>{props.text}</Title>
               {renderDropdown(props.list)}
            </div>
        }
    }

    if (props.dropdown) {
        const dropdownStyle = {
            position: "fixed",
            transform: `translateX(${dropdownAnchor[0]})`,
            top: dropdownAnchor[1] + "px",
            opacity: 0,
            left: dropdownAnchor[0] - dropdownWidth + "px",
            zIndex: 9999
        }

        const variants = {
            open: {opacity: 1, y: 0, scale: 1, z: 9999},
            closed: {opacity: 0, y: 40, scale: 1, z: -9999},
        }

        return (
            <React.Fragment>

                <StyledDropdownLink  isAtTop={props.isAtTop}
                                     isHome={props.isHome}
                                     isSecondary={props.isSecondary}
                                     onClick={openDropdown}
                                    ref={linkRef}
                                    to={props.route}>{props.text}</StyledDropdownLink>

                {/*<Portal container={document.body}>*/}
                {/*    <motion.div*/}
                {/*        animate={open ? "open" : "closed"}*/}
                {/*        transition={{*/}
                {/*            type: "spring",*/}
                {/*            damping: 300,*/}
                {/*            duration: 0.01,*/}
                {/*        }}*/}
                {/*        variants={variants}*/}
                {/*        style={dropdownStyle}>*/}
                {/*        <DropdownContainer ref={dropdownRef}>*/}
                {/*            {renderDropdown(props.list)}*/}
                {/*        </DropdownContainer>*/}
                {/*    </motion.div>*/}
                {/*</Portal>*/}
                <Popup show={open} align={"middle"} anchor={linkRef}>
                    <DropdownContainer>
                        {renderDropdown(props.list)}
                    </DropdownContainer>
                </Popup>
            </React.Fragment>
        )
    } else if (props.onClick) {
        return (
            <StyledButton
                isAtTop={props.isAtTop}
                isHome={props.isHome}
                isSecondary={props.isSecondary}
                onClick={props.onClick} style={props.isMobile ? props.altStyle :props.isAtTop ? props.style : props.altStyle ? props.altStyle : props.style}>{props.text}</StyledButton>
        )
    } else {
        return (
            <StyledLink  isAtTop={props.isAtTop}
                         isHome={props.isHome}
                         style={props.style} to={props.route}>{props.text}</StyledLink>
        )
    }

}

export default NavItem
