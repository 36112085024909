import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import mixpanel from 'mixpanel-browser';
import MixpanelProvider from 'react-mixpanel';
import firebase from "./firebase"

// import registerServiceWorker from './registerServiceWorker';
import configureStore from './store/store';

// import { unregister } from './registerServiceWorker';
// unregister();


document.addEventListener("DOMContentLoaded", () => {
  const store = configureStore();
  window.store = store;
  const root = document.getElementById('root')

  mixpanel.init("1b255a7abc6243f8f4a3a86fa62afefc");
  //
  // registerServiceWorker();
  ReactDOM.render(<Root store={store}/>, root)
})
