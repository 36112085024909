import React, { useEffect} from "react";
import {motion, AnimatePresence, useAnimation} from "framer-motion"

function _ModuleRect(props) {
    const controls = useAnimation()
    const isModuleRectActive = props.currentConnect.nodes.indexOf(props.module.id) !== -1
    const x = Math.cos(props.module.deg * (Math.PI / 180)) * props.INNER_RADIUS + 260,
        y = Math.sin(props.module.deg * (Math.PI / 180)) * props.INNER_RADIUS + 260


    useEffect(() => {
        controls.start("hidden")
        controls.start("visible")
    })

    const variants = {
        hidden: {
            fill: "#285e4b",
        },
        visible: {
            fill: isModuleRectActive ? "#00b575" : "#00b575"
        }
    }


    const renderText = (x, y, text) => {
        const words = text.split(" ")
        if (words.length > 1) {
            return (
                <React.Fragment>
                    <text textAnchor="middle" x={x + 40} y={y + 38} fontSize={"9pt"} fontWeight={"600"}>{words[0]}</text>
                    <text textAnchor="middle" x={x + 40} y={y + 53} fontSize={"9pt"} fontWeight={"600"}>{words[1]} {words[2]}</text>
                </React.Fragment>
            )

        } else {
            return (
                <text textAnchor="middle" x={x + 40} y={y + 33} fontSize={"9pt"} fontWeight={"600"}>
                    <tspan textAnchor="middle" dy="1.2em">{text}</tspan>
                </text>
            )

        }

    }


    // console.log(props.module.id, props.currentConnect.nodes.indexOf(props.module.id) !== -1)

    return (
        <AnimatePresence>
            {
                true && <g>
                    <motion.rect
                        x={x} y={y}
                        width={props.RECT_WIDTH}
                        height={props.RECT_WIDTH}
                        rx="6"
                        fill={props.currentConnect.nodes.indexOf(props.module.id) !== -1 ? "#00b575" : "#00b575"}
                        opacity={1}
                        variants={variants}
                        initial="hidden"
                        animate={controls}
                        filter="url(#f2)"
                        exit={{opacity: 0}}
                    />
                    {renderText(x, y, props.module.name)}
                </g>

            }


        </AnimatePresence>
    )


}

export default _ModuleRect
