import React from "react";
import arcCloud from "../../../assets/images/home/arcCloud.png"
import ConnectLine from "../_ConnectLine";
import Message from "./_Message"

class SupplierAnimation extends React.Component{
    constructor(){
        super();

        this.INNER_RADIUS = 190
        this.RECT_WIDTH = 80

        this.messages = [
            {
                text: ["New Account", "Added"],
                btnText: "View Account"
            },
            {
                text: ["Usage Consumption", "Insight"],
                btnText: "View History"
            },
            {
                text: ["Bill Received", "$1,324.21"],
                btnText: "Pay Bill"
            },
            {
                text: ["New Quote", "Received"],
                btnText: "View Quote"
            },
            {
                text: ["Contract Ready", "to Sign"],
                btnText: "Sign Contract"
            },
            {
                text: ["Carbon Footprint", "70%"],
                btnText: "View Footprint"
            },
        ]

        this.state = {
            currentConnect: null,
            show: false,
            currentMessageIdx: 0
        }
    }

    componentDidMount() {
        this.setState({show: true})
        setInterval(() => {
            this.setState({show: false})

            this.setState({show: true})
            let {currentMessageIdx} = this.state

            currentMessageIdx++
            if(currentMessageIdx > this.messages.length - 1) {
                currentMessageIdx = 0
            }
            this.setState({currentMessageIdx})
        }, 4000)

    }

    renderConnection = () => {
        const path = "M 300 180 H 120 V 360 H 480 V 180 H 300"
        var rand = Math.random()
        return <ConnectLine name={"test"} path={path} current={"test"} rand={rand} show={this.state.show}/>
    }

    renderMessage = () => {
        var rand = Math.random()

        return <Message {...this.messages[this.state.currentMessageIdx]} rand={rand} show={this.state.show}/>
    }

    renderGrid = () => {
        const lines = [];
        let countX = 0,
            countY = 0,
            distance = 20

        while (countX <= 50) {
            lines.push(
                <line x1="0" y1={countX * distance} x2="600" y2={countX * distance}
                      style={{stroke: "#00b575", strokeWidth: 0.5}}/>
            )
            countX++
        }
        while (countY <= 50) {
            lines.push(
                <line x1={countY * distance} y1="0" x2={countY * distance} y2="600"
                      style={{stroke: "#00b575", strokeWidth: 0.5}}/>
            )
            countY++
        }

        return lines
    }


    render(){
        return (
            <svg width={600} height={600} style={{marginLeft: "auto", marginRight: "auto"}}>
                <defs>
                    <radialGradient id="myGradient">
                        <stop offset="60%" stop-color="#000" stopOpacity={0}/>
                        <stop offset="100%" stop-color="#000"/>
                    </radialGradient>
                    <linearGradient id="grad1">
                        <stop offset="0%" stop-color="#6bf9c6"/>
                        <stop offset="100%" stop-color="#fff" stopOpacity="1"/>
                    </linearGradient>
                    <filter id="f2" x="0" y="0" width="200%" height="200%">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="10"/>
                        <feOffset dx="0" dy="20"/>
                        <feColorMatrix values="0     0     0     0     0
                                      0     0     0     0     0
                                      0     0     0     0     0
                                      0     0     0     0.7     0"/>
                        <feMerge>
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>

                    </filter>
                </defs>
                <g mask="url(#mask1)">
                    {this.renderGrid()}
                </g>

                <rect width={600} height={600} fill="url(#myGradient)" />
                {this.renderConnection()}
                <rect x={160} y={260} width={280} height={200} rx={5} fill="#cccccc" />
                {this.renderMessage()}
                <image href={arcCloud} x={300 - 60} y={100} height="120" width="120"/>
            </svg>
        )
    }

}

export default SupplierAnimation
