import React from"react"

import Header from "./sections/Header";
import Header2 from "./sections/Header2";
import Header4 from "./sections/Header4";
import Header5 from "./sections/Header5";
import Header3 from "./sections/Header3";
import ComingSoonCDG from "./sections/ComingSoonCDG";
import Solutions from "./sections/Solutions";
import EightSolutions from "./sections/EightSolutions";
import ArcTradeOps from "./sections/ArcTradeOps";
const HomePage = () => {
    return<div>
        {/*<Header2/>*/}
        {/*<Header2/>*/}
        {/*<Header4/>*/}
        <Header5/>
        {/*<EightSolutions/>*/}
        {/*<Solutions/>*/}
        {/*<ArcTradeOps/>*/}
        {/*<ComingSoonCDG/>*/}
    </div>
}

export default HomePage
