import React from "react";


class BackgroundImage extends React.Component{
  constructor(){
    super();

    this.state = {
      loaded: false
    }

    this.bkImage = new Image();
    this.loadImage = this.loadImage.bind(this)
  }

  loadImage(){
    this.setState({
      loaded: true
    })
  }

  componentDidMount(){
    this.bkImage.src = this.props.image;
    this.bkImage.onload = this.loadImage;
  }

  componentWillUnmount(){
    clearTimeout(this.timeout)
  }

  render(){
    const containerStyle = {
      backgroundColor: this.props.color || "#fff",
    }

    const bkImageStyle = {
      backgroundImage: "url(" + this.bkImage.src + ")",
    }

    const placeholderStyle = {
      backgroundImage: "url(" + this.props.placeholder + ")",
    }

    return(
      <div style={containerStyle} className={
        this.state.loaded ?  "background-image-container loaded" : "background-image-container"
      }>
        <div className="background-image-placeholder" style={placeholderStyle}/>
        <div className="background-image" style={bkImageStyle}/>
      </div>
    )
  }
}

export default BackgroundImage
