function Validation(validationType){
  this.validationType = validationType
}


Validation.prototype.isValid = function(value){
  switch (this.validationType) {
    case "email":
      return this.emailValidation(value)
    default:

  }
}

Validation.prototype.emailValidation = function(value){
  const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(value);
}

export default Validation
