import React from "react";

import BackgroundImage from "../../images/background-image";

import {Link} from "react-router-dom";

class Slide extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            "display": false,
        }

        this.renderTitle = this.renderTitle.bind(this)
    }

    toggleDisplay() {
        this.setState({
            "display": !this.state.display
        })
    }

    renderLink() {
        return (
            <Link to={this.props.link}>
                <div className="btn hp-btn hp-btn-lg light">
                    {this.props.buttonText}
                </div>
            </Link>
        )
    }

    renderLabel() {
        return (
            <div className="slide-label">
                {this.props.label}
            </div>
        )
    }

    renderTitle() {
        switch (this.props.title.type) {
            case "text":
                return this.props.title.text
            case "image":
                return (
                    <img
                        src={this.props.title.image}
                        className={this.props.title.class}
                        alt={this.props.title.alt}/>
                )
            default:
                console.log("TITLE TYPE MISSING")
        }
    }

    render() {
        const colorStyles = {
            "background": this.props.bkColor,
        }

        return (
            <div
                className={
                    this.props.slideId === this.props.currentId
                        ? "at-slide active" : "at-slide"
                }>
                {
                    this.props.content
                        ? <div className={"slide-image-content"}>
                            {this.props.content}
                        </div>
                        : ""
                }
                <div className="slide-content-container">
                    {this.props.label ? this.renderLabel() : ""}
                    <div className="slide-title">
                        {this.renderTitle()}
                    </div>
                    <div className="slide-description">
                        {this.props.description}
                    </div>
                    {this.props.link ? this.renderLink() : ""}
                </div>
                {
                    this.props.image && <BackgroundImage image={this.props.image} placeholder={this.props.placeholderImage}/>
                }

            </div>
        );
    }
}

export default Slide
