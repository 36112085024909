import React, {useEffect} from "react";
import {Controller, Scene} from 'react-scrollmagic';
import {Tween, Timeline} from 'react-gsap';
import TextLoop from "react-text-loop";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Waypoint} from 'react-waypoint';
import {motion, useAnimation} from "framer-motion";


const Wrapper = styled.div`
    width: 100%;
    position: relative;
    // background: #fff;
`

const Container = styled.div`
  width: 100%;
     position: relative;
  
`

const InnerContainer = styled.div`
    height: 100vh;
    background: lightgray;
    display: flex;
    align-items: center;
    p
    // background: red;
`

const ModulesContainer = styled.div`
    position: absolute;
    color: #fff;
    
`

const Module = styled.div`
    font-size: 10pt;
`

const SceneStyled = styled(Scene)`
    font-size: 10pt;
`
const Headerline = styled.div`
    height: 50vh;
    font-size: 40pt;
    font-weight: bold;npm install react-text-loo
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`


const Section = styled.div`
    height: 75vh;
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    flex-direction: ${props => props.right ? "row-reverse" : "row"};
    // margin-right: ${props => props.right ? "0" : "auto"};
    // margin-left: ${props => props.right ? "auto" : "0"};
`

const SectionContent = styled.div`
    display: flex;
    max-width: 500px;
    color: #fff;
    flex-direction:column;
    justify-content: center;
    align-items: flex-start;
`

const SectionHeader = styled.div`
    font-size: 30pt;
    line-height: 30pt;
    font-weight: bold;
    font-family: "Sohne Test"
`

const SectionBody = styled.div`
    margin-top: 20px;
    font-size: 12pt;
    font-weight: 400;
    font-family: "IBM Plex Serif"
`

const SectionImagePlaceHolder = styled.div`
    width: 500px; 
    height: 300px;
    border: 1px solid #fff;
`

const LinkTo = styled(Link)`
    color: #666cf3;
    font-size: 11pt;
    align-self: flex-end;
    border: 1px solid #4d52f6;
    padding: 4px 12px;
    border-radius: 6px;
    /* background: #8b8eff; */
    margin-top: 14px;
`

const Line = styled.div`
    position: absolute;
    top:80px;
    left 50%;
    bottom: 0;
    border-right: 1px solid #fff;
`

const SectionTitle = styled.span`
    background: #04b574;
    padding: 8px 12px;
    display: inline-block;
    left: 50%;
    top: 0;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    position: absolute;
`
const AutomatedTitle = styled.span`
    background: #4d52f6;
    text-transform: uppercase;
    padding: 8px 12px;
    font-size: 10pt;
    font-weight: 600;
    letter-spacing: 7px;
    margin-bottom: 15px;
`

const VariableText = styled.span`
    color: #4d52f6;
    border-bottom: 3px solid #4d52f6;
    margin: 0 10px;
`

const sceneConfig = [
    {
        insertTitle: "Create your Customer",
        title: "Capture your most important customer data quickly",
        body: "ArcTrade’s CRM provides a centralized rolodex to manage all your customers, brokers and sales channels. Manage all intricate entity relationships easily. Start building your customer book from scratch or migrate from your existing CRM.",
        link: {
            text: "Learn more about CRM",
            href: "/solutions/crm"
        }
    },
    {
        title: "Verified and Approved",
        body: "Customizable approval workflows for credit check and legal business entity verification ensure data integrity and risk control right from your lead generation."
    },
    {
        automated: true,
        insertTitle: "Manage your Data",
        title: "Upload Account Information and ArcTrade takes it from there",
        body: "ArcTrade automatically gets EDI usage for you. Your success depends on data integrity and data accuracy. ArcTrade’s in-built data analytics will show you any exceptions and anomalies in the data. Compliance is a breeze with ISO, State and LDC compliance rules woven throughout the platform."
    },
    {
        title: "One Platform. One Dataset",
        body: "Patching together various systems with APIs and having to migrate data across multiple interfaces inevitably leads to junky and flawed data. ArcTrade’s single platform ensures data integrity by maintaining a single dataset that provides a 360 view of your data throughout your organization."
    },
    {
        insertTitle: "Create your Quote",
        title: "Sophisticated Pricing made easy",
        body: "ArcTrade’s pricing engine is the most sophisticated and accurate on the market, yet allowing you to price even the most complex deals in just a few clicks. You can seamlessly integrate your consumption load shape with shapes for solar, wind, or battery projects. ArcTrade will automatically show avoided costs, additional revenue, and rate of return for your renewable projects. "
        // "Pricing matrix for residential customers is a click away. Layer in hundreds of hedges or create your own custom trade blocks for sophisticated C&I customers."
    },
    {
        insertTitle: "Execute your Contract",
        title: "Contracts Delivered Quickly",
        body: "Generating a contract is as simple as clicking a button. With immediate delivery to the customer’s inbox, reduce the turn around time to ensure shortest sales cycle. You have all of your contract versions at your fingertips with our searchable contracts management module."
    },
    {
        automated: true,
        title: "Retain your Existing Customers",
        body: "If desired, customer contracts can be set to automatically convert to evergreen or autorenewal without manual intervention. ArcTrade’s built in compliance system ensures on-time delivery of customer notifications based on State and LDC regulations."
    },
    {
        insertTitle: "Manage your Risk",
        title: "View your Exposure",
        body: "Managing risk is crucial to your operations. ArcTrade provides risk position reports for your wholesale, retail, and net positions so you can manage your book effectively and with ease."
    },
    {
        title: "Mark to Market Position",
        body: "Quickly view mark to market position for each deal or for your entire book at the click of a button. No need for any external risk management software."
    },

    {
        automated: true,
        insertTitle: "Serve your Customer",
        title: "Service on Autopilot",
        body: "After contract approval, serving a customer is handled on autopilot. Based on the LDC rules and contract start date, the system will automatically schedule the enrollments. Once enrolled, the system will automatically calculate and deliver the customer invoice every bill cycle."
    },

    {
        title: "System-guided Exceptions",
        body: "If any changes or issues happen to arise during service, such as missing usage, meter exchanges etc, the system guides the user through step-by-step instructions to resolution. No more years of training, be productive on day one."
    },

    {
        title: "Precision Billing",
        body: "ArcTrade’s single platform approach leverages a single database to ensure the contract and settlement rates flow through to billing seamlessly. This reduces the risk of billing errors and ensures your customer invoice is 100% accurate."
    },
    {
        title: "ESCO Business Rules: Run your operations your way",
        body: "Every retailer operates their business in a unique way. The ArcTrade platform is flexible to accommodate your business rules. Controlling true ups, bill estimations, and many more business rules are right at your fingertips."
    },
    {
        title: "From 1 to 1,000,000 <br/>Built for scale",
        body: "Whether your business is built around residential customers or large C&I customer base, ArcTrade makes it easy to manage your customers. Through convenient options like summary billing, invoicing one account or a thousand accounts is simple."
    },
    {
        title: "Connect to your Bank",
        body: "Managing your customer payments is easy on ArcTrade with the ability to connect to your bank lock box and automatically post payments. All LDC payments are also automatically handled."
    },

    {
        insertTitle: "Manage your ISO Ops",
        title: "Integration with the ISO",
        body: "ArcTrade automatically downloads and processes your settlement files giving you access to all your data through default and custom reports, right at your fingertips."
},
    {
        title: "Automatic Scheduling",
        body: "Submitting your demand bids is handled automatically based on load forecast and contract type."
    },



]



// const WayPoint = (props) => {
//     return (
//         <div style={{height: props.height + "px", border: "1px solid red"}}></div>
//     )
// }
//
//
// const Trigger = props => {
//     const TriggerContainer = styled.div`
//         border: 1px solid blue;
//         position: absolute;
//         height: 1px;
//         width: 100%;
//         top: ${props => props.position}px;
//     `
//
//     return (
//         <TriggerContainer position={props.position}></TriggerContainer>
//     )
// }
//
// const WayPointContainer = (props) => {
//     return (
//         <div style={{height: "100vh", background: "gray", overflow: "auto", position: "relative"}}>
//             {props.children}
//         </div>
//     )
// }
//


const Motionable = props => {
    const controls = useAnimation()
    console.log(props)
    useEffect(() => {
        if (props.show) {
            controls.start("open")
        } else {
            controls.start("closed")
        }
    })


    const variants = {
        open: {opacity: 1, y: 0},
        closed: {opacity: 0, y: "50px"},
    }

    return (
        <div style={{position: "fixed", top: "50%", transform: "translateY(-50%)", width: "100%"}}>
            <motion.div
                initial={"closed"}
                animate={controls}
                variants={variants}
                style={{
                    // border: "1px solid yellow",
                    width: "100%", height: "100%", maxWidth: "1200px", margin: "0 auto"
                }}>
                {props.children}
            </motion.div>
        </div>
    )
}

class ScrollWrapper extends React.Component {
    constructor() {
        super();

        const stateSetup = this.setupState()

        this.state = {
            height: 8000,
            sceneState: stateSetup
        }

        this.wrapperRef = React.createRef()
    }

    componentDidMount() {
        // var wrapper = this.wrapperRef.current
        // window.addEventListener("scroll", (e) => {
        //     console.log("WINDOW", window.scrollY, "top", wrapper.getBoundingClientRect().top)
        // })

    }

    renderScene = () => {
        var d = []
        sceneConfig.map((sceneItem, i) => {
            var link = sceneItem.link ? <LinkTo to={sceneItem.link.href}>{sceneItem.link.text}</LinkTo> : ""

            var section = <Section right={i % 2 !== 0}>
                <SectionContent>
                    {sceneItem.automated ? <AutomatedTitle>Automated Process</AutomatedTitle> : ""}
                    <SectionHeader dangerouslySetInnerHTML={{__html: sceneItem.title}}/>
                    <SectionBody>{sceneItem.body}</SectionBody>
                    {link}
                </SectionContent>
                <SectionImagePlaceHolder/>
            </Section>

            // if (sceneItem.insertTitle) {
            //     return (
            //         <React.Fragment>
            //             <Title>{sceneItem.insertTitle}</Title>
            //             {section}
            //             {}
            //         </React.Fragment>
            //     )
            // }
            d.push(
                <Tween
                    from={{y: '-100px', opacity: 0,}}
                    to={{y: '0px', opacity: 1,}}
                >
                    {section}
                </Tween>
            )

            // return (
            //     < Scene
            //         triggerHook="onLeave"
            //         duration={1000 * (i + 1)}
            //         pin
            //     >
            //         {(progress) => {
            //             return (
            //                 <Timeline progress={progress} duration={1000} paused>
            //                     <Tween
            //                         from={{y: '-100px', opacity: 0,}}
            //                         to={{y: '0px', opacity: 1,}}
            //                     >
            //                         {section}
            //                     </Tween>
            //                 </Timeline>
            //             )
            //         }}
            //     </Scene>
            // )

        })


        return (
            < Scene
                triggerHook="onLeave"
                duration={5000}
                pin
            >
                {(progress) => {
                    return (
                        <Timeline progress={progress} duration={1000} paused>
                            {d}
                        </Timeline>
                    )
                }}
            </Scene>
        )
    }

    setStart = (key) => {
        return e => {
            this.setState(prevState => {
                var sceneState = prevState.sceneState

                Object.keys(sceneState).forEach(_key => {
                    sceneState[_key] = false
                })

                sceneState[key] = true

                return {sceneState}
            })
        }
    }

    setFinish = (key) => {
        return e => {
            this.setState(prevState => {
                var sceneState = prevState.sceneState

                sceneState[key] = false

                return {sceneState}
            })
        }
    }

    setupState = () => {
        return sceneConfig.reduce((acc, curr, i) => {
            var id = "SCENE_" + i

            acc[id] = false
            return acc
        }, {})
    }

    renderContent = () => {
        var waypoints = [],
            content = [],
            stateTags = {}


        sceneConfig.forEach((scene, i) => {
            var id = "SCENE_" + i

            waypoints.push(
                <React.Fragment>
                    <div style={{
                        height: "110vh",
                        // border: "1px solid orange"
                    }}/>
                    <Waypoint
                        onEnter={this.setStart(id)}
                        onLeave={this.setFinish(id)}
                    />
                </React.Fragment>
            )

            content.push(
                <Motionable show={this.state.sceneState[id]}>
                    <Section right={i % 2 !== 0}>
                        {scene.insertTitle && <SectionTitle>{scene.insertTitle}</SectionTitle>}
                        <SectionContent>
                            {scene.automated ? <AutomatedTitle>Automated Process</AutomatedTitle> : ""}
                            <SectionHeader dangerouslySetInnerHTML={{__html: scene.title}}/>
                            <SectionBody>{scene.body}</SectionBody>
                        </SectionContent>
                        <SectionImagePlaceHolder/>
                    </Section>
                </Motionable>
            )

            stateTags[id] = false
        })

        return (
            <React.Fragment>
                {waypoints}
                {content}
            </React.Fragment>
        )

    }

    render() {
        console.log("STATE", this.state)
        return (
            <Wrapper ref={this.wrapperRef}>
                <Headerline>What does
                    <TextLoop
                        springConfig={{stiffness: 70, damping: 31}}
                        adjustingSpeed={500}
                    >
                        <VariableText>Billing</VariableText>
                        <VariableText>Pricing</VariableText>
                        <VariableText>Settlements</VariableText>
                        <VariableText>Operations</VariableText>
                        <VariableText>Risk Management</VariableText>
                    </TextLoop>{" "}
                    look like on Arctrade?</Headerline>


                {/*<WayPointContainer>*/}
                {/*    <Trigger position={300}/>*/}
                {/*    <WayPoint height={200}/>*/}
                {/*    <WayPoint height={900}/>*/}
                {/*    <WayPoint height={5000}/>*/}
                {/*</WayPointContainer>*/}
                <Container>
                    <Line/>
                    {this.renderContent()}
                </Container>
                {/*<div style={{height: "4000px"}}></div>*/}
                {/*<div style={{width: "100%", height: "1px", border: "1px dotted red"}}/>*/}
                {/*<Waypoint*/}
                {/*    style={{border: "10px solid purple"}}*/}
                {/*    onEnter={this.setStart}*/}
                {/*    onLeave={this.setFinish}*/}
                {/*/>*/}
                {/*<Motionable start={this.state.start}>*/}
                {/*    <Section right={false}>*/}
                {/*        <SectionContent>*/}
                {/*            <AutomatedTitle>Automated Process</AutomatedTitle>*/}
                {/*            <SectionHeader dangerouslySetInnerHTML={{__html: "Capture your most important"}}/>*/}
                {/*            <SectionBody>ArcTrade’s CRM provides a centralized rolodex to manage all your customers. With*/}
                {/*                support for the simplest residential customers to complex corporate relationships*/}
                {/*                easily start building your customer book from scratch or migrate from any existing CRM</SectionBody>*/}
                {/*        </SectionContent>*/}
                {/*        <SectionImagePlaceHolder/>*/}
                {/*    </Section>*/}
                {/*</Motionable>*/}
                {/*<Motionable start={this.state.start}>*/}
                {/*    <Section right={false}>*/}
                {/*        <SectionContent>*/}
                {/*            <AutomatedTitle>Automated Process</AutomatedTitle>*/}
                {/*            <SectionHeader dangerouslySetInnerHTML={{__html: "Capture your most important"}}/>*/}
                {/*            <SectionBody>ArcTrade’s CRM provides a centralized rolodex to manage all your customers. With*/}
                {/*                support for the simplest residential customers to complex corporate relationships*/}
                {/*                easily start building your customer book from scratch or migrate from any existing CRM</SectionBody>*/}
                {/*        </SectionContent>*/}
                {/*        <SectionImagePlaceHolder/>*/}
                {/*    </Section>*/}
                {/*</Motionable>*/}
            </Wrapper>
        )
    }

    // render() {
    //     return (
    //         <Wrapper ref={this.wrapperRef}>
    //             <Headerline>What does
    //                 <TextLoop
    //                     springConfig={{stiffness: 70, damping: 31}}
    //                     adjustingSpeed={500}
    //                 >
    //                     <VariableText>Billing</VariableText>
    //                     <VariableText>Pricing</VariableText>
    //                     <VariableText>Settlements</VariableText>
    //                     <VariableText>Operations</VariableText>
    //                     <VariableText>Risk Management</VariableText>
    //                 </TextLoop>{" "}
    //                 look like on Arctrade?</Headerline>
    //             <Container>
    //                 <Line/>
    //                 <Controller>
    //                     {this.renderScene()}
    //                 </Controller>
    //             </Container>
    //         </Wrapper>
    //     )
    // }

}

export default ScrollWrapper
// render() {
//     return (
//         <Wrapper ref={this.wrapperRef}>
//             <Container>
//                 <Controller>
//                     <Scene
//                         triggerHook="onLeave"
//                         duration={3000}
//                         pin
//                     >
//                         {(progress) => {
//                             console.log(progress)
//                             return(
//                                 <Timeline progress={progress}  duration={0.3} paused>
//                                     <Tween
//                                         from={{ x: '-10%', opacity: 0, }}
//                                         to={{ x: '0%',opacity: 1, }}
//                                     >
//                                         <Section>
//                                             <SectionHeader>Capture your most important customer data quickly</SectionHeader>
//                                             <SectionBody>
//                                                 ArcTrade’s CRM provides a centralized rolodex to manage all your customers. With
//                                                 support for the simplest residential customers to complex corporate relationships
//                                                 easily start building your customer book from scratch or migrate from any existing CRM
//                                             </SectionBody>
//                                         </Section>
//                                     </Tween>
//                                 </Timeline>
//                             )
//                         }}
//                     </Scene>
//                     <Scene
//                         triggerHook="onLeave"
//                         duration={3000}
//                         pin
//                     >
//                         {(progress) => {
//                             console.log(progress)
//                             return(
//                                 <Section className="sticky" right>
//                                     <Timeline progress={progress}  duration={0.3} paused>
//                                         <Tween
//                                             from={{ x: '-10%', opacity: 0, }}
//                                             to={{ x: '0%',opacity: 1, }}
//                                         >
//                                             <Section>
//                                                 <SectionHeader>Capture your most important customer data quickly</SectionHeader>
//                                                 <SectionBody>
//                                                     ArcTrade’s CRM provides a centralized rolodex to manage all your customers. With
//                                                     support for the simplest residential customers to complex corporate relationships
//                                                     easily start building your customer book from scratch or migrate from any existing CRM
//                                                 </SectionBody>
//                                             </Section>
//                                         </Tween>
//                                     </Timeline>
//                                 </Section>
//                             )
//                         }}
//                     </Scene>
//                 </Controller>
//                 <InnerContainer>
//
//                 </InnerContainer>
//                 <ModulesContainer>
//                     <Module>CRM</Module>
//                     <Module>Pricing</Module>
//                 </ModulesContainer>
//             </Container>
//         </Wrapper>
//     )
// }
// <Container>
// <Controller>
// {this.renderScene()}
// <Scene
//     triggerHook="onLeave"
//     duration={3000}
//     pin
// >
//     {(progress) => {
//         console.log(progress)
//         return (
//             <Timeline progress={progress} duration={0.3} paused>
//                 <Tween
//                     from={{x: '-10%', opacity: 0,}}
//                     to={{x: '0%', opacity: 1,}}
//                 >
//                     <Section>
//                         <SectionHeader>Capture your most important customer data
//                             quickly</SectionHeader>
//                         <SectionBody>
//                             ArcTrade’s CRM provides a centralized rolodex to manage all your
//                             customers. With
//                             support for the simplest residential customers to complex corporate
//                             relationships
//                             easily start building your customer book from scratch or migrate
//                             from any existing CRM
//                         </SectionBody>
//                     </Section>
//                 </Tween>
//             </Timeline>
//         )
//     }}
// </Scene>
// <Scene
// triggerHook="onLeave"
// duration={3000}
// pin
// >
// {(progress) => {
//     console.log(progress)
//     return (
//         <Section className="sticky" right>
//             <Timeline progress={progress} duration={0.3} paused>
//                 <Tween
//                     from={{x: '-10%', opacity: 0,}}
//                     to={{x: '0%', opacity: 1,}}
//                 >
//                     <Section>
//                         <SectionHeader>Capture your most important customer data
//                             quickly</SectionHeader>
//                         <SectionBody>
//                             ArcTrade’s CRM provides a centralized rolodex to manage all
//                             your customers. With
//                             support for the simplest residential customers to complex
//                             corporate relationships
//                             easily start building your customer book from scratch or
//                             migrate from any existing CRM
//                         </SectionBody>
//                     </Section>
//                 </Tween>
//             </Timeline>
//         </Section>
//     )
// }}
// </Scene>
// </Controller>
// <InnerContainer>
//
// </InnerContainer>
// <ModulesContainer>
// <Module>CRM</Module>
// <Module>Pricing</Module>
// </ModulesContainer>
// </Container>
