import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

import NavItem from "./NavItem";

import logoTypeWhite from '../../assets/images/logo/arctradeLogo_type.png';
import logoIconWhite from "../../assets/images/logo/arctradeLogo_icon.png"
import * as ReactRouter from "react-router";
import {useHistory} from "react-router-dom";
import {withRouter} from "react-router";
import {Icon} from "@arctrade/relay";
import {useResizeKey} from "../../views/HomePage/components/Graphic";

const Container = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  // background: #000;

  height: 60px;
  z-index: 99999;
  flex-wrap: wrap;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // max-width: 1400px;
  width: 100%;
  height: 100%;
  padding: 0 35px;
  background: ${props => {
    return props.isAtTop ? null : "#fff"
  }};
  transition: background 0.2s ease-in-out;
  @media only screen and (max-width: 700px) {
    padding: 0 20px;
  }
`

const Logo = styled(Link)`
  display: flex;
  align-items: center
`


const ItemsContainer = styled.div`
  display: block;
  @media only screen and (max-width: 700px) {
    display: none;
  }
`
const MobileItemsContainer = styled.div`
  display: none;
  @media only screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    color: #000;
    z-index: 9999;
    align-items: center;
  }
`
const MobileMenuButton = styled.button`
  display: none;
  background: none;
  color: ${props => {
    return !props.isAtTop ? "#000" : props.isHome ? "#fff" : "#fff"
  }};
  font-size: 18px;
  border: none;
  @media only screen and (max-width: 700px) {
    display: block;
  }
`

const CloseMenuBtn = styled.button`
  background: none;
  color: ${props => {
    return !props.isAtTop ? "#000" : "#000"
  }};
  border: none;
  margin: 12px 0;
`

const Banner = styled.div`
  width: 100%;
  height: 40px;
  background: #1516CF;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
`


function Navbar(props) {
    const history = useHistory()
    const [showMenu, setShowMenu] = useState(false)
    const [isAtTop, setIsAtTop] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    // const {url,path} = useRouteMatch()
    const isHome = props.location.pathname === "/"
    const resizeKey = useResizeKey()
    const isBreakpoint = useMemo(() => {
        return window.innerWidth < 1100
    }, [resizeKey])

    const handleScroll = e => {
        setIsAtTop(prev => {
            const scrollY = window.scrollY
            return scrollY === 0
        })

    }

    const handleResize = () => {
        const _isMobile = window.innerWidth <= 700
        setIsMobile(_isMobile)
    }

    const handleClose = () => {
        setShowMenu(false)
        document.removeEventListener("click", handleClose)
    }

    useEffect(() => {
        document.addEventListener("scroll", handleScroll)
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        if (showMenu) {
            document.addEventListener("click", handleClose)
        }
    }, [showMenu])


    const handleLoginLink = () => {
        let protocol;
        const secureUrls = [
            "uat.arctrade.com",
            "arctrade.com",
            "www.uat.arctrade.com",
            "www.arctrade.com",
            "demo.arctrade.com",
            "www.demo.arctrade.com"
        ]
        const host = window.location.host

        if (secureUrls.indexOf(host) === -1) {
            protocol = "http://"
        } else {
            protocol = "https://"
        }
        // context.mixpanel.track(`Clicked /ArcTradeApp/Account/Login`)
        const p = protocol + host + "/ArcTradeApp/Account/Login"
        window.location.href = p
    }

    const renderNavItems = useMemo(() => {
        const items = [
            // {
            // 	text: "Solutions",
            // 	dropdown: true,
            // 	list: [
            // 		{
            // 			title: "For Energy Retailers",
            // 			items: [
            // 				{
            // 					text: "Our Platform",
            // 					description: "Your end-to-end single retail platform solution",
            // 					route: "/solutions/crm"
            // 				},
            // 				{
            // 					text: <span>ArcTrade
            // 						<i>Ops</i>
            // 					</span>,
            // 					description: "A virtual back office at your fingertips",
            // 					route: "/solutions/crm"
            // 				},
            // 				{
            // 					text: <span>ArcTrade
            // 						<i>CommunitySolar</i>
            // 					</span>,
            // 					badge: "Coming Soon",
            // 					description: "Manage your renewable assets and subscribers",
            // 					route: "/solutions/crm"
            // 				},
            // 			]
            // 		}
            // 	]
            // },
            {
                text: "Retail Suppliers",
                route: "/forRetailSuppliers",
                dropdown: true,
                list: [
                    {
                        text: "The Energy Operating System",
                        route: "/eos"
                    },
                    {
                        text: "BPO",
                        route: "/bpo"
                    },
                ]
            },
            {
                text: "Community Solar",
                route: "/contact",
                dropdown: true,
                list: [
                    {
                        text: "Sign Up",
                        route: "/cdgSignup"
                    },
                    {
                        text: "For Project Owners",
                        route: "/cdgProjectOwners"
                    },
                ]
            },
            // {
            // 	text: "Contact",
            // 	route: "/contact",
            // },
            // {
            // 	text: "Schedule a Demo",
            // 	route: "/request-demo",
            // },
            {
                text: "Supplier Login",
                style: {
                    background: "none",
                    color: "#fff",
                    fontWeight: 600,
                    border: "1px solid #fff"
                },
                altStyle:{
                    border: "1px solid #37002d",
                    color:"#37002d",
                },
                isSecondary: true,
                onClick: handleLoginLink
            },
            {
                text: "Community Solar Signup",
                style: {
                    background: "#fff",
                    fontWeight: 600,
                    color: "#37002d"
                },
                altStyle:{
                    background:"#37002d",
                    color:"#fff",
                },
                onClick: () => {
                    history.push("/cdgSignUp")
                }
            },

        ]

        return items.map((item, i) => <NavItem
            isAtTop={isAtTop}
            isHome={isHome}
            isMobile={isMobile}
            key={i} {...item} />)
    }, [isMobile, isHome, isAtTop])


    console.log("ReactRouter", isMobile, props)
    return (
        <Container isHome={isHome}>
            <Content isHome={isHome} isAtTop={isAtTop}>
                <Logo href={"/"} to={"/"}>
                    <img src={
                        isHome && isAtTop && isBreakpoint
                            ? "https://cdn.arctrade.com/brand/Arctrade_Wordmark_White.svg"
                            :isHome && isAtTop && !isBreakpoint
                                ?"https://cdn.arctrade.com/brand/Arctrade_Symbol_White.svg"
                                :!isHome && isAtTop
                            ? "https://cdn.arctrade.com/brand/Arctrade_Wordmark_White.svg"
                            : isAtTop
                                ? "https://cdn.arctrade.com/brand/Arctrade_Symbol_White.svg"
                                : "https://cdn.arctrade.com/brand/Arctrade_Wordmark_Black.svg"} width={
                        !isHome
                            ? 140
                            : isHome && isAtTop && isBreakpoint
                                ? 140
                                :isAtTop
                                ? 0
                                : 140}
                         style={{
                             marginRight: "15px"
                         }}/>
                </Logo>
                {/*<Logo href={"/"} to={"/"}>*/}
                {/*	<img src={*/}
                {/*		!isHome && isAtTop*/}
                {/*		?"https://cdn.arctrade.com/brand/Arctrade_Wordmark_White.svg"*/}
                {/*			:isAtTop*/}
                {/*		? "https://cdn.arctrade.com/brand/Arctrade_Symbol_Black.svg"*/}
                {/*	:"https://cdn.arctrade.com/brand/Arctrade_Wordmark_Black.svg"} width={*/}
                {/*		!isHome*/}
                {/*		? 140*/}
                {/*		:isAtTop*/}
                {/*			? 35*/}
                {/*			: 140}*/}
                {/*		 style={{*/}
                {/*			 marginRight: "15px"}}/>*/}
                {/*</Logo>*/}
                <ItemsContainer>
                    {renderNavItems}
                </ItemsContainer>
                {
                    showMenu
                        ? <MobileItemsContainer>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: "15px 20px"
                            }}>

                                <img src={"https://cdn.arctrade.com/brand/Arctrade_Wordmark_Black.svg"}
                                     width={140}
                                     style={{
                                         marginTop: '0px',
                                         marginRight: "15px"
                                     }}/>
                                <CloseMenuBtn

                                    isHome={isHome}
                                    onClick={() => setShowMenu(false)}>
                                    <Icon type={"fa-window-close"}/>
                                </CloseMenuBtn>
                            </div>
                            {renderNavItems}
                        </MobileItemsContainer>
                        : <MobileMenuButton isAtTop={isAtTop}
                                            isHome={isHome}
                                            onClick={() => setShowMenu(true)}>
                            <Icon type={"fa-bars"}/>
                        </MobileMenuButton>
                }

            </Content>
        </Container>
    )
}

export default withRouter(Navbar)
