import React from 'react';

// import HighlightedText from '../text/highlighted-text'

const ColumnTitle = props => {
  return(
    <div className="column-title">
      {props.children}
    </div>
  )
}

export default ColumnTitle
