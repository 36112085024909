import React from "react";
import {connect} from 'react-redux';

import ViewTemplate from "./view_template";
import PageHeader from "../components/page-header/page-header";
import Section from "../components/section/section";
import Column from "../components/column/column";
import Form from "../components/forms/form";
import Input from "../components/forms/input";

import styled from "styled-components";

const PAGE_TITLE = "BPO";


const MaxContentWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

const SectionContainer = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  
`

const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
  width: 100%;
`
const ContentTitle = styled.div`
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 10px;
  @media only screen and (max-width: 700px){
    font-size: 20px;
    line-height: 25px;
  }
`
const ContentSubTitle = styled.div`
  font-size: 18px;
  line-height: 26px;
  //font-weight: 500;
  @media only screen and (max-width: 700px){
    font-size: 16px;
    line-height: 24px;
  }
`

const SectionBody = styled.div`
  font-size: 14px;
  line-height: 22px;
`

const BPO =() => {
	return <ViewTemplate pageTitle={PAGE_TITLE}>
		<PageHeader subtitle={"Business Process Outsourcing"} title={"BPO"}/>
		<Section >
			<MaxContentWrapper>

				<Column colCount="7">
					<ContentTitle>
						Let our teams, support yours.
					</ContentTitle>
					<ContentSubTitle>
						Arctrade provides a wide range of customizable BPO solutions that can be tailored to your specific needs. Arctrade’s experienced
						team brings specialized knowledge and industrial expertise, which can help you  stay competitive, scale faster, and adapt to new challenges and opportunities.</ContentSubTitle>
				</Column>
				<Column colCount="5">
					{/*<img src={"https://cdn.arctrade.com/brand/Arctrade_Brand_Cream_Pattern_Gray5.svg"}*/}
					{/*	 width={"100%"}/>*/}
				</Column>
				<Column colCount="7">
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Regulatory/Compliance</SectionTitle>
							<SectionBody>

								<div>
									<li>Compliance filling (RPS, PUC, State SOS, FERC EQR, EIA Form 861, ISO) </li>
									<li>PUC interrogatory data support </li>
									<li>PUC license (Renewal)</li>
									<li>New market registration</li>
								</div>
								<br/>
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Sales/Marketing/Customer Service Support</SectionTitle>
							<SectionBody>
								<div>
									<li>Enrollments and retention management </li>
									<li>Provide data support to customer service</li>
									<li>Provide customer related documents such as welcome letters, renewal notices and disclosures</li>
								</div>
								<br/>
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Utility Data Management</SectionTitle>
							<SectionBody>
								<div>
									<li>Communicate with utilities on behalf of supplier to resolve any issues related to data errors, missing enrollments, missing billing usages, invoices or 820 payments</li>
									<li>Manage EDI rejection issues</li>
								</div>
								<br/>
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Grid Data Management</SectionTitle>
							<SectionBody>
								<div>
									<li>Review ISO settlement data to validate charges, resolve any invoice errors</li>
									<li>Communicate with ISO on behalf of supplier</li>
									<li>Identify and resolve utility data error within the settlement window</li>
									<li>Participate in ISO capacity auction</li>
									<li>Confirm physical power/capacity delivery</li>
								</div>
								<br/>
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Wholesale Risk Management</SectionTitle>
							<SectionBody>
								<div>
									<li>Help to define the risk policy</li>
									<li>Monitor risk position </li>
									<li>Facilitate wholesale hedge</li>
									<li>Reconcile credit exposure with counterparty</li>
									<li>Monitor ISO collateral exposure</li>
									<li>Manage wholesale curves</li>
									<li>Manage contract and other documents</li>
								</div>
								<br/>
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Renewable (RPS and Voluntary Green)</SectionTitle>
							<SectionBody>
								<div>
									<li>Track RPS and voluntary REC obligations</li>
									<li>Facilitate wholesale REC hedge</li>
									<li>Manage RPS tracking system and retire/transfer REC purchases</li>
									<li>Manage REC inventory</li>
									<li>Prepare RPS compliance filings</li>
									<li>Manage annual voluntary REC auditing process</li>
								</div>
								<br/>
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Accounting/Finance</SectionTitle>
							<SectionBody>
								<div>
									<li>Assist in resolving issues related to billed/unbilled revenue recognition and reconciliation</li>
									<li>Assist in resolving issues related to cost of goods recognition and reconciliation</li>
									<li>Assist to reconcile invoice/payment</li>
									<li>Provide support for sales tax / GRT reporting</li>
									<li>Assist in resolve issues related to accounting month/year end closing</li>
									<li>Provide necessary data support upon auditor request</li>
								</div>
								<br/>
							</SectionBody>
						</SectionContainer>
					</div>
				</Column>
				<Column colCount="5" noPadding >
				</Column>
			</MaxContentWrapper>
		</Section>
	</ViewTemplate>
}

export default BPO
