import React from 'react';
import {merge} from "lodash";

const Section = props => {
  const classes = ["section-container"]

  if(props.dark){
    classes.push("dark")
  }

  if(props.blank){
    classes.push("blank")
  }

  if(props.reverse){
    classes.push("reverse")
  }


  const renderSpacer = () => {
    if(props.spacer){
      return <div className="section-spacer" />
    }
  }

  const style = {
    alignItems: props.alignCenter ? "center" : "",
  }

  const mergedStyled = merge(style, props.style || {})

  return(
    <div className={classes.join(" ")} style={mergedStyled}>
      { renderSpacer() }
      { props.children }
    </div>
  )
}

export default Section
