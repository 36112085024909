import _ from 'lodash';

import { SET_NAV_DARK, SET_NAV_LIGHT } from '../actions/ui_actions'

const defaultState = {
  "nav": "light"
}

const uiReducer = ( state = defaultState, action ) => {
  switch (action.type) {
    case SET_NAV_DARK:
      return _.merge( {}, state, { nav: "dark" })
    case SET_NAV_LIGHT:
      return _.merge( {}, state, { nav: "light" })
    default:
      return state;
  }
}


export default uiReducer
