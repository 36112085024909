import React from "react";
import {connect} from 'react-redux';

import ViewTemplate from "../view_template";
import PageHeader from "../../components/page-header/page-header";
import Section from "../../components/section/section";
import Column from "../../components/column/column";

import {setNavLight} from "../../actions/ui_actions";
import lesleyImage from "../../assets/images/team/lesley.png"
import leeImage from "../../assets/images/team/lee.png"
import michaelImage from "../../assets/images/team/michael.png"
import chrisImage from "../../assets/images/team/chris.png"
import janniImage from "../../assets/images/team/janni.png"
import amitImage from "../../assets/images/team/amit.png"
import staceyImage from "../../assets/images/team/stacey.png"
import deniceImage from "../../assets/images/team/denice.png"

class Team extends React.Component {
    componentDidMount() {
        // window.analytics.page(PAGE_TITLE)
        this.props.setNavLight()
    }

    render() {
        return (
            <ViewTemplate pageTitle="Team">
                <PageHeader title="Team"/>
                <Section dark>
                    <Column colCount="5">
                        <img src={lesleyImage} width={100}/>
                        <h3>Lesley Yang</h3>
                        <h4>CEO & Co-Founder</h4>
                        <p>
                            20+ years in serving customer’s comprehensive
                            energy needs, Lesley has experienced the industry pain
                            and is ready to disrupt
                        </p>
                    </Column>

                    <Column colCount="5">
                        <img src={leeImage} width={100}/>
                        <h3>Lee Chao</h3>
                        <h4>CTO & Co-Founder</h4>
                        <p>
                            30+ years in enterprise SaaS architecture for
                            financial and health industry, Lee is bringing the
                            energy revolution into reality
                        </p>
                    </Column>

                    <Column colCount="5">
                        <img src={michaelImage} width={100}/>
                        <h3>Michael Li</h3>
                        <h4>VP of Engineering</h4>
                        <p>
                            20+ years in enterprise & consumer
                            software, Michael knows how to assemble and lead the right engineering team
                        </p>
                    </Column>
                    <Column colCount="5">
                        <img src={amitImage} width={100}/>
                        <h3>Amit Garg</h3>
                        <h4>VP of Business Ops</h4>
                        <p>
                            10+ years in financial engineering and
                            structured energy products, Amit knows how to make the system simple to operate
                        </p>
                    </Column>

                    <Column colCount="5">
                        <img src={chrisImage} width={100}/>
                        <h3>Chris Holland</h3>
                        <h4>Director of Engineering</h4>
                        <p>
                            10+ years engineering and integration
                            experience for the large supplier, Chris knows traps and holes among the legacy systems

                        </p>
                    </Column>

                    <Column colCount="5">
                        <img src={janniImage} width={100}/>
                        <h3>Jan-Luca Berger</h3>
                        <h4>Director of Product</h4>
                        <p>
                            Creative mind and fresh eye to the industry, enables Jan-Luca to design the enterprise SaaS products for generation Z and after
                        </p>
                    </Column>

                    <Column colCount="5">
                        <img src={staceyImage} width={100}/>
                        <h3>Stacey Ramgolam</h3>
                        <h4>Director of Supplier Ops</h4>
                        <p>
                            20+ years back office operations for the
                            largest energy supplier, Stacey knows all the nuances involved in data, contracting & billing
                        </p>
                    </Column>

                    <Column colCount="5">
                        <img src={deniceImage} width={100}/>
                        <h3>Denice Purdum</h3>
                        <h4>Director of Grid Ops</h4>
                        <p>
                            20+ years experience with grid operators,  Denice knows the complicated market
                            structure and evolving history of every grid
                        </p>
                    </Column>

                    <Column colCount="6">
                    </Column>
                </Section>
            </ViewTemplate>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNavLight: () => dispatch(setNavLight())
    }
}

export default connect(null, mapDispatchToProps)(Team)
