import React from "react"
import ReactDOM from "react-dom";

class Portal extends React.Component {
    render() {
        if(!this.props.container) return ""
        return ReactDOM.createPortal(
            this.props.children,
            this.props.container,
        );
    }
}

export default Portal
