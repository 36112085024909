import React from 'react';
import {Switch, Route} from 'react-router-dom';
import "@arctrade/relay/dist/index.css"
// import './assets/fonts/scss/ibm-type.scss';
import './assets/styles/App.scss';

import Navbar from "./components/Navbar";
import Footer from './components/footer/footer';

import Routes from "./routes.js"
import {AppConfig} from "@arctrade/relay";
import CdgSignupComplete from "./views/CdgSignupComplete";




const Root = () => {

    return (
        <AppConfig >


        <div>
            <Switch>
                <Route path="/" component={Navbar}/>
            </Switch>
            <Switch>
                <Route path={Routes.CdgSignup.path} component={Routes.CdgSignup.component}/>
                <Route path={Routes.CdgSignupComplete.path} component={Routes.CdgSignupComplete.component}/>
                <Route path={Routes.Contact.path} component={Routes.Contact.component}/>
                <Route path={Routes.RequestDemo.path} component={Routes.RequestDemo.component}/>
                <Route path={Routes.PrivacyPolicy.path} component={Routes.PrivacyPolicy.component}/>
                <Route path={Routes.TermsOfService.path} component={Routes.TermsOfService.component}/>
                <Route path={Routes.TermsOfService.path} component={Routes.TermsOfService.component}/>
                <Route path={Routes.Security.path} component={Routes.Security.component}/>
                {/*<Route path={Routes.CRM.path} component={Routes.CRM.component}/>*/}
                <Route path={Routes.ForRetailSuppliers.path} component={Routes.ForRetailSuppliers.component}/>
                <Route path={Routes.ForProjectOwners.path} component={Routes.ForProjectOwners.component}/>
                <Route path={Routes.BPO.path} component={Routes.BPO.component}/>
                <Route path={Routes.Home.path} component={Routes.Home.component}/>
            </Switch>
            <Footer/>
        </div>
        </AppConfig>
    );
}

export default Root;
