import React from "react";
import {connect} from 'react-redux';
import WithMixpanel from "../components/WithMixpanel";
import { InlineWidget } from "react-calendly";

import firebase from "../firestore";

import ViewTemplate from "./view_template";
import PageHeader from "../components/page-header/page-header";
import Section from "../components/section/section";
import Column from "../components/column/column";
import Form from "../components/forms/form";
import Input from "../components/forms/input";

import {setNavLight} from "../actions/ui_actions";
import styled from "styled-components";


const MaxContentWrapper = styled.div`
     max-width: 1400px;
     width: 100%;
     margin: 0 auto;
     position: relative;
     display: flex;
     flex-wrap: wrap;
`

const SuccessMessage = styled.div`
    background: #04b574;
    padding: 5px 12px;
    border-radius: 3px;
    display: inline;
    font-size: 9pt;
    font-weight: 600;
`


const ErrorMessage = styled.div`
    background: red;
    padding: 5px 12px;
    border-radius: 3px;
    display: inline;
    font-size: 9pt;
    font-weight: 600;
`

class RequestDemo extends React.Component {
    constructor() {
        super()

        this.state = {
            form: {
                Name: "",
                Email: "",
                Company: "",
                PhoneNumber: ""
            },
            showSuccessMessage: false,
            showErrorMessage: false,

        }
    }

    componentDidMount() {
        this.props.setNavLight()
    }

    handleFormSubmit = (e) => {
        const db = firebase.firestore();

        db.collection("demo-request").add({...this.state.form})
            .then(res => {
                this.props.mixpanel.track(`Request for demo sent`, {...this.state.form})
                this.setState({
                    form: {
                        Name: "",
                        Email: "",
                        Company: "",
                        PhoneNumber: ""
                    },
                    showSuccessMessage: true,
                    showErrorMessage: false,
                })
            })
            .catch(err => {
                this.setState({
                    showSuccessMessage: false,
                    showErrorMessage: true,
                })
            });
    }

    handleChange = key => {
        return e => {
            let {form} = this.state
            form[key] = e.target.value
            this.setState({form})
        }
    }

    render() {
        console.log(this.props)

        return (
            <ViewTemplate pageTitle="Schedule a Demo">
                <PageHeader title="Schedule a Demo"/>
                <Section style={{padding: "10px"}}>
                    <MaxContentWrapper>
                        <Column colCount="12">
                            <InlineWidget url="https://calendly.com/arctrade-demo/arctrade-demo" />
                        </Column>
                    </MaxContentWrapper>
                </Section>
            </ViewTemplate>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNavLight: () => dispatch(setNavLight())
    }
}

export default connect(null, mapDispatchToProps)(WithMixpanel(RequestDemo))
