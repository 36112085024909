import React from "react";
import {connect} from 'react-redux';

import ViewTemplate from "../view_template";
import PageHeader from "../../components/page-header/page-header";
import Section from "../../components/section/section";
import Column from "../../components/column/column";

import {setNavLight} from "../../actions/ui_actions";
import styled from "styled-components";
import {Link} from "react-router-dom";

const MaxContentWrapper = styled.div`
     max-width: 1400px;
     width: 100%;
     margin: 0 auto;
     position: relative;
`

const SectionTitle = styled.div`
    font-size: 14pt;
    font-weight: bold;
    margin: 40px 8px 12px;
`

const SectionSubtitle = styled.div`
    font-size: 11pt;
    font-weight: bold;
    margin: 20px 0px 18px 15px;
`

const Bold = styled.div`
    font-weight: bold;
    font-size: 10pt;
    margin: 6px 30px 0px;
`

const Text = styled.div`
    font-weight: 300;
    font-size: 10pt;
    margin: 2px 30px 10px 30px;
`

const List = styled.ul`
 margin: 6px 40px 0px;
`

const ListItem = styled.li`
    list-style-type: disc;
    font-weight: 300;
    font-size: 10pt;
`

class PrivacyPolicy extends React.Component {
    componentDidMount() {
        // window.analytics.page(PAGE_TITLE)
        this.props.setNavLight()
    }

    render() {
        return (
            <ViewTemplate pageTitle="Privacy Policy">
                <PageHeader title="Privacy Policy"/>
                <Section>
                    <MaxContentWrapper>
                        <Column colCount="10">
                            <Bold>Last Updated: June 9, 2020</Bold>
                            <br/>
                            <Text>ArcTrade, Inc. (“ArcTrade,” “we,” “our,” and/or “us”) values the privacy of
                                individuals who use our website (the “Site”). This privacy policy (the “Privacy Policy”)
                                explains how we collect, use, and share personal information from users of our Site. By
                                using our Site, you agree to the collection, use, disclosure, and procedures this
                                Privacy Policy describes. Beyond this Privacy Policy, your use of our Site is also
                                subject to our <Link style={{color: "blue"}} to={"./terms-of-service"}>Terms of Service</Link>.</Text>

                            <SectionTitle>Information We Collect</SectionTitle>
                            <Text>We may collect a variety of personal information from or about you or your devices
                                from various sources, as described below.</Text>

                            <SectionSubtitle>A. Information You Provide to Us.</SectionSubtitle>

                            <Bold>Requesting a Demo.</Bold>
                            <Text>If you contact us through our Site to request a demo, we will receive your name, email
                                address, company name, and phone number.</Text>

                            <Bold>Communications.</Bold>
                            <Text>If you contact us directly, we may receive additional personal information about you.
                                For example, when you contact our Customer Support Team, we will receive your name,
                                email address, and the contents of your message. If you use our Site to subscribe to our
                                marketing communications, we will receive your email address.</Text>

                            <Bold>Careers.</Bold>
                            <Text>If you decide to apply for a job with us, you may submit your contact information and
                                your resume online. We will collect the personal information you choose to provide on
                                your resume, such as your education and employment experience. You may also apply
                                through certain third-party websites. If you do so, we will collect the personal
                                information you make available to us through those websites.</Text>

                            <SectionSubtitle>B. Information We Collect When You Use Our Site</SectionSubtitle>

                            <Bold>Device Information.</Bold>
                            <Text>We receive information about the device and software you use to access our Site,
                                including internet protocol (IP) address, web browser type, and operating system
                                version.</Text>

                            <Bold>Usage Information.</Bold>
                            <Text>To help us understand how you use our Site and to help us improve it, we automatically
                                receive information about your interactions with it, such as the pages or other content
                                you view, the length of time you spend on a page, objects such as hyperlinks you click
                                on, and the dates and times of your visits.</Text>

                            <Bold>Information from Cookies and Similar Technologies.</Bold>
                            <Text>We collect information using cookies, pixel tags, or similar technologies. Cookies are
                                small text files containing a string of alphanumeric characters.</Text>

                            <Text>Please review your web browser’s “Help” file to learn the proper way to modify your
                                cookie settings.</Text>

                            <SectionSubtitle>C. Personal Information We Receive from Third Parties.</SectionSubtitle>
                            <Text>We may receive personal information about you from third parties such as data or
                                marketing partners and combine it with other personal information we have about
                                you.</Text>

                            <SectionSubtitle>How We Use the Personal Information We Collect</SectionSubtitle>
                            <Bold>We use the personal information we collect:</Bold>

                            <List>
                                <ListItem>To provide, maintain, improve, and enhance our Site;</ListItem>
                                <ListItem>To understand and analyze how you use our Site and develop new products,
                                    services, features, and functionality;</ListItem>
                                <ListItem>To understand and analyze aggregated usage patterns;</ListItem>
                                <ListItem>To communicate with you, provide you with updates and other information
                                    relating to our Site, provide information that you request, respond to requests,
                                    comments, or questions, and otherwise provide customer support;</ListItem>
                                <ListItem>For marketing purposes, such as developing and providing promotional and
                                    advertising materials that may be useful, relevant, valuable or otherwise of
                                    interest to you;</ListItem>
                                <ListItem>To find and prevent fraud, and respond to trust and safety issues that may
                                    arise;</ListItem>
                                <ListItem>For compliance purposes, including enforcing our <Link style={{color: "blue"}} to={"./terms-of-service"}>Terms of Service</Link> or other
                                    legal rights, or as may be required by applicable laws and regulations or requested
                                    by any judicial process or governmental agency; and</ListItem>
                                <ListItem>For other purposes for which we provide specific notice at the time the
                                    personal information is collected.</ListItem>
                            </List>

                            <SectionSubtitle>How We Share the Personal Information We Collect</SectionSubtitle>
                            <Text>We do not share or otherwise disclose personal information we collect from or about
                                you except as described below or otherwise disclosed to you at the time of the
                                collection.</Text>

                            <Bold>Vendors and Service Providers.</Bold>
                                    <Text>We may share any personal information we receive with
                                        vendors and service providers retained in connection with the provision of our Site.</Text>

                            <Bold>Marketing.</Bold>
                            <Text>We do not rent, sell, or share personal information about you with
                                nonaffiliated companies for their direct marketing purposes unless we have your
                                permission.</Text>

                            <Bold>Analytics Partners.</Bold>
                            <Text>We may use analytics services such as Google Analytics to collect
                                and process certain analytics data. These services may also collect information about
                                your use of other websites, apps, and online resources.</Text>

                            <Bold>As Required by Law and Similar Disclosures.</Bold>
                            <Text>We may access, preserve, and disclose your
                                personal information if we believe doing so is required or appropriate to: (a) comply
                                with law enforcement requests and legal process, such as a court order or subpoena; (b)
                                respond to your requests; or (c) protect your, our, or others’ rights, property, or
                                safety.</Text>

                            <Bold>Merger, Sale, or Other Asset Transfers.</Bold>
                            <Text>We may transfer your personal information to
                                service providers, advisors, potential transactional partners, or other third parties in
                                connection with the consideration, negotiation, or completion of a corporate transaction
                                in which we are acquired by or merged with another company or we sell, liquidate, or
                                transfer all or a portion of our assets.</Text>

                            <Bold>Consent.</Bold>
                            <Text>We may also disclose your personal information with your permission.</Text>

                            <SectionTitle>Your Choices</SectionTitle>
                            <Bold>Marketing Communications.</Bold>
                            <Text>You can unsubscribe from our promotional emails via the link
                                provided in the emails. Even if you opt-out of receiving promotional messages from us,
                                you will continue to receive administrative messages from us.</Text>

                            <Bold>Do Not Track.</Bold>
                            <Text>There is no accepted standard on how to respond to Do Not Track signals,
                                and we do not respond to such signals.</Text>

                            <SectionTitle>Third Parties</SectionTitle>
                                <Text>Our Site may contain links to other websites, products, or services that we do not own
                                or operate. We are not responsible for the privacy and advertising practices of these
                                third parties. Please be aware that this Privacy Policy does not apply to your
                                activities on these third-party services or any personal information you disclose to
                                these third parties. We encourage you to read their privacy policies before providing
                                    any personal information to them.</Text>

                            <SectionTitle>Security</SectionTitle>
                               <Text> We make reasonable efforts to protect your personal information by using physical and
                                electronic safeguards designed to improve the security of the personal information we
                                maintain. However, as no electronic transmission or storage of personal information can
                                be entirely secure, we can make no guarantees as to the security or privacy of your
                                   personal information.</Text>

                            <SectionTitle>International Visitors</SectionTitle>
                                <Text>Our Site is hosted in the United States and intended for visitors located within the
                                United States. If you choose to use our Site from the European Union or other regions of
                                the world with laws governing data collection and use that may differ from U.S. law,
                                then please note that you are transferring your personal information outside of those
                                regions to the United States for storage and processing. Also, we may transfer your data
                                from the U.S. to other countries or regions in connection with storage and processing of
                                data, fulfilling your requests, and operating the Site. By providing any information,
                                including personal information, on or to our Site, you consent to such transfer,
                                    storage, and processing.</Text>

                            <SectionTitle>Children’s Privacy</SectionTitle>
                                <Text>We do not knowingly collect, maintain, or use personal information from children under
                                13 years of age, and no parts of our Site are directed to children. If you learn that a
                                child has provided us with personal information in violation of this Privacy Policy,
                                    then you may alert us at privacy@arctrade.com.</Text>

                            <SectionTitle>Pose a Question</SectionTitle>
                                <Text>If you have questions about your privacy on the Site or this privacy policy, please
                                    contact us at privacy@arctrade.com.</Text>

                            <SectionTitle>Changes to this Privacy Policy</SectionTitle>
                                <Text>We will post any adjustments to the Privacy Policy on this page, and the revised version
                                will be effective when it is posted. If we materially change the ways in which we use or
                                share personal information previously collected from you through our Site, we will
                                    attempt to notify you through our Site, by email, or other means.</Text>

                            <SectionTitle>Contact Information</SectionTitle>
                                <Text>ArcTrade is responsible for processing your information. If you have any questions,
                                comments, or concerns about our processing activities, please email us at
                                privacy@arctrade.com or write to us at 12707 High Bluff Drive, Suite 200, San Diego, CA
                                    92103.</Text>

                        </Column>
                    </MaxContentWrapper>
                </Section>
            </ViewTemplate>
    )
    }
    }

    const mapDispatchToProps = dispatch => {
        return {
        setNavLight: () => dispatch(setNavLight())
    }
    }

    export default connect(null, mapDispatchToProps)(PrivacyPolicy)
