import React from 'react';


const ColumnBody = props => {
  return(
    <div className="column-body">
      {props.children}
    </div>
  )
}

export default ColumnBody
