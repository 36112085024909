import React from 'react';
import { Provider } from 'react-redux';
import {  BrowserRouter } from 'react-router-dom';

import './assets/styles/App.css';

import App from './App';
import ScrollToTop from './components/ui-components/scroll-top';



const Root = ({store}) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  );
}

export default Root;
