import React, {useEffect, useMemo, useRef, useState} from "react";
import { Document, Outline, Page } from "react-pdf";
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import {
  Button,
  Column,
  NumericTextbox,
  Skeleton,
  Title, Visible,
} from "@arctrade/relay";

import PreviewBar from "./PreviewBar";
import "./pdf.scss";


function Pdf({blob = null, doc,fileName="",showDownload=false, showPreview = true, height }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [title, setTitle] = useState(null);
  const [_height, setHeight] = useState(height);
  const ref = useRef(null);
  const [zoom, setZoom] = useState(1);
  const [internalPageNumber, setInternalPageNumber] = useState(pageNumber);
  const [pageWidth, setPageWidth] = useState(0);
  const [parsedDoc, setParsedDoc] = useState(null);
  const documentPdfContainerRef = useRef(null);

  useEffect(() => {
    setInternalPageNumber(pageNumber);
  }, [pageNumber]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onNext = () => {
    setPageNumber((prev) => {
      return Math.min(numPages, prev + 1);
    });
  };
  const onPrev = () => {
    setPageNumber((prev) => {
      return Math.max(1, prev - 1);
    });
  };

  const onZoomIn = () => {
    setZoom((prev) => {
      return prev + prev * 0.2;
    });
  };
  const onZoomOut = () => {
    setZoom((prev) => {
      return Math.max(0.2, prev - prev * 0.2);
    });
  };

  const onDownload = () => {
    const url = window.URL.createObjectURL(parsedDoc);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if(blob){
      setParsedDoc(blob);
      return
    }
    if (doc) {
      var raw = window.atob(doc);
      var uInt8Array = new Uint8Array(raw.length);
      for (var i = 0; i < raw.length; i++) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      const blob = new Blob([uInt8Array], {
        type: "application/pdf",
      });

      setParsedDoc(blob);
    }
  }, [doc,blob]);

  useEffect(() => {
    if (documentPdfContainerRef.current) {
      const bound = documentPdfContainerRef.current.getBoundingClientRect();
      if (!height) {
        setHeight(bound.height + "px");
      }
    }
  }, [documentPdfContainerRef]);

  useEffect(() => {
    // if (doc && typeof doc === 'string') {
    //   const path = doc.split('/');
    //   const name = path.pop();
    //   setTitle(name);
    // }
  }, [doc]);


  const renderPages = useMemo(() => {
    const p = []

    for (let i = 1; i <= numPages; i++) {
      p.push(<Page width={pageWidth} scale={zoom} pageNumber={i} />)
    }

    return p
  }, [blob, numPages])


  return (
    <div
      className={"r-document-pdf-container"}
      style={{ height: `${_height}` }}
    >

      <div className={"r-document-content-container"}>
        {/*<div className={"r-document-preview-container r-background-gray-l2"}>*/}
        {/*  <PreviewBar*/}
        {/*    doc={parsedDoc}*/}
        {/*    numPages={numPages}*/}
        {/*    pageNumber={pageNumber}*/}
        {/*    setPageNumber={setPageNumber}*/}
        {/*  />*/}
        {/*</div>*/}
        <div
          className={"r-document-pdf-document"}
          ref={documentPdfContainerRef}
        >
          <Document onLoadSuccess={onDocumentLoadSuccess} file={parsedDoc}>
            {renderPages}
            {/*<Page width={pageWidth} scale={zoom} pageNumber={pageNumber} />*/}
          </Document>
        </div>
      </div>
    </div>
  );
}

export default Pdf;
