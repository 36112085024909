import React from 'react';

import Validation from "../../assets/js/validations";


class Input extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            "focused": false,
            "value": "",
        }

        this.handleFocus = this.handleFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.shouldLabelCollapse = this.shouldLabelCollapse.bind(this);
        this.isValidInput = this.isValidInput.bind(this);
    }

    handleFocus() {
        this.setState({
            "focused": !this.state.focused
        })
    }

    isValidInput(value) {
        const validation = new Validation(this.props.validationType, this.props.validationLength)
        return validation.isValid(value)
    }

    handleChange(e) {
        const newValue = e.target.value;
        this.props.handleChange(e)
        this.setState({
            "value": newValue
        }, this.props.updateValidState(this.props.inputId, this.isValidInput(newValue)))
    }

    shouldLabelCollapse() {
        let collapse = true;
        if (this.state.focused || this.state.value.length > 0) {
            collapse = false;
        }
        return collapse;
    }

    render() {
        const inputClass = {
            "zIndex": "1"
        }

        const {required, validationType} = this.props;

        return (
            <div className="at-form-item">
                <label htmlFor={this.props.id} className={
                    this.shouldLabelCollapse() ? "input-label" : "input-label focused"
                }>
                    {this.props.label}
                    {
                        required
                            ? <span style={{color: "red"}}>*</span>
                            : ""
                    }
                </label>
                <input
                    value={this.props.value}
                    style={inputClass}
                    className={this.props.className}
                    id={this.props.id}
                    name={this.props.name}
                    type={validationType}
                    onChange={(e) => this.handleChange(e)}
                    placerholder="test"
                    onFocus={this.handleFocus}
                    onBlur={this.handleFocus}
                    required={required}
                />
            </div>
        )
    }
}

export default Input
