import React from 'react';

// import Validation from "../../assets/js/validations"

class Form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formComplete: false,
            formValidated: false,
            inputsValidated: {}
        }


        this.mapInputs = this.mapInputs.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }

    mapInputs() {
        const inputCount = React.Children.count(this.props.children)
        let inputsValidated = Object.assign({}, this.state.inputsValidated);

        for (var i = 0; i < inputCount; i++) {
            inputsValidated[i] = false;
            this.setState({inputsValidated}, this.done);
        }

    }

    handleValidation(inputId, state) {
        let inputsValidated = Object.assign({}, this.state.inputsValidated);
        inputsValidated[inputId] = state;
        if (this.state.inputsValidated[inputId] !== state) {
            this.setState({
                inputsValidated
            }, this.isFormValid)
        }
    }

    isFormValid() {
        let valid = true;
        for (const id in this.state.inputsValidated) {

            if (this.state.inputsValidated[id] === false) {
                valid = false;
            }
        }
        this.setState({
            formValidated: valid
        })
    }

  handleSubmit = e=> {
    e.preventDefault()
      if(this.state.formValidated){
        this.props.onSubmit()
      }
  }

    componentDidMount() {
        this.mapInputs()
    }

    render() {
        const {action, id, method, dataID, buttonClass, formClass} = this.props;

        const inputsWithProps = React.Children.map(this.props.children, (child, idx) => {
            return React.cloneElement(child, {
                updateValidState: this.handleValidation, inputId: idx
            })
        })

        return (
            <div>
                <form className={formClass} id={id}  data-id={dataID} onSubmit={this.handleSubmit.bind(this)}>
                    {inputsWithProps}
                    <button className={
                        this.state.formValidated ? "hp-btn hp-btn-md to-right validated" : "hp-btn hp-btn-md to-right"
                    } type="submit">{this.props.buttonText}</button>
                </form>
            </div>
        )
    }
}

export default Form
