import React from "react";
import {connect} from 'react-redux';

import ViewTemplate from "./../view_template";
import PageHeader from "../../components/page-header/page-header";
import Section from "../../components/section/section";
import Column from "../../components/column/column";
import Form from "../../components/forms/form";
import Input from "../../components/forms/input";

import styled from "styled-components";

const PAGE_TITLE = "For Project Owners";

const MaxContentWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

const SectionContainer = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
  width: 100%;
`
const ContentTitle = styled.div`
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 10px;
  @media only screen and (max-width: 700px){
    font-size: 20px;
    line-height: 25px;
  }
`
const ContentSubTitle = styled.div`
  font-size: 18px;
  line-height: 26px;
  //font-weight: 500;
  @media only screen and (max-width: 700px){
    font-size: 16px;
    line-height: 24px;
  }
`

const SectionBody = styled.div`
  font-size: 14px;
  line-height: 22px;
`

const ForRetailSuppliers = () => {
	return <ViewTemplate pageTitle={PAGE_TITLE}>
		<PageHeader subtitle={"Community Solar"} title={PAGE_TITLE}/>
		<Section>
			<MaxContentWrapper>
				<Column colCount="5"noPadding >
				</Column>
				<Column colCount="7">
					<ContentTitle>
						We understand community solar
					</ContentTitle>
					<ContentSubTitle>
						Arctrade offers an end-to-end solution for community solar projects that includes subscriber acquisition, project management, and everything in between.

						<br/>
						<br/>
						For more information contact us at cdgoperations@arctrade.com
					</ContentSubTitle>
				</Column>
				<Column colCount="5">
					<img src={"https://images.unsplash.com/photo-1616007579077-22bee7dd35a5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2664&q=80"}
						 width={"100%"}/>
				</Column>
				<Column colCount="7">
					<div style={{padding: "0 0 15px 0"}}>
						<SectionContainer>
							<SectionTitle>Subscriber Acquisition</SectionTitle>
							<SectionBody>
								Our team can work with you to develop a personalized customer acquisition plan that is
								specifically tailored to your community solar project through:
								<br/>
								<br/>
								<div>
									<li>Marketing campaigns</li>
									<li>Outreach to community organizations and local businesses</li>
									<li>Digital advertising</li>
								</div>
								<br/>
								We understand the importance of making community solar accessible to all, including
								those
								with lower incomes. Our team can help you identify and qualify low to moderate income
								subscribers, and effectively communicate the benefits of community solar.
								<br/>
								<br/>
								Whether you're looking to build your subscriber base quickly or over time, we can help
								you
								develop a plan that is right for your project.
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "15px 0"}}>
						<SectionContainer>
							<SectionTitle>Project Management</SectionTitle>
							<SectionBody>
								Forecasting is a critical aspect of optimizing the full potential of your energy asset.
								Our
								sophisticated tools can provide accurate predictions of subscriber energy usage and
								project
								credit generation. By leveraging these, we can help you to manage your asset in a way
								that
								maximizes profitability.
								<br/>
								<br/>
								In addition, our team is dedicated to minimizing customer churn by managing all customer
								interactions and delivering high-quality service to your customers. Additionally, by
								handling utility communications, we can minimize service issues ensuring that your
								project
								is
								generating the highest possible return.
								<br/>
								<br/>
								Ultimately, our end-to-end management approach can help you to achieve your project
								goals
								and enhance the performance of your asset.
							</SectionBody>
						</SectionContainer>
					</div>
					<div style={{padding: "15px 0"}}>
						<SectionContainer>
							<SectionTitle>Developer Insights</SectionTitle>
							<SectionBody>
								Each community solar market is unique, and it is important to understand the local
								dynamics
								in order to identify the correct market and product offering for your project. Our team
								has
								a deep understanding of the complexities involved in community solar, and we can help
								you
								navigate the market to identify the best opportunity for your project.
								<br/>
								<br/>
								Futhermore, our platform provides ongoing insights into the performance of your
								project,a
								llowing you to access real-time data on the performance of your project, including:
								<br/>
								<br/>
								<div>
									<li>Credit generation</li>
									<li>Customer acquisition & engagement</li>
									<li>Financial performance</li>
								</div>
								<br/>
								These insights allow you to monitor the progress of your project and make informed
								decisions about its ongoing management.


							</SectionBody>
						</SectionContainer>
					</div>


				</Column>

				<Column colCount="6">
				</Column>
			</MaxContentWrapper>
		</Section>
	</ViewTemplate>
}

export default ForRetailSuppliers
