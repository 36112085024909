import React, {Component} from 'react';
import Helmet from "react-helmet"
import WithMixpanel from "../components/WithMixpanel";

class ViewTemplate extends Component {
    constructor(props) {
        super(props);

        this.pageTitle = props.pageTitle || "UNDEFINED"
    }

    componentDidMount() {
        this.props.mixpanel.track(`Viewed ${this.pageTitle}`);
    }

    render() {

        return (
            <div style={{paddingTop: "100px"}}>
                <Helmet>
                    <title>Arctrade | {this.pageTitle}</title>
                </Helmet>
                {this.props.children}
            </div>
        );
    }
}


export default WithMixpanel(ViewTemplate)
