import React from "react";


const Placeholder = props => {

  const renderImage = () => {

    if(props.image){
      return(
        <img src={props.image} width={props.imageWidth} alt="placeholder"/>
      )
    }
  }

  const style = {
    width: props.width,
    height: props.height,
    background: props.color,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: props.round ? "99999px" : "0"
  }

  return(
    <div style={style}>
      {renderImage()}
    </div>
  )
}

export default Placeholder
