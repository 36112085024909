import styled from "styled-components";
import {Column, Row, useTheme} from "@arctrade/relay";

const HowCDGWorksWrapper = styled.div`
  width: 100%;
  // padding: ${props => props.isMobile ? "15px" : "50px 20px" };
  //background: #f6f6f6;
`;

const NewsletterTitle = styled.div`
  font-size: 25px;
  font-weight: 600;
  line-height: 25px;
`;
const NewsletterSubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

const MegaSubTitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  margin-top: 15px;
`;
const MegaAction = styled.div`
  font-size: 20px;
  font-weight: 600;
  background: #05d3ad;
  border-radius: 3px;
  color: #fff;
  padding: 10px 20px;
  margin-top: 20px;
`;
const HowItWorksItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const HowItWorksItemWrapper = styled.div`
  width: ${props => props.isMobile ? "100%" : "25%" };
  min-width: 250px;
  padding: 15px;
`;
const HowItWorksItemBlock = styled.div`
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const TextContainer = styled.div`
  background: #fff;
  display: flex;
  padding: 15px;
  align-items: flex-start;
`;

const TextNumber = styled.div`
  background: whitesmoke;
  border-radius: 999px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  flex-shrink: 0;
  flex-grow: 0;
`;
const TextContent = styled.div`
  background: #fff;
  height: 50px;
  font-size: 13px;
`;
const HowItWorksDiagramContainer = styled.div`
  background: ${(props) => props.background};
  height: 200px;
  //width: 245px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const MegaImage = styled.div`
  font-size: 20px;
  height: 100%;
  width: 100%;
  font-weight: 400;
  color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  //background-image: url("https://cdn.dribbble.com/userupload/2850305/file/original-12a11021a52c3fab9def0e96e3ecc63c.jpg?compress=1&resize=1504x1128");
`;

const HowItWorksDiagram = styled.div`
  font-size: 20px;
  height: 150px;
  //width: 220px;
  font-weight: 400;
  color: #fff;
  //background-size:     cover;
  //background-repeat:   no-repeat;
  //background-position: center center;
  //background-image: url( ${(props) => props.image});
`;

const PanelTitle = styled.div`
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 20px;
`


const PanelT2itle = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 4px;
`;
const HowCDGWorks = () => {
	const {
		theme: { isMobile },
	} = useTheme();
	return (
		<HowCDGWorksWrapper isMobile={isMobile} id={"howCDGWorks"}>
				<Row  middleContent column>
					<PanelTitle>How It Works</PanelTitle>
				</Row>
				<HowItWorksItemsContainer>
					<HowItWorksItemWrapper>
						<HowItWorksItemBlock>
							<TextContainer>
								<TextNumber>1</TextNumber>
								<TextContent>
									You sign up for a community solar project near you
								</TextContent>
							</TextContainer>
							<HowItWorksDiagramContainer background={"#E0FFDD"}>
								<img
									src={
										"https://cdn.arctrade.com/images/finn/diagrams/how-it-works-1.png"
									}
									width={"100%"}
								/>
							</HowItWorksDiagramContainer>
						</HowItWorksItemBlock>
					</HowItWorksItemWrapper>
					<HowItWorksItemWrapper>
						<HowItWorksItemBlock>
							<TextContainer>
								<TextNumber>2</TextNumber>
								<TextContent>
									Our solar projects generates power that goes into the grid
								</TextContent>
							</TextContainer>
							<HowItWorksDiagramContainer background={"#FFF5D3"}>
								<img
									src={
										"https://cdn.arctrade.com/images/finn/diagrams/how-it-works-2.png"
									}
									width={"100%"}
								/>
							</HowItWorksDiagramContainer>
						</HowItWorksItemBlock>
					</HowItWorksItemWrapper>
					<HowItWorksItemWrapper>
						<HowItWorksItemBlock>
							<TextContainer>
								<TextNumber>3</TextNumber>
								<TextContent>
									The community gets clean solar energy
								</TextContent>
							</TextContainer>
							<HowItWorksDiagramContainer background={"#E5FAFF"}>
								<img
									src={
										"https://cdn.arctrade.com/images/finn/diagrams/how-it-works-3.png"
									}
									width={"100%"}
								/>
							</HowItWorksDiagramContainer>
						</HowItWorksItemBlock>
					</HowItWorksItemWrapper>
					<HowItWorksItemWrapper>
						<HowItWorksItemBlock>
							<TextContainer>
								<TextNumber>4</TextNumber>
								<TextContent>You save on your electricity bill</TextContent>
							</TextContainer>
							<HowItWorksDiagramContainer background={"#FFEDDC"}>
								<img
									src={
										"https://cdn.arctrade.com/images/finn/diagrams/how-it-works-4.png"
									}
									width={"100%"}
								/>
							</HowItWorksDiagramContainer>
						</HowItWorksItemBlock>
					</HowItWorksItemWrapper>
				</HowItWorksItemsContainer>
		</HowCDGWorksWrapper>
	);
};

export default HowCDGWorks;
