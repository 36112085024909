
import Crm from "./views/Solutions/Crm"
import PricingEngine from "./views/Solutions/PricingEngine"
import Home from "./views/Home"
import Contact from "./views/contact";
import ForRetailSuppliers from "./views/ForRetailSuppliers";
import ForProjectOwners from "./views/ForProjectOwners";
import RequestDemo from "./views/request-demo";
import Team from "./views/Team"
import TermsOfService from "./views/TermsOfService"
import PrivacyPolicy from "./views/PrivacyPolicy"
import HomePage from "./views/HomePage/index.tsx";
import Security from "./views/Security/index.jsx";
import CdgSignup from "./views/CdgSignup/index.jsx";
import CdgSignupComplete from "./views/CdgSignupComplete";
import BPO from "./views/bpo.jsx";
const Routes = {
    Home: {
      path: "/",
      component: HomePage
    },
    ForRetailSuppliers: {
      path: "/eos",
      component: ForRetailSuppliers
    },
    BPO: {
      path: "/bpo",
      component: BPO
    },
    ForProjectOwners: {
      path: "/cdgProjectOwners",
      component: ForProjectOwners
    },
    Contact: {
      path: "/contact",
      component: Contact
    },
    RequestDemo: {
      path: "/request-demo",
      component: RequestDemo
    },
    CRM: {
        path: "/solutions/crm",
        component: Crm
    },
    PricingEngine: {
        path: "/solutions/pricing",
        component: PricingEngine
    },
    Team: {
        path: "/team",
        component: Team
    },
    TermsOfService: {
        path: "/terms-of-service",
        component: TermsOfService
    },
    PrivacyPolicy: {
        path: "/privacy-policy",
        component: PrivacyPolicy
    },
    Security: {
        path: "/securityInfo",
        component: Security
    },
    CdgSignup: {
        path: "/cdgSignup",
        component: CdgSignup
    },
    CdgSignupComplete: {
        path: "/cdgSignupComplete",
        component: CdgSignupComplete
    }
}

export default Routes
