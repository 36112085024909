import React from "react";
import {connect} from 'react-redux';

import ViewTemplate from "./view_template";
import PageHeader from "../components/page-header/page-header";
import Section from "../components/section/section";
import Column from "../components/column/column";
import Form from "../components/forms/form";
import Input from "../components/forms/input";

import styled from "styled-components";

const PAGE_TITLE = "Contact";

const MaxContentWrapper = styled.div`
     max-width: 1400px;
     width: 100%;
     margin: 0 auto;
     position: relative;
     display: flex;
     flex-wrap: wrap;
`


class Contact extends React.Component {

    render() {
        return (
            <ViewTemplate pageTitle={PAGE_TITLE}>
                <PageHeader title={PAGE_TITLE}/>
                <Section>
                    <MaxContentWrapper>
                        <Column colCount="6">
                            <h3>Sales</h3>
                            <p>
                                Email: sales@arctrade.com <br/>
                                Phone: 858-284-6189
                            </p>
                            <h3 style={{marginTop: "15px"}}>Online</h3>
                            <p>
                                Support: support@arctrade.com <br/>
                                Careers: hr@arctrade.com
                            </p>

                        </Column>
                        <Column colCount="6">
                            <p>
                                440 Stevens Avenue
                                <br/>Suite 200
                                <br/>Solana Beach, CA 92075
                            </p>
                        </Column>
                        <Column colCount="6">
                        </Column>
                    </MaxContentWrapper>
                </Section>
            </ViewTemplate>
        )
    }
}



export default Contact
