import React from "react";
import {connect} from 'react-redux';
import pdf from "./ArcTrade_Terms_of_Service.pdf"
import ViewTemplate from "../view_template";
import PageHeader from "../../components/page-header/page-header";
import Section from "../../components/section/section";
import Column from "../../components/column/column";

import {setNavLight} from "../../actions/ui_actions";
import styled from "styled-components";
import {Link} from "react-router-dom";


const MaxContentWrapper = styled.div`
     max-width: 1400px;
     width: 100%;
     margin: 0 auto;
     position: relative;
`


const SectionTitle = styled.div`
    font-size: 14pt;
    font-weight: bold;
    margin: 40px 8px 12px;
`

const SectionSubtitle = styled.div`
    font-size: 11pt;
    font-weight: bold;
    margin: 20px 0px 18px 15px;
`

const Bold = styled.div`
    font-weight: bold;
    font-size: 10pt;
    margin: 6px 30px 0px;
`

const Strong = styled.span`
    font-weight: bold;
`

const Text = styled.div`
    font-weight: 300;
    font-size: 10pt;
    margin: 2px 30px 10px 30px;
`

const Clause = styled.div`
    display: flex;
     margin: 2px 30px 10px 30px;
`
const SubClause = styled.div`
    display: flex;
     margin: 2px 30px 10px 80px;
`
const ClauseNum = styled.div`
    width: 20px;
    flex-shrink: 0;
    font-size: 10pt;
    margin: 2px 0;
`

const List = styled.ul`
 margin: 6px 40px 0px;
`

const ListItem = styled.li`
    list-style-type: disc;
    font-weight: 300;
    font-size: 10pt;
`


class TermsOfService extends React.Component {
    componentDidMount() {
        // window.analytics.page(PAGE_TITLE)
        this.props.setNavLight()
    }

    render() {
        return (
            <ViewTemplate pageTitle="Terms of Service">
                <PageHeader title="Terms of Service"/>
                <Section>
                    <MaxContentWrapper>
                        <Column colCount="10">

                            <Bold>Last Updated: June 9, 2020</Bold>
                            <br/>
                            <Text>Welcome, and thank you for your interest in
                                ArcTrade, Inc. (“ArcTrade,” “we,” or “us”) and our website at www.arctrade.com, along
                                with our related websites, networks, applications, and other services provided by
                                us (collectively, the “Service”). These Terms of Service are a legally binding contract
                                between you and ArcTrade regarding your use of the Service.</Text>

                            <Text><Strong>PLEASE READ THE FOLLOWING TERMS CAREFULLY.
                                BY CLICKING “I ACCEPT,” OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING
                                THE SERVICE, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION TO YOUR
                                USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE FOLLOWING TERMS AND
                                CONDITIONS, INCLUDING ARCTRADE’S PRIVACY POLICY (TOGETHER, THESE “TERMS”). IF YOU ARE
                                NOT ELIGIBLE, OR DO NOT AGREE TO THE TERMS, THEN YOU DO NOT HAVE OUR PERMISSION TO USE
                                THE SERVICE. YOUR USE OF THE SERVICE, AND ARCTRADE’S PROVISION OF THE SERVICE TO
                                YOU, CONSTITUTES AN AGREEMENT BY ARCTRADE AND BY YOU TO BE BOUND BY THESE
                                TERMS.</Strong>
                            </Text>
                            <Text><Strong>ARBITRATION NOTICE.</Strong> Except for certain kinds of disputes described in
                                Section 15, you
                                agree that disputes arising under these Terms will be resolved by binding, individual
                                arbitration, and BY ACCEPTING THESE TERMS, YOU AND ARCTRADE ARE EACH WAIVING THE RIGHT
                                TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR REPRESENTATIVE PROCEEDING.
                                YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend your rights under
                                this contract (except for matters that may be taken to small claims court). Your rights
                                will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury. (See Section
                                15.)</Text>

                            <Clause>
                                <ClauseNum>1.</ClauseNum>
                                <Text>
                                    <Strong>ArcTrade Service Overview.</Strong> In addition to general information about
                                    ArcTrade and its
                                    services that is made available on ArcTrade’s website, the Service may include
                                    access to
                                    certain features of ArcTrade’s web-based platform known as the “ArcTrade
                                    Intelligence
                                    Platform” (the <Strong>“Platform”</Strong>). The Platform can be used by retail
                                    energy service
                                    companies (“<Strong>ESCOs</Strong>”) to manage their relationships with their retail
                                    energy
                                    customers (“<Strong>Customers</Strong>”).
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>2.</ClauseNum>
                                <Text>
                                    <Strong>Eligibility.</Strong> You must be at least 18 years old to use the Service.
                                    By agreeing to
                                    these Terms, you represent and warrant to us that: (a) you are at least 18 years
                                    old; (b) you have not previously been suspended or removed from the
                                    Service; and (c) your registration and your use of the Service is in compliance with
                                    any
                                    and all applicable laws and regulations. If you are an entity, organization, or
                                    company, the individual accepting these Terms on your behalf represents and warrants
                                    that they have authority to bind you to these Terms and you agree to be bound by
                                    these
                                    Terms.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>3.</ClauseNum>
                                <Text>
                                    <Strong>User Types; Accounts and Registration</Strong>
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>3.1</ClauseNum>
                                <Text>
                                    <Strong>ESCOs.</Strong> If you are using the Platform on behalf of an ESCO, that
                                    usage is subject to
                                    the terms of a separate agreement between that ESCO and ArcTrade governing use of
                                    the
                                    Platform (a <Strong>“Platform Subscription Agreement”</Strong>). Use of the Service
                                    other than the
                                    Platform is subject to these Terms to the extent these Terms do not conflict with
                                    the
                                    Platform Subscription Agreement. In the event of a conflict between the Platform
                                    Subscription Agreement and these Terms, the Platform Subscription Agreement will
                                    control. If you are accessing the Service on behalf of an ESCO and that ESCO does
                                    not
                                    have a current Platform Subscription Agreement, you are not authorized to use the
                                    Service, other than publicly available portions of the Service (e.g., the public
                                    website), and such use is governed by these Terms.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>3.2</ClauseNum>
                                <Text>
                                    <Strong>Customers</Strong>
                                </Text>
                            </Clause>
                            <SubClause>
                                <ClauseNum>a.</ClauseNum>
                                <Text>
                                    If you are using the Platform as a Customer, to access most features of the
                                    Service, you must register for an account (each, a <Strong>“Customer
                                    Account”</Strong>). The first user
                                    to set up a Customer Account for a particular utility account (such user,
                                    the <Strong>“Primary
                                    Account Holder”</Strong> and such account, the <Strong>“Primary Customer
                                    Account”</Strong>) will be responsible
                                    for delegating access rights to additional users with respect to that same utility
                                    account (each, a <Strong>“User Account Holder”</Strong>). The Primary Account Holder
                                    is responsible for
                                    all activities of any User Account Holders who have been provided access to the
                                    Customer
                                    Account by the Primary Account Holder.
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>b.</ClauseNum>
                                <Text>
                                    When you register for a Customer Account, you may be required to provide us with
                                    some
                                    information about yourself, such as your name, email address, or other contact
                                    information. You agree that the information you provide to us is accurate and that
                                    you
                                    will keep it accurate and up-to-date at all times. When you register, you will be
                                    asked
                                    to provide a password. You are solely responsible for maintaining the
                                    confidentiality of
                                    your account and password, and you accept responsibility for all activities that
                                    occur
                                    under your Customer Account. If you believe that your account is no longer secure,
                                    then
                                    you must immediately notify us at support@arctrade.com.
                                </Text>
                            </SubClause>
                            <Clause>
                                <ClauseNum>4.</ClauseNum>
                                <Text>
                                    <Strong>Licenses</Strong>
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>4.1</ClauseNum>
                                <Text>
                                    <Strong>Limited License.</Strong> Subject to your complete and ongoing compliance
                                    with these
                                    Terms, ArcTrade grants you, solely for your personal use, a
                                    limited, non-exclusive, non-transferable, non-sublicensable, revocable license to
                                    access
                                    and use the Service.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>4.2</ClauseNum>
                                <Text>
                                    <Strong>License Restrictions.</Strong> Except and solely to the extent such a
                                    restriction is
                                    impermissible under applicable law, you may not: (a) reproduce, distribute, publicly
                                    display, or publicly perform the Service; (b) make modifications to the
                                    Service; or (c) interfere with or circumvent any feature of the Service, including
                                    any
                                    security or access control mechanism. If you are prohibited under applicable law
                                    from
                                    using the Service, you may not use it.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>4.3</ClauseNum>
                                <Text>
                                    Feedback. If you choose to provide input and suggestions regarding problems with or
                                    proposed modifications or improvements to the Service (<Strong>“Feedback”</Strong>),
                                    then you hereby
                                    grant ArcTrade an
                                    unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right
                                    to
                                    exploit the Feedback in any manner and for any purpose, including to improve the
                                    Service
                                    and create other products and services.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>5.</ClauseNum>
                                <Text>
                                    <Strong>Ownership; Proprietary Rights.</Strong> The Service is owned and operated by
                                    ArcTrade. The
                                    visual interfaces, graphics, design, compilation, information, data, computer
                                    code (including source code or object code), products, software, services, and all
                                    other
                                    elements of the Service (<Strong>“Materials”</Strong>) provided by ArcTrade are
                                    protected by intellectual
                                    property and other laws. All Materials included in the Service are the property of
                                    ArcTrade or its third party licensors. Except as expressly authorized by ArcTrade,
                                    you
                                    may not make use of the Materials. ArcTrade reserves all rights to the Materials not
                                    granted expressly in these Terms.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>6.</ClauseNum>
                                <Text>
                                    <Strong>Third Party Terms</Strong>
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>6.1</ClauseNum>
                                <Text>
                                    <Strong>Third Party Services and Linked Websites.</Strong> ArcTrade may provide
                                    tools through the
                                    Service that enable you to export information, including User Content, to third
                                    party
                                    services. By using one of these tools, you agree that ArcTrade may transfer that
                                    information to the applicable third party service. Third party services are not
                                    under
                                    ArcTrade’s control, and, to the fullest extent permitted by law, ArcTrade is not
                                    responsible for any third party service’s use of your exported information. The
                                    Service
                                    may also contain links to third party websites. Linked websites are not under
                                    ArcTrade’s
                                    control, and ArcTrade is not responsible for their content.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>6.2</ClauseNum>
                                <Text>
                                    <Strong>Third Party Software.</Strong> The Service may include or incorporate third
                                    party software
                                    components that are generally available free of charge under licenses granting
                                    recipients broad rights to copy, modify, and distribute those components (<Strong>“Third
                                    Party
                                    Components”</Strong>). Although the Service is provided to you subject to these
                                    Terms, nothing in
                                    these Terms prevents, restricts, or is intended to prevent or restrict you from
                                    obtaining Third Party Components under the applicable third party licenses or to
                                    limit
                                    your use of Third Party Components under those third party licenses.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>7.</ClauseNum>
                                <Text>
                                    <Strong>User Content</Strong>
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>7.1</ClauseNum>
                                <Text>
                                    <Strong>User Content Generally.</Strong> Certain features of the Service may permit
                                    users to upload
                                    content to the Service, which may include
                                    messages, reviews, photos, video, images, folders, data, text, and other types of
                                    works (<Strong>User Content”</Strong>) and to publish User Content on the Service.
                                    You retain any
                                    copyright and other proprietary rights that you hold in the User Content that you
                                    post
                                    to the Service.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>7.2</ClauseNum>
                                <Text>
                                    <Strong>Limited License Grant to ArcTrade.</Strong> By providing User Content to or
                                    via the
                                    Service, you grant ArcTrade a worldwide, non-exclusive, irrevocable, royalty-free,
                                    fully
                                    paid right and license (with the right to sublicense) to
                                    host, store, transfer, display, perform, reproduce, modify for the purpose of
                                    formatting
                                    for display, and distribute your User Content, in whole or in part, in any media
                                    formats
                                    and through any media channels now known or hereafter developed solely in connection
                                    with provision, support, and enhancement of the Service.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>7.3</ClauseNum>
                                <Text>
                                    <Strong>Limited License Grant to Other Users.</Strong> By providing User Content to
                                    or via the Service
                                    to other users of the Service (including any ESCOs), you grant those users a
                                    non-exclusive license to access and use that User Content as permitted by these
                                    Terms
                                    and the functionality of the Service.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>7.4</ClauseNum>
                                <Text>
                                    <Strong>User Content Representations and Warranties.</Strong> ArcTrade disclaims any
                                    and all liability
                                    in connection with User Content. You are solely responsible for your User Content
                                    and
                                    the consequences of providing User Content via the Service. By providing User
                                    Content
                                    via the Service, you affirm, represent, and warrant that:
                                </Text>
                            </Clause>
                            <SubClause>
                                <ClauseNum>a.</ClauseNum>
                                <Text>
                                    you are the creator and owner of the User Content, or have the necessary
                                    licenses, rights, consents, and permissions to authorize ArcTrade and users of the
                                    Service to use and distribute your User Content as necessary to exercise the
                                    licenses
                                    granted by you in this Section, in the manner contemplated by ArcTrade, the Service,
                                    and
                                    these Terms;
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>b.</ClauseNum>
                                <Text>
                                    your User Content, and the use of your User Content as contemplated by these Terms,
                                    does
                                    not and will not: (i) infringe, violate, or misappropriate any third party
                                    right, including any copyright, trademark, patent, trade secret, moral right,
                                    privacy
                                    right, right of publicity, or any other intellectual property or proprietary
                                    right; (ii) slander, defame, libel, or invade the right of privacy, publicity or
                                    other
                                    property rights of any other person; or (iii) cause ArcTrade to violate any law or
                                    regulation; and
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>c.</ClauseNum>
                                <Text>
                                    your User Content could not be deemed by a reasonable person to be
                                    objectionable, profane, indecent, pornographic, harassing, threatening,
                                    embarrassing, hateful, or
                                    otherwise inappropriate.
                                </Text>
                            </SubClause>
                            <Clause>
                                <ClauseNum>7.5</ClauseNum>
                                <Text>
                                    <Strong>User Content Disclaimer.</Strong> We are under no obligation to edit or
                                    control User Content
                                    that you or other users post or publish, and will not be in any way responsible or
                                    liable for User Content. ArcTrade may, however, at any time and without prior
                                    notice, screen, remove, edit, or block any User Content that in our sole judgment
                                    violates these Terms or is otherwise objectionable. You understand that when using
                                    the
                                    Service you will be exposed to User Content from a variety of sources and
                                    acknowledge
                                    that User Content may be inaccurate, offensive, indecent, or objectionable. You
                                    agree to
                                    waive, and do waive, any legal or equitable right or remedy you have or may have
                                    against
                                    ArcTrade with respect to User Content. If notified by a user or content owner that
                                    User
                                    Content allegedly does not conform to these Terms, we may investigate the allegation
                                    and
                                    determine in our sole discretion whether to remove the User Content, which we
                                    reserve
                                    the right to do at any time and without notice. For clarity, ArcTrade does not
                                    permit
                                    copyright-infringing activities on the Service.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>7.6</ClauseNum>
                                <Text>
                                    <Strong>Monitoring Content.</Strong> ArcTrade does not control and does not have any
                                    obligation to
                                    monitor: (a) User Content; (b) any content made available by third parties; or (c)
                                    the
                                    use of the Service by its users. You acknowledge and agree that ArcTrade reserves
                                    the
                                    right to, and may from time to time, monitor any and all information transmitted or
                                    received through the Service for operational and other purposes. If at any time
                                    ArcTrade
                                    chooses to monitor the content, ArcTrade still assumes no responsibility or
                                    liability
                                    for content or any loss or damage incurred as a result of the use of content. During
                                    monitoring, information may be examined, recorded, copied, and used in accordance
                                    with
                                    our Privacy Policy.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>8.</ClauseNum>
                                <Text>
                                    <Strong>Prohibited Conduct.</Strong> BY USING THE SERVICE YOU AGREE NOT TO:
                                </Text>
                            </Clause>
                            <SubClause>
                                <ClauseNum>a.</ClauseNum>
                                <Text>
                                    use the Service for any illegal purpose or in violation of any
                                    local, state, national, or international law;
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>b.</ClauseNum>
                                <Text>
                                    b. violate, or encourage others to violate, any right of a third party, including by
                                    infringing or misappropriating any third party intellectual property right;
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>c.</ClauseNum>
                                <Text>
                                    interfere with security-related features of the Service, including by: (i) disabling or
                                    circumventing features that prevent or limit use or copying of any
                                    content; or (ii) reverse engineering or otherwise attempting to discover the source code
                                    of any portion of the Service except to the extent that the activity is expressly
                                    permitted by applicable law;
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>d.</ClauseNum>
                                <Text>
                                    interfere with the operation of the Service or any user’s enjoyment of the
                                    Service, including by: (i) uploading or otherwise disseminating any
                                    virus, adware, spyware, worm, or other malicious code; (ii) making any unsolicited offer
                                    or advertisement to another user of the Service; (iii) collecting personal information
                                    about another user or third party without consent; or (iv) interfering with or
                                    disrupting any network, equipment, or server connected to or used to provide the
                                    Service;
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>e.</ClauseNum>
                                <Text>
                                    perform any fraudulent activity including impersonating any person or entity, claiming a
                                    false affiliation, accessing any other Service account without permission;
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>f.</ClauseNum>
                                <Text>
                                    sell or otherwise transfer the access granted under these Terms or any Materials or any
                                    right or ability to view, access, or use any Materials; or
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>g.</ClauseNum>
                                <Text>
                                    attempt to do any of the acts described in this Section 8 or assist or permit any
                                    person in engaging in any of the acts described in this Section 8.
                                </Text>
                            </SubClause>
                            <Clause>
                                <ClauseNum>9.</ClauseNum>
                                <Text>
                                    <Strong>Digital Millennium Copyright Act</Strong>
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>9.1</ClauseNum>
                                <Text>
                                    <Strong>DMCA Notification.</Strong> We comply with the provisions of the Digital Millennium Copyright
                                    Act applicable to Internet service providers (17 U.S.C. §512, as amended). If you have
                                    an intellectual property rights-related complaint about material posted on the
                                    Service, you may contact our Designated Agent at the following address:
                                    <br/>
                                    <br/>
                                    ArcTrade, Inc.<br/>
                                    12707 High Bluff Drive. Suite 200<br/>
                                    San Diego, CA 92130<br/>
                                    Phone: 858-284-6189<br/>
                                    Email: copyright@arctrade.com<br/>
                                    <br/>
                                    Any notice alleging that materials hosted by or distributed through the Service infringe
                                    intellectual property rights must comply include the following information:
                                </Text>
                            </Clause>
                            <SubClause>
                                <ClauseNum>a.</ClauseNum>
                                <Text>
                                    an electronic or physical signature of the person authorized to act on behalf of the
                                    owner of the copyright or other right being infringed;
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>b.</ClauseNum>
                                <Text>
                                    a description of the copyrighted work or other intellectual property that you claim has
                                    been infringed;
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>c.</ClauseNum>
                                <Text>
                                    a description of the material that you claim is infringing and where it is located on the
                                    Service;
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>d.</ClauseNum>
                                <Text>
                                    your address, telephone number, and email address;
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>e.</ClauseNum>
                                <Text>
                                    a statement by you that you have a good faith belief that the use of the materials on the
                                    Service of which you are complaining is not authorized by the copyright owner, its
                                    agent, or the law; and
                                </Text>
                            </SubClause>
                            <SubClause>
                                <ClauseNum>f.</ClauseNum>
                                <Text>
                                    a statement by you that the above information in your notice is accurate and
                                    that, under penalty of perjury, you are the copyright or intellectual property owner or
                                    authorized to act on the copyright or intellectual property owner’s behalf.
                                </Text>
                            </SubClause>
                            <Clause>
                                <ClauseNum>9.2</ClauseNum>
                                <Text>
                                    <Strong>Repeat Infringers.</Strong> ArcTrade will promptly terminate the accounts of users that are
                                    determined by ArcTrade to be repeat infringers.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>10.</ClauseNum>
                                <Text>
                                    <Strong>Modification of these Terms.</Strong> We reserve the right to change these Terms on a
                                    going-forward basis at any time. Please check these Terms periodically for changes. If a
                                    change to these Terms materially modifies your rights or obligations, we may require
                                    that you accept the modified Terms in order to continue to use the Service. Material
                                    modifications are effective upon your acceptance of the modified Terms. Immaterial
                                    modifications are effective upon publication. Except as expressly permitted in this
                                    Section 10, these Terms may be amended only by a written agreement signed by authorized
                                    representatives of the parties to these Terms. Disputes arising under these Terms will
                                    be resolved in accordance with the version of these Terms that was in effect at the time
                                    the dispute arose.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>11.</ClauseNum>
                                <Text>
                                    <Strong>Term, Termination and Modification of the Service</Strong>
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>11.1</ClauseNum>
                                <Text>
                                    <Strong>Term.</Strong> These Terms are effective beginning when you accept the Terms or first
                                    download, install, access, or use the Service, and ending when terminated as described
                                    in Section 11.2.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>11.2</ClauseNum>
                                <Text>
                                    <Strong>Termination.</Strong> If you violate any provision of these Terms, your authorization to
                                    access the Service and these Terms automatically terminate. In addition, ArcTrade
                                    may, at its sole discretion, terminate these Terms or your account on the Service, or
                                    suspend or terminate your access to the Service, at any time for any reason or no
                                    reason, with or without notice. You may terminate your account and these Terms by
                                    contacting customer service at support@arctrade.com and following the then-current
                                    termination process.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>11.3</ClauseNum>
                                <Text>
                                    <Strong>Effect of Termination.</Strong> Upon termination of these Terms: (a) your license rights will
                                    terminate and you must immediately cease all use of the Service; (b) you will no longer
                                    be authorized to access your account or the Service; (c) you must pay ArcTrade any
                                    unpaid amount that was due prior to termination; and (d) all payment obligations accrued
                                    prior to termination and Sections 4.3, 5, 7.2, 11.3, 12, 13, 14, 15, and 16 will
                                    survive.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>11.4</ClauseNum>
                                <Text>
                                    <Strong>Modification of the Service.</Strong> ArcTrade reserves the right to modify or discontinue
                                    the Service at any time (including by limiting or discontinuing certain features of the
                                    Service), temporarily or permanently, without notice to you. ArcTrade will have no
                                    liability for any change to the Service or any suspension or termination of your access
                                    to or use of the Service.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>12.</ClauseNum>
                                <Text>
                                    <Strong>Indemnity.</Strong> To the fullest extent permitted by law, you are responsible for your use
                                    of the Service, and you will defend and indemnify <Strong>ArcTrade</Strong> and its
                                    officers, directors, employees, consultants, affiliates, subsidiaries and
                                    agents (together, the “<Strong>ArcTrade</Strong> Entities”) from and against every claim brought by a
                                    third party, and any related liability, damage, loss, and expense, including reasonable
                                    attorneys’ fees and costs, arising out of or connected with: (a) your unauthorized use
                                    of, or misuse of, the Service; (b) your violation of any portion of these Terms, any
                                    representation, warranty, or agreement referenced in these Terms, or any applicable law
                                    or regulation; (c) your violation of any third party right, including any intellectual
                                    property right or publicity, confidentiality, other property, or privacy
                                    right; or (d) any dispute or issue between you and any third party. We reserve the
                                    right, at our own expense, to assume the exclusive defense and control of any matter
                                    otherwise subject to indemnification by you (without limiting your indemnification
                                    obligations with respect to that matter), and in that case, you agree to cooperate with
                                    our defense of those claims.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>13.</ClauseNum>

                                <Text>
                                        <Strong>Disclaimers; No Warranties</Strong>
                                    <br/>
                                    THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS
                                    IS” AND ON AN “AS AVAILABLE” BASIS. ARCTRADE DISCLAIMS ALL WARRANTIES OF ANY
                                    KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT
                                    AVAILABLE THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY OF
                                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR
                                    NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR
                                    TRADE. ARCTRADE DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY
                                    MATERIALS OR CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR FREE
                                    OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND ARCTRADE DOES NOT WARRANT THAT ANY
                                    OF THOSE ISSUES WILL BE CORRECTED.
                                    NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SERVICE OR
                                    ARCTRADE ENTITIES OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE
                                    ANY WARRANTY REGARDING ANY OF THE ARCTRADE ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY
                                    STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE
                                    SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER. YOU UNDERSTAND AND AGREE THAT YOU
                                    USE ANY PORTION OF THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT
                                    RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE
                                    DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY LOSS OF DATA, INCLUDING USER CONTENT.
                                    THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION APPLY TO THE FULLEST EXTENT
                                    PERMITTED BY LAW. ArcTrade does not disclaim any warranty or other right that ArcTrade
                                    is prohibited from disclaiming under applicable law.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>14.</ClauseNum>

                                <Text>
                                        <Strong>Limitation of Liability</Strong>
                                    <br/>
                                    TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE ARCTRADE ENTITIES BE LIABLE
                                    TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE
                                    DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE
                                    LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO
                                    ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER BASED ON
                                    WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND
                                    WHETHER OR NOT ANY ARCTRADE ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
                                    EXCEPT AS PROVIDED IN SECTION 15.4 AND TO THE FULLEST EXTENT PERMITTED BY LAW, THE
                                    AGGREGATE LIABILITY OF THE ARCTRADE ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR
                                    RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE
                                    UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER
                                    OF: (A) THE AMOUNT YOU HAVE PAID TO ARCTRADE FOR ACCESS TO AND USE OF THE SERVICE IN
                                    THE 12 MONTHS PRIOR TO THE EVENT OR CIRCUMSTANCE GIVING RISE TO CLAIM; OR (B) $100.
                                    EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF
                                    WARRANTIES, OR EXCLUSION OF DAMAGES IS INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN
                                    THE PARTIES UNDER THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF
                                    THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT
                                    OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 14 WILL APPLY
                                    EVEN IF ANY LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>15.</ClauseNum>
                                <Text>
                                    <Strong>Dispute Resolution and Arbitration</Strong>
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>15.1</ClauseNum>
                                <Text>
                                    <Strong>Generally.</Strong> In the interest of resolving disputes between you and ArcTrade in the
                                    most expedient and cost effective manner, and except as described in Section 15.2, you
                                    and ArcTrade agree that every dispute arising in connection with these Terms will be
                                    resolved by binding arbitration. Arbitration is less formal than a lawsuit in court.
                                    Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more
                                    limited discovery than in court, and can be subject to very limited review by courts.
                                    Arbitrators can award the same damages and relief that a court can award. This agreement
                                    to arbitrate disputes includes all claims arising out of or relating to any aspect of
                                    these Terms, whether based in contract, tort, statute, fraud, misrepresentation, or any
                                    other legal theory, and regardless of whether a claim arises during or after the
                                    termination of these Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE
                                    TERMS, YOU AND ARCTRADE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE
                                    IN A CLASS ACTION.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>15.2</ClauseNum>
                                <Text>
                                    <Strong>Exceptions.</Strong> Despite the provisions of Section 15.1, nothing in these Terms will be
                                    deemed to waive, preclude, or otherwise limit the right of either party to: (a) bring an
                                    individual action in small claims court; (b) pursue an enforcement action through the
                                    applicable federal, state, or local agency if that action is available; (c) seek
                                    injunctive relief in a court of law in aid of arbitration; or (d) to file suit in a
                                    court of law to address an intellectual property infringement claim.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>15.3</ClauseNum>
                                <Text>
                                    <Strong>Arbitrator.</Strong> Any arbitration between you and ArcTrade will be settled under the
                                    Federal Arbitration Act and administered by the American Arbitration
                                    Association (“AAA”) under its Consumer Arbitration Rules (collectively, “AAA Rules”) as
                                    modified by these Terms. The AAA Rules and filing forms are available online at
                                    www.adr.org, by calling the AAA at 1-800-778-7879, or by contacting ArcTrade. The
                                    arbitrator has exclusive authority to resolve any dispute relating to the
                                    interpretation, applicability, or enforceability of this binding arbitration agreement.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>15.4</ClauseNum>
                                <Text>
                                    <Strong>Notice of Arbitration; Process.</Strong> A party who intends to seek arbitration must first
                                    send a written notice of the dispute to the other party by certified U.S. Mail or by
                                    Federal Express (signature required) or, only if that other party has not provided a
                                    current physical address, then by electronic mail (“Notice of Arbitration”). ArcTrade’s
                                    address for Notice is: ArcTrade, Inc., 12707 High Bluff Drive, Suite 200. San
                                    Diego, CA 92130. The Notice of Arbitration must: (a) describe the nature and basis of
                                    the claim or dispute; and (b) set forth the specific relief sought (“Demand”). The
                                    parties will make good faith efforts to resolve the claim directly, but if the parties
                                    do not reach an agreement to do so within 30 days after the Notice of Arbitration is
                                    received, you or ArcTrade may commence an arbitration proceeding. All arbitration
                                    proceedings between the parties will be confidential unless otherwise agreed by the
                                    parties in writing. During the arbitration, the amount of any settlement offer made by
                                    you or ArcTrade must not be disclosed to the arbitrator until after the arbitrator makes
                                    a final decision and award, if any. If the arbitrator awards you an amount higher than
                                    the last written settlement amount offered by ArcTrade in settlement of the dispute
                                    prior to the award, ArcTrade will pay to you the higher of: (i) the amount awarded by
                                    the arbitrator; or (ii) $10,000.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>15.5</ClauseNum>
                                <Text>
                                    <Strong>Fees.</Strong> If you commence arbitration in accordance with these Terms, ArcTrade will
                                    reimburse you for your payment of the filing fee, unless your claim is for more than
                                    $10,000, in which case the payment of any fees will be decided by the AAA Rules. Any
                                    arbitration hearing will take place at a location to be agreed upon in San Diego
                                    County, California, but if the claim is for $10,000 or less, you may choose whether the
                                    arbitration will be conducted: (a) solely on the basis of documents submitted to the
                                    arbitrator; (b) through a non-appearance based telephone hearing; or (c) by an in-person
                                    hearing as established by the AAA Rules in the county (or parish) of your billing
                                    address. If the arbitrator finds that either the substance of your claim or the relief
                                    sought in the Demand is frivolous or brought for an improper purpose (as measured by the
                                    standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all
                                    fees will be governed by the AAA Rules. In that case, you agree to reimburse ArcTrade
                                    for all monies previously disbursed by it that are otherwise your obligation to pay
                                    under the AAA Rules. Regardless of the manner in which the arbitration is conducted, the
                                    arbitrator must issue a reasoned written decision sufficient to explain the essential
                                    findings and conclusions on which the decision and award, if any, are based. The
                                    arbitrator may make rulings and resolve disputes as to the payment and reimbursement of
                                    fees or expenses at any time during the proceeding and upon request from either party
                                    made within 14 days of the arbitrator’s ruling on the merits.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>15.6</ClauseNum>
                                <Text>
                                    <Strong>No Class Actions.</Strong> YOU AND ARCTRADE AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
                                    OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                                    ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and ArcTrade
                                    agree otherwise, the arbitrator may not consolidate more than one person’s claims, and
                                    may not otherwise preside over any form of a representative or class proceeding.
                                    15.7 Modifications to this Arbitration Provision. If ArcTrade makes any future change to
                                    this arbitration provision, other than a change to ArcTrade’s address for Notice of
                                    Arbitration, you may reject the change by sending us written notice within 30 days of
                                    the change to ArcTrade’s address for Notice of Arbitration, in which case your account
                                    with ArcTrade will be immediately terminated and this arbitration provision, as in
                                    effect immediately prior to the changes you rejected will survive.
                                    15.8 Enforceability. If Section 15.6 is found to be unenforceable or if the entirety of
                                    this Section 15 is found to be unenforceable, then the entirety of this Section 15 will
                                    be null and void and, in that case, the parties agree that the exclusive jurisdiction
                                    and venue described in Section 16.2 will govern any action arising out of or related to
                                    these Terms.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>16.</ClauseNum>
                                <Text>
                                    <Strong>Miscellaneous</Strong>
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>16.1</ClauseNum>
                                <Text>
                                    <Strong>General Terms.</Strong> These Terms, together with the Privacy Policy and any other
                                    agreements expressly incorporated by reference into these Terms (including the Platform
                                    Subscription Agreement, if applicable), are the entire and exclusive understanding and
                                    agreement between you and ArcTrade regarding your use of the Service. You may not assign
                                    or transfer these Terms or your rights under these Terms, in whole or in part, by
                                    operation of law or otherwise, without our prior written consent. We may assign these
                                    Terms at any time without notice or consent. The failure to require performance of any
                                    provision will not affect our right to require performance at any other time after
                                    that, nor will a waiver by us of any breach or default of these Terms, or any provision
                                    of these Terms, be a waiver of any subsequent breach or default or a waiver of the
                                    provision itself. Use of section headers in these Terms is for convenience only and will
                                    not have any impact on the interpretation of any provision. Throughout these Terms the
                                    use of the word “including” means “including but not limited to”. If any part of these
                                    Terms is held to be invalid or unenforceable, the unenforceable part will be given
                                    effect to the greatest extent possible, and the remaining parts will remain in full
                                    force and effect.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>16.3</ClauseNum>
                                <Text>
                                    <Strong>Governing Law.</Strong> These Terms are governed by the laws of the State of California
                                    without regard to conflict of law principles that would result in the application of the
                                    laws of another jurisdiction. You and ArcTrade submit to the personal and exclusive
                                    jurisdiction of the state courts and federal courts located within San Diego
                                    County, California for resolution of any lawsuit or court proceeding permitted under
                                    these Terms. We operate the Service from our offices in California, and we make no
                                    representation that Materials included in the Service are appropriate or available for
                                    use in other locations.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>16.3</ClauseNum>
                                <Text>
                                    <Strong>Privacy Policy.</Strong> Please read the ArcTrade <Link style={{color: "blue"}} to={"./privacy-policy"}>Privacy Policy</Link> carefully for information
                                    relating to our collection, use, storage, disclosure of your personal information. The
                                    ArcTrade Privacy Policy is incorporated by this reference into, and made a part
                                    of, these Terms.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>16.4</ClauseNum>
                                <Text>
                                    <Strong>Additional Terms.</Strong> Your use of the Service is subject to all additional
                                    terms, policies, rules, or guidelines applicable to the Service or certain features of
                                    the Service that we may post on or link to from the Service (the <Strong>“Additional
                                    Terms”</Strong>). All Additional Terms are incorporated by this reference into, and made a part
                                    of, these Terms.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>16.5</ClauseNum>
                                <Text>
                                    <Strong>Consent to Electronic Communications.</Strong> By using the Service, you consent to receiving
                                    certain electronic communications from us as further described in our Privacy Policy.
                                    Please read our Privacy Policy to learn more about our electronic communications
                                    practices. You agree that any notices, agreements, disclosures, or other communications
                                    that we send to you electronically will satisfy any legal communication
                                    requirements, including that those communications be in writing.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>16.6</ClauseNum>
                                <Text>
                                    <Strong>Consent to Access Electric Utility Data.</Strong> By using the Service, you consent and
                                    authorize ArcTrade to utilize your specific data detailing your use of, or charges
                                    for, electric utility services. You authorize ArcTrade to access your individual
                                    customer usage information, including information that identifies you as a customer
                                    receiving electric utility services, details your record of payment(s) for electric
                                    utility services, as well as any and all smart meter data or other recorded data
                                    containing your use of, charges for, or payments of electric utility services.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>16.7</ClauseNum>
                                <Text>
                                    Contact Information. The Service is offered by ArcTrade, Inc., located at 12707 High
                                    Bluff Drive, Suite 200. San Diego, CA 92130. You may contact us by sending
                                    correspondence to that address or by emailing us at support@arctrade.com. You can access
                                    a copy of these Terms by clicking <Link style={{color: "blue"}} download target="_self" to={pdf}>here</Link>.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>16.8</ClauseNum>
                                <Text>
                                    <Strong>Notice to California Residents.</Strong> If you are a California resident, under California
                                    Civil Code Section 1789.3, you may contact the Complaint Assistance Unit of the Division
                                    of Consumer Services of the California Department of Consumer Affairs in writing
                                    at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone
                                    at (800) 952-5210 in order to resolve a complaint regarding the Service or to receive
                                    further information regarding use of the Service.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>16.9</ClauseNum>
                                <Text>
                                    <Strong>No Support.</Strong> We are under no obligation to provide support for the Service. In
                                    instances where we may offer support, the support will be subject to published policies.
                                </Text>
                            </Clause>
                            <Clause>
                                <ClauseNum>16.10</ClauseNum>
                                <Text>
                                    <Strong>International Use.</Strong> The Service is intended for visitors located within the United
                                    States. We make no representation that the Service is appropriate or available for use
                                    outside of the United States. Access to the Service from countries or territories or by
                                    individuals where such access is illegal is prohibited.
                                </Text>
                            </Clause>


                        </Column>
                    </MaxContentWrapper>
                </Section>
            </ViewTemplate>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNavLight: () => dispatch(setNavLight())
    }
}

export default connect(null, mapDispatchToProps)(TermsOfService)
