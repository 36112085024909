import React from "react";
import Slide from './slide/slide';
import CarouselMarker from './marker/marker';

import leftChevron from '../../assets/images/icons/chevron-left.png'
import rightChevron from '../../assets/images/icons/chevron-right.png'

class Carousel extends React.Component {
    constructor(props) {
        super();
        this.state = {
            "currentIdx": 0,
            "slideCount": props.slides.length
        };

        this.focusSlide = this.focusSlide.bind(this)
        this.prevSlide = this.prevSlide.bind(this)
        this.nextSlide = this.nextSlide.bind(this)
        this.cycleSlides = this.cycleSlides.bind(this)
    }

    renderSlides() {
        return this.props.slides.map((slide, idx) => {
            return (
                <Slide slideId={idx}
                       key={idx}
                       image={slide.image}
                       placeholderImage={slide.placeholderImage}
                       currentId={this.state.currentIdx}
                       content={slide.content}
                       backgroundImage={slide.backgroundImage}
                       tests={this.toggleDisplay}
                       title={slide.title}
                       link={slide.link}
                       buttonText={slide.buttonText}
                       description={slide.description}
                       bkColor={slide.bkColor}
                       label={slide.label}/>
            )
        })
    }

    focusSlide(id) {
        clearInterval(this.interval);
        this.setState({
            "currentIdx": id
        })
        this.setSlideInterval();
    }

    setSlideInterval() {
        this.interval = setInterval(this.cycleSlides, parseInt(this.props.slideSpeed || 4000, 10));
    }

    cycleSlides() {
        this.setState({
            "currentIdx": (this.state.currentIdx + 1) % this.state.slideCount,
        })
    }

    renderMarkers() {
        return this.props.slides.map((slide, idx) => {
            return (
                <CarouselMarker
                    key={idx}
                    focus={this.focusSlide}
                    currentId={this.state.currentIdx}
                    markerId={idx}
                    slideSpeed={this.props.slideSpeed}/>
            )
        })
    }

    prevSlide() {
        clearInterval(this.interval);
        let newIdx;
        if (this.state.currentIdx === 0) {
            newIdx = this.state.slideCount - 1;
        } else {
            newIdx = this.state.currentIdx - 1;
        }
        this.setState({
            "currentIdx": newIdx
        })
        this.setSlideInterval();
    }

    nextSlide() {
        clearInterval(this.interval);
        this.cycleSlides()
        this.setSlideInterval();
    }

    renderCarouselNav() {
        return (
            <React.Fragment>
                <div className="carousel-nav-container">

                    { this.props.slides.length > 1
                        ?  <div id="carousel-marker-container">
                            {this.renderMarkers()}
                        </div>

                        : ""
                    }

                </div>
                {this.props.arrows ?
                    <img alt="leftChevron" onClick={this.prevSlide} className="carousel-arrow left" src={leftChevron}
                         width="12px"/>
                    : ""}
                {this.props.arrows ?
                    <img alt="rightChevron" onClick={this.nextSlide} className="carousel-arrow right" src={rightChevron}
                         width="12px"/>
                    : ""}
            </React.Fragment>
        )
    }

    componentDidMount() {
        this.setSlideInterval();
    }


    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {

        const style = {
            height: this.props.height || "100vh"
        }

        return (
            <div className="at-carousel-container" id="carousel-container" style={style}>
                {this.renderSlides()}
                {this.renderCarouselNav()}
            </div>
        )
    }
}

export default Carousel
