import React from "react";
import {connect} from 'react-redux';

import ViewTemplate from "../view_template";
import PageHeader from "../../components/page-header/page-header";
import Section from "../../components/section/section";
import Column from "../../components/column/column";
import Form from "../../components/forms/form";
import Input from "../../components/forms/input";

import styled from "styled-components";

const PAGE_TITLE = "Security";

const MaxContentWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`
const Ol = styled.ol`
  list-style-type: disc;
`


class Contact extends React.Component {

	render() {
		return (
			<ViewTemplate pageTitle={PAGE_TITLE}>
				<PageHeader title={PAGE_TITLE}/>
				<Section>
					<MaxContentWrapper>
						<Column>
							<div>
								<h3>Organizational Security</h3>
								<Ol>
									<li><h4>Information Security Program</h4>
										<Ol>
											<li>
												We have an Information Security Program in place that is communicated
												throughout the organization. Our Information Security Program follows
												the criteria set forth by the SOC 2 Framework. SOC 2 is a widely known
												information security auditing procedure created by the American
												Institute of Certified Public Accountants.

											</li>
										</Ol>
									</li>
									<li><h4>Information Security Program</h4>
										<Ol>
											<li>
												Our organization undergoes independent third-party assessments to test our security
												and compliance controls.
											</li>
										</Ol>
									</li>
									<li><h4>Third-Party Penetration Testing</h4>
										<Ol>
											<li>
												We perform an independent third-party penetration at least annually to ensure that the
												security posture of our services is uncompromised.
											</li>
										</Ol>
									</li>
									<li><h4>Roles and Responsibilities</h4>
										<Ol>
											<li>
												Roles and responsibilities related to our Information Security Program and the
												protection of our customer’s data are well defined and documented. Our team members are
												required to review and accept all of the security policies.
											</li>
										</Ol>
									</li>
									<li><h4>Security Awareness Training</h4>
										<Ol>
											<li>
												Our team members are required to go through employee security awareness training
												covering industry standard practices and information security topics such as phishing
												and password management.
											</li>
										</Ol>
									</li>
									<li><h4>Confidentiality</h4>
										<Ol>
											<li>
												All team members are required to sign and adhere to an industry standard
												confidentiality agreement prior to their first day of work.
											</li>
										</Ol>
									</li>
									<li><h4>Background Checks</h4>
										<Ol>
											<li>
												We perform background checks on all new team members in accordance with local laws.
											</li>
										</Ol>
									</li>
								</Ol>
								<h3>Cloud Security</h3>
								<Ol>
									<li><h4>Cloud Infrastructure Security</h4>
										<Ol>
											<li>
												All of our services are hosted with Amazon Web Services (AWS). They employ a robust
												security program with multiple certifications. For more information on our provider’s
												security processes, please visit AWS Security.
											</li>
										</Ol>
									</li>
									<li><h4>Data Hosting Security</h4>
										<Ol>
											<li>
												All of our data is hosted on Amazon Web Services (AWS) databases. These databases are
												all located in the United States. Please reference the above vendor specific
												documentation linked above for more information.
											</li>
										</Ol>
									</li>
									<li><h4>Encryption at Rest</h4>
										<Ol>
											<li>
												All databases are encrypted at rest.
											</li>
										</Ol>
									</li>
									<li><h4>Encryption in Transit</h4>
										<Ol>
											<li>
												Our applications encrypt in transit with TLS/SSL only.
											</li>
										</Ol>
									</li>
									<li><h4>Vulnerability Scanning</h4>
										<Ol>
											<li>
												We perform vulnerability scanning and actively monitor for threats
											</li>
										</Ol>
									</li>
									<li><h4>Logging and Monitoring</h4>
										<Ol>
											<li>
												We actively monitor and log various cloud services.
											</li>
										</Ol>
									</li>
									<li><h4>Business Continuity and Disaster Recovery</h4>
										<Ol>
											<li>
												We use our data hosting provider’s backup services to reduce any risk of data loss in
												the event of a hardware failure. We utilize monitoring services to alert the team in the
												event of any failures affecting users.
											</li>
										</Ol>
									</li>
									<li><h4>Incident Response</h4>
										<Ol>
											<li>
												We have a process for handling information security events which includes escalation
												procedures, rapid mitigation and communication.
											</li>
										</Ol>
									</li>
								</Ol>
								<h3>Access Security</h3>
								<Ol>
									<li><h4>Permissions and Authentication</h4>
										<Ol>
											<li>
												Access to cloud infrastructure and other sensitive tools are limited to authorized
												employees who require it for their role.
											</li>
										</Ol>
									</li>
									<li><h4>Permissions and Authentication</h4>
										<Ol>
											<li>
												Access to cloud infrastructure and other sensitive tools are limited to authorized
												employees who require it for their role.
											</li>
											<li>
												Where available we have Single Sign-on (SSO), 2-factor authentication (2FA) and strong
												password policies to ensure access to cloud services are protected.
											</li>
										</Ol>
									</li>
									<li><h4>Least Privilege Access Control</h4>
										<Ol>
											<li>
												We follow the principle of least privilege with respect to identity and access
												management.
											</li>
										</Ol>
									</li>
									<li><h4>Quarterly Access Reviews</h4>
										<Ol>
											<li>
												We perform quarterly access reviews of all team members with access to sensitive
												systems.
											</li>
										</Ol>
									</li>
									<li><h4>Password Requirements</h4>
										<Ol>
											<li>
												All team members are required to adhere to a minimum set of password requirements and
												complexity for access.
											</li>
										</Ol>
									</li>
									<li><h4>Password Managers</h4>
										<Ol>
											<li>
												All company issued laptops utilize a password manager for team members to manage
												passwords and maintain password complexity.
											</li>
										</Ol>
									</li>
								</Ol>
								<h3>Vendor and Risk Management</h3>
								<Ol>
									<li><h4>Annual Risk Assessments</h4>
										<Ol>
											<li>
												We undergo at least annual risk assessments to identify any potential threats,
												including considerations for fraud.
											</li>
										</Ol>
									</li>
									<li><h4>Vendor Risk Management</h4>
										<Ol>
											<li>
												Vendor risk is determined and the appropriate vendor reviews are performed prior to
												authorizing a new vendor.
											</li>
										</Ol>
									</li>
								</Ol>
								<h3>Contact Us</h3>
								<ul>
									<li>
										If you have any questions, comments or concerns or if you wish to report a potential
										security issue, please contact security@arctrade.com.

									</li>
								</ul>
							</div>
						</Column>
					</MaxContentWrapper>
				</Section>
			</ViewTemplate>
		)
	}
}


export default Contact
