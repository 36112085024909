import React from "react";
import {connect} from 'react-redux';

import ViewTemplate from "../view_template";
import PageHeader from "../../components/page-header/page-header";
import Section from "../../components/section/section";
import Input from "../../components/forms/input";

import styled from "styled-components";
import Button from "../../components/button/button";
import useQueryParams from "../../hooks/useQueryParams";
import {Textbox,Row,Column, Form, Dropdown} from "@arctrade/relay";

const PAGE_TITLE = "Community Solar";

const MaxContentWrapper = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`


const CdgPageSignupComplete = () => {

	return (
		<ViewTemplate pageTitle={PAGE_TITLE}>
			<PageHeader title={PAGE_TITLE}/>
			<Section>
				<MaxContentWrapper>
					<Row>
						<Column>
						<p>Thank you for signing up for community solar! </p>

						</Column>
					</Row>
				</MaxContentWrapper>
			</Section>
		</ViewTemplate>
	)
}


export default CdgPageSignupComplete
