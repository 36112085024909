import React from 'react';


const Button = props => {

  const handleButtonClasses = () => {
    if(props.small){
      return "hp-btn hp-btn-sm"
    }

    if(props.medium){
      return "hp-btn hp-btn-md"
    }

    if(props.large){
      return "hp-btn hp-btn-lg"
    }

    return "hp-btn hp-btn-md"
  }

  const style = {
    marginLeft: props.right ? "auto" : "0"
  }

  return(
    <button style={style} className={handleButtonClasses()}>
      {props.text}
    </button>
  )
}


export default Button
