import React from 'react';


class CarouselMarker extends React.Component{
  render(){
    const animationStyles = {
      "animationDuration": this.props.slideSpeed / 1000 + "s"
    }
    return(
      <div onClick={() => this.props.focus(this.props.markerId)}className={
          this.props.currentId === this.props.markerId
            ? "carousel-marker active" : "carousel-marker"
      }>
        <div style={animationStyles}
          className="carousel-marker-progress"
        ></div>
      </div>
    )
  }
}

export default CarouselMarker
