import React from "react";
import faker from "faker";
import arcLogo from "../../../assets/images/logo/arctradeLogo_icon_dark.png"
import VerticalLine from "./_VerticalLine"
import Mask from "./_Mask"
import ConnectLine from "../_ConnectLine"

class MarketAnimation extends React.Component {
    constructor() {
        super();

        this.INNER_RADIUS = 190
        this.RECT_WIDTH = 80

        this.state = {
            show: false,
            dollarText: 23.42,
            curve: this.generateRandomCurve(160)
        }
    }


    componentDidMount() {
        this.setState({show: true})

        setInterval(() => {
            this.setState({show: false})

            this.setState({show: true, curve: this.generateRandomCurve(160)})


            var dollarInterval = setInterval(() => {
                var randomnum = faker.finance.amount(30, 50, 4)
                this.setState({dollarText: parseFloat(randomnum).toFixed(2)})
            }, 100)


            setTimeout(() => {
                clearInterval(dollarInterval)
            }, 800)
        }, 3000)

    }

    generateRandomCurve = (startX) => {
        const startY = faker.random.number({min: 280, max: 360})
        const curve = [`M ${startX} ${startY}`]
        let horizontalProgress = startX

        for (var i = 0; i < 15; i++) {
            horizontalProgress += 20
            var y = faker.random.number({min: 240, max: 400})
            curve.push(`L ${horizontalProgress} ${y}`)
        }

        curve.push(`V 400 H ${startX}`)

        return curve.join(" ")
    }

    renderCurve = () => {
        const path = this.state.curve
        return <React.Fragment>
            <path d={path} fill={"url('#grad2')"} clipPath={"url(#cut-off-bottom)"}/>
            <path
                clipPath={"url(#cut-off-bottom)"}
                d={path}
                stroke={"#142599"}
                style={{strokeWidth: 1}}
                fill={"none"}/>
        </React.Fragment>
    }


    renderGrid = () => {
        const lines = [];
        let countX = 0,
            countY = 0,
            distance = 20

        while (countX <= 50) {
            lines.push(
                <line key = {countX} x1="0" y1={countX * distance} x2="600" y2={countX * distance}
                      style={{stroke: "#00b575", strokeWidth: 0.5}}/>
            )
            countX++
        }
        while (countY <= 50) {
            lines.push(
                <line key={countY + "a"} x1={countY * distance} y1="0" x2={countY * distance} y2="600"
                      style={{stroke: "#00b575", strokeWidth: 0.5}}/>
            )
            countY++
        }

        return lines
    }


    render() {
        return (
            <svg width={600} height={600} style={{marginLeft: "auto", marginRight: "auto"}}>
                <defs>
                    <radialGradient id="myGradient">
                        <stop offset="60%" stop-color="#000" stopOpacity={0}/>
                        <stop offset="100%" stop-color="#000"/>
                    </radialGradient>
                    <linearGradient id="grad2" gradientTransform="rotate(90)">
                        <stop offset="0%" stop-color="#155fff"/>
                        <stop offset="100%" stop-color="#3f76ff" stopOpacity="1"/>
                    </linearGradient>
                    <filter id="f2" x="0" y="0" width="200%" height="200%">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="10"/>
                        <feOffset dx="0" dy="20"/>
                        <feColorMatrix values="0     0     0     0     0
                                      0     0     0     0     0
                                      0     0     0     0     0
                                      0     0     0     0.7     0"/>
                        <feMerge>
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>

                    </filter>
                    <clipPath id="cut-off-bottom">
                        <Mask show={this.state.show}/>
                    </clipPath>

                </defs>
                <g mask="url(#mask1)">
                    {this.renderGrid()}
                </g>

                <rect width={600} height={600} fill="url(#myGradient)"/>

                <ConnectLine path={"M 300 200 L 300 100"} show={this.state.show} delay={0.5} duration={0.6}/>
                <ConnectLine path={"M 160 300 L 100 300"} show={this.state.show} delay={0.5} duration={0.6}/>
                <ConnectLine path={"M 440 300 L 500 300"} show={this.state.show} delay={0.5} duration={0.6}/>
                <ConnectLine path={"M 300 400 L 300 500"} show={this.state.show} delay={0.5} duration={0.6}/>
                <g>
                    <circle cx={300} cy={100} r={40} fill="#00b575"/>
                    <text x={300} y={105} fontSize={"9pt"} fontWeight={"bold"} textAnchor="middle">Utilities</text>
                </g>
                <g>
                    <circle cx={300} cy={500} r={40} fill="#00b575"/>
                    <text x={300} y={505} fontSize={"9pt"} fontWeight={"bold"} textAnchor="middle">Suppliers</text>
                </g>
                <g>
                    <circle cx={60} cy={300} r={40} fill="#00b575"/>
                    <text x={60} y={297} fontSize={"9pt"} fontWeight={"bold"} textAnchor="middle">Grid</text>
                    <text x={60} y={311} fontSize={"9pt"} fontWeight={"bold"} textAnchor="middle">Operators</text>
                </g>
                <g>
                    <circle cx={540} cy={300} r={40} fill="#00b575"/>
                    <text x={540} y={305} fontSize={"9pt"} fontWeight={"bold"} textAnchor="middle">Customers</text>
                </g>
                <rect x={160} y={200} width={280} height={200} rx={5} fill="#cccccc"/>
                <line x1={160} y1={280} x2={440} y2={280} stroke={"gray"} strokeWidth={0.5}/>
                <line x1={160} y1={320} x2={440} y2={320} stroke={"gray"} strokeWidth={0.5}/>
                <line x1={160} y1={360} x2={440} y2={360} stroke={"gray"} strokeWidth={0.5}/>
                {this.renderCurve()}
                <VerticalLine show={this.state.show}/>
                <image href={arcLogo} x={170} y={210} width="35"/>
                <text fontSize={"9pt"} fontWeight={"bold"} x={350} y={230}>${this.state.dollarText}</text>
                <text fontSize={"9pt"} x={390} y={230}>/MWh</text>
            </svg>
        )
    }

}

export default MarketAnimation
