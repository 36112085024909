import React, { useEffect} from "react";
import {motion, AnimatePresence, useAnimation} from "framer-motion"

function _ConnectLine(props) {
    const controls = useAnimation()

    const icon = {
        hidden: {
            pathLength: 0,
        },
        visible: {
            pathLength: 1,
        }
    }

    useEffect(() => {
        controls.start("hidden")
        controls.start("visible")
    })

    return (
        <AnimatePresence>
            {/*<text key={"xsf"}>{props.rand}</text>*/}
            {
                props.show && <motion.path
                    d={props.path}
                    fill={"none"}
                    variants={icon}
                    initial="hidden"
                    animate={controls}
                    exit={{opacity: 0}}
                    opacity={1}
                    stroke={"#fff"}
                    style={{strokeWidth: 2}}
                    // onAnimationComplete={onComplete}
                    transition={{
                        default: {
                            delay: props.delay || 0,
                            duration: props.duration || 1,
                            ease: "easeInOut",
                            yoyo: props.yoyo ? Infinity : null
                        },
                    }}/>
            }
        </AnimatePresence>
    )

}

export default _ConnectLine

