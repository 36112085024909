import React from 'react';
import styled from "styled-components"
import {Link} from 'react-router-dom';

import Column from "../column/column";
import Section from "../section/section";

import logoType from '../../assets/images/logo/arctradeLogo_type.png';


const FooterContainer = styled.div`
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
    margin: 0 auto;
`
const FooterLinks = styled.div`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const FooterLink = styled(Link)`
    margin-left: 8px;
    font-size: 9pt;
`

const Footer = () => {
    const copyrightClass = {
        "fontSize": "11px",
        "marginTop": "10px"
    }

    const year = new Date().getFullYear()

    return (
        <FooterContainer className="homepage-footer at-row">
            <div>
                <div className="at-column" style={{paddingTop: "15px"}}>
                    <img alt="arctradeLogo" src={"https://cdn.arctrade.com/brand/Arctrade_Wordmark_White.svg"} width="100px"/>
                    <div style={copyrightClass}>
                        © {year} Arctrade Inc.
                    </div>
                </div>
            </div>
            <FooterLinks>
                <FooterLink to="/contact" className="footer-link">Contact</FooterLink>
                <FooterLink to="/securityInfo" className="footer-link">Security</FooterLink>
                <FooterLink to="/terms-of-service" className="footer-link">Terms of Service</FooterLink>
                <FooterLink to="/privacy-policy" className="footer-link">Privacy Policy</FooterLink>
            </FooterLinks>

        </FooterContainer>
    )
}

export default Footer
