import React from "react";
import ViewTemplate from "../../view_template"
import PageHeader from "../../../components/page-header/page-header";
import Image from "../../../assets/images/background/mountain.jpg";
import Section from "../../../components/section/section";
import Column from "../../../components/column/column";
import Label from "../../../components/text/label";
import {BLUE} from "../../../assets/styles/variables";
import Placeholder from "../../../components/ui-components/placeholder";
import ColumnTitle from "../../../components/column/column-title";
import ColumnBody from "../../../components/column/column-body";
import Button from "../../../components/button/button";
import playIcon from "../../../assets/images/icons/playicon.png";


const CRM = props => {
    return(
        <ViewTemplate pageTitle={"Retail Solutions | Pricing"}>
            <PageHeader dark bkColor="black" bkImage={Image}  title="Retail Solutions | Pricing"/>
            <Section>
                <Column colCount="10">
                    <Label color={BLUE}>
                        Built for Energy Customers
                    </Label>
                    <div className="large-text">
                        ArcTrade's Pricing Engine is the most flexible and comprehensive available on the market today. Pricing deals from 1 MWh to 1,000,000 MWh
                    </div>
                </Column>
            </Section>
            <Section>
                <Column colCount="6">
                    <Placeholder width="100%" height="200px" color="lightgray" />
                    <ColumnTitle>
                        Lorem ipsum dolor sit
                    </ColumnTitle>
                    <ColumnBody>
                        Lorem ipsum dolor sit amet, cLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu consectetur nisi. Proin quis nunc congue, euismod ipsum vitae, rhoncus purus. Praesent lobortis ultricies leo.
                        <Button small right text="Read More" />
                    </ColumnBody>
                </Column>
                <Column colCount="6">
                    <Placeholder width="100%" height="200px" color="lightgray" />
                    <ColumnTitle>
                        Lorem ipsum dolor sit
                    </ColumnTitle>
                    <ColumnBody>
                        Lorem ipsum dolor sit amet, cLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu consectetur nisi. Proin quis nunc congue, euismod ipsum vitae, rhoncus purus. Praesent lobortis ultricies leo.
                        <Button small right text="Read More" />
                    </ColumnBody>
                </Column>
            </Section>
            <Section>
                <Column colCount="6">
                    <ColumnTitle>
                        Lorem ipsum dolor sit
                    </ColumnTitle>
                    <ColumnBody>
                        Lorem ipsum dolor sit amet, cLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu consectetur nisi. Proin quis nunc congue, euismod ipsum vitae, rhoncus purus. Praesent lobortis ultricies leo.
                        <Button small right text="Read More" />
                    </ColumnBody>
                </Column>
                <Column colCount="6">
                    <Placeholder width="100%" height="200px" color="lightgray" />
                </Column>
            </Section>
            <Section>
                <Column colCount="6">
                    <Placeholder width="100%" height="200px" color="lightgray" />
                </Column>
                <Column colCount="6">
                    <ColumnTitle>
                        Lorem ipsum dolor sit
                    </ColumnTitle>
                    <ColumnBody>
                        Lorem ipsum dolor sit amet, cLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu consectetur nisi. Proin quis nunc congue, euismod ipsum vitae, rhoncus purus. Praesent lobortis ultricies leo.
                        <Button small right text="Read More" />
                    </ColumnBody>
                </Column>
            </Section>

            <Section dark>
                <Column colCount="8" className="center-vertical">
                    <Placeholder
                        width="100%"
                        height="400px"
                        color="lightgray"
                        image={playIcon}
                        imageWidth="70px"/>
                </Column>
                <Column colCount="4" className="center-vertical">
                    <ColumnTitle>
                        <Label small color="#2a2a2a">
                            Case Study
                        </Label>
                        Lorem ipsum dolor sit
                    </ColumnTitle>
                    <ColumnBody>
                        Lorem ipsum dolor sit amet, cLorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu consectetur nisi. Proin quis nunc congue, euismod ipsum vitae, rhoncus purus. Praesent lobortis ultricies leo.
                    </ColumnBody>
                </Column>
            </Section>
        </ViewTemplate>
    )
}
export default CRM