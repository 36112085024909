import React from "react";

import Mixpanel from 'mixpanel'


const mixpanelTokens = {
    "localhost:3000": "ce4379d45af565636b73312f4988fc6a",
    "qa.arctrade.com": "d8de378b9a5fbb2f2f77dbd589db8726",
    "uat.arctrade.com": "5299a45d48c9123abf64c8b203bedc7e",
    "arctrade.com": "cddbc2c6ea39e38ade18725352e81cf4",
    "demo.arctrade.com": "f1f271264285c98eec5efc4b79202e73",
}

var mixpanel = Mixpanel.init(mixpanelTokens[window.location.host] || mixpanelTokens["localhost:3000"]);


function WithMixpanel(WrappedComponent) {
    return class extends React.Component {
        render() {
            return (
               <WrappedComponent mixpanel={mixpanel}  {...this.props}/>
            )
        }
    }
}

export default WithMixpanel
