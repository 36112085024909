import React, { useEffect} from "react";
import {motion, AnimatePresence, useAnimation} from "framer-motion"

function _Mask(props) {
    const controls = useAnimation()

    const icon = {
        hidden: {
           width:0
        },
        visible: {
            width: 260
        }
    }

    useEffect(() => {
        controls.start("hidden")
        controls.start("visible")
    })

    return (
        <AnimatePresence>
            {/*<text>{props.rand}</text>*/}
            {
                props.show && <motion.rect
                    x="160" y="200" width="0" height="400"
                    variants={icon}
                    initial="hidden"
                    animate={controls}
                    exit={{ opacity: 0 }}
                    opacity={1}
                    stroke={"darkgreen"}
                    style={{strokeWidth: 2}}
                    // onAnimationComplete={onComplete}
                    transition={{
                        default: {
                            type: 'spring',
                            damping: 300,
                            // duration: 4,
                            // yoyo: Infinity,
                        },
                    }}
                />
            }
        </AnimatePresence>

    )

}

export default _Mask

