import React from "react";

import Column from "../column/column"
import Section from "../section/section"
import BackgroundImage from "../images/background-image";
import styled from "styled-components";

const MaxContentWrapper = styled.div`
     max-width: 1400px;
     width: 100%;
     margin: 0 auto;
     position: relative;
`
const Subtitle = styled.div`
     font-size: 19px;
    margin-bottom: 12px;
    @media only screen and (max-width: 700px){
        font-size: 14px;
    }
`


const PageHeader = props => {
    const headerClasses = props.dark
        ? "page-header-container dark" : "page-header-container"

    const backgroundColor = props.dark ? "#000" : "";

    return (
        <div className={headerClasses}>
            <BackgroundImage image={props.bkImage} color={backgroundColor}/>
            <Section blank>
                <MaxContentWrapper>
                    <Column colCount="12">
                        {props.subtitle
                            ? <Subtitle>{props.subtitle}</Subtitle>
                            :""}
                        <div className="page-header-title">{props.title}</div>


                    </Column>
                </MaxContentWrapper>
            </Section>
        </div>
    )
}

export default PageHeader
