import React, {useEffect, useState} from "react";
import { Grid } from  'react-loader-spinner'
import Autocomplete from "react-google-autocomplete";

import firebase from 'firebase/app';
import  'firebase/functions'

import ViewTemplate from "../view_template";
import PageHeader from "../../components/page-header/page-header";
import Section from "../../components/section/section";
import Input from "../../components/forms/input";

import styled from "styled-components";
import useQueryParams from "../../hooks/useQueryParams";
import {
	Textbox,
	Button,
	Row,
	Column,
	Form,
	Dropdown,
	NumericTextbox,
	Submit,
	Visible,
	Title,
	Modal, Card, Panel
} from "@arctrade/relay";
import {firebaseApp} from "../../firebase";
import Pdf from "../../components/Pdf";
import HowCDGWorks from "./HowCdgWorks";

const ldcs =[
	{
		"ID_Pattern": "^05\\d{20}$",
		"Short_Name": "AECO",
		"Business_Entity_Name": "Atlantic City Electric Company (Connectiv Power Delivery)",
		"Short_Name__1": "Atlantic City Electric Company (Connectiv Power",
		"Primary_State": "NJ"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "BGE",
		"Business_Entity_Name": "Baltimore Gas and Electric Company",
		"Short_Name__1": "Baltimore Gas and Electric Company",
		"Primary_State": "MD"
	},
	{
		"ID_Pattern": "^0{8}\\d{9}$",
		"Short_Name": "BHE",
		"Business_Entity_Name": "Bangor Hydro-Electric Company",
		"Short_Name__1": "Bangor Hydro-Electric Company",
		"Primary_State": "ME"
	},
	{
		"ID_Pattern": "^[1-3]\\d{10}$",
		"Short_Name": "NSTARBECO",
		"Business_Entity_Name": "Boston Edison Electric",
		"Short_Name__1": "Boston Edison Electric",
		"Primary_State": "MA"
	},
	{
		"ID_Pattern": "^[1-3]\\d{10}$",
		"Short_Name": "NSTARCAMB",
		"Business_Entity_Name": "Cambridge Electric",
		"Short_Name__1": "Cambridge Electric",
		"Primary_State": "MA"
	},
	{
		"ID_Pattern": "^[0-9]{21}$",
		"Short_Name": "CH",
		"Business_Entity_Name": "Central Hudson Gas & Electric Corporation",
		"Short_Name__1": "Central Hudson Gas & Electric Corporation",
		"Primary_State": "NY"
	},
	{
		"ID_Pattern": "^\\d{13}$",
		"Short_Name": "CMP",
		"Business_Entity_Name": "Central Maine Power Company",
		"Short_Name__1": "Central Maine Power Company",
		"Primary_State": "ME"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "COMED",
		"Business_Entity_Name": "Commonwealth Edison Company",
		"Short_Name__1": "Commonwealth Edison Company",
		"Primary_State": "IL"
	},
	{
		"ID_Pattern": "^\\d{11}|\\d{15}$",
		"Short_Name": "ConEd",
		"Business_Entity_Name": "Consolidated Edison Company of NY, Inc.",
		"Short_Name__1": "Consolidated Edison Company of NY, Inc.",
		"Primary_State": "NY"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "DUQ",
		"Business_Entity_Name": "Duquesne Light Company",
		"Short_Name__1": "Duquesne Light Company",
		"Primary_State": "PA"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "PSEG-LI",
		"Business_Entity_Name": "Long Island Power Authority",
		"Short_Name__1": "Long Island Power Authority",
		"Primary_State": "NY"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "NGMECO",
		"Business_Entity_Name": "Mass Electric",
		"Short_Name__1": "MASS ELECTRIC",
		"Primary_State": "MA"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "NGNant",
		"Business_Entity_Name": "NANTUCKET ELECTRIC",
		"Short_Name__1": "NANTUCKET ELECTRIC",
		"Primary_State": "MA"
	},
	{
		"ID_Pattern": "^N01\\d{12}$",
		"Short_Name": "NYSEG",
		"Business_Entity_Name": "New York State Electric & Gas Corporation",
		"Short_Name__1": "New York State Electric & Gas Corporation",
		"Primary_State": "NY"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "NIMO",
		"Business_Entity_Name": "National Grid - Niagara Mohawk",
		"Short_Name__1": "NIAGARA MOHAWK",
		"Primary_State": "NY"
	},
	{
		"ID_Pattern": "^\\{11}|\\d{10}$",
		"Short_Name": "O&R",
		"Business_Entity_Name": "Orange and Rockland Utilities Inc",
		"Short_Name__1": "Orange and Rockland Utilities Inc",
		"Primary_State": "NY"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "PG&E",
		"Business_Entity_Name": "Pacific Gas & Electric Company",
		"Short_Name__1": "Pacific Gas & Electric Company",
		"Primary_State": "CA"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "PECO",
		"Business_Entity_Name": "PECO Energy Company",
		"Short_Name__1": "PECO Energy Company",
		"Primary_State": "PA"
	},
	{
		"ID_Pattern": "^080\\d{17}$",
		"Short_Name": "PENELEC",
		"Business_Entity_Name": "Pennsylvania Electric Company",
		"Short_Name__1": "Pennsylvania Electric Company",
		"Primary_State": "PA"
	},
	{
		"ID_Pattern": "^080\\d{17}$",
		"Short_Name": "PENPWR",
		"Business_Entity_Name": "Pennsylvania Power Company",
		"Short_Name__1": "Pennsylvania Power Company",
		"Primary_State": "PA"
	},
	{
		"ID_Pattern": "^080\\d{17}$",
		"Short_Name": "FEPE",
		"Business_Entity_Name": "Potomac Edison Company d/b/a Allegheny Power",
		"Short_Name__1": "Potomac Edison Company d/b/a Allegheny Power",
		"Primary_State": "MD"
	},
	{
		"ID_Pattern": "^PE\\d{18}$",
		"Short_Name": "PSEG",
		"Business_Entity_Name": "Public Service Electric and Gas Company",
		"Short_Name__1": "Public Service Electric and Gas Company",
		"Primary_State": "NJ"
	},
	{
		"ID_Pattern": "^R01\\d{12}$",
		"Short_Name": "RGE",
		"Business_Entity_Name": "Rochester Gas & Electric Corporation",
		"Short_Name__1": "Rochester Gas & Electric Corporation",
		"Primary_State": "NY"
	},
	{
		"ID_Pattern": "^\\d{11}|\\d{10}$",
		"Short_Name": "RECO",
		"Business_Entity_Name": "Rockland Electric Company",
		"Short_Name__1": "Rockland Electric Company",
		"Primary_State": "NY"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "SDGE",
		"Business_Entity_Name": "San Diego Gas & Electric",
		"Short_Name__1": "San Diego Gas & Electric",
		"Primary_State": "CA"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "SCE",
		"Business_Entity_Name": "Southern California Edison",
		"Short_Name__1": "Southern California Edison",
		"Primary_State": "CA"
	},
	{
		"ID_Pattern": "^\\d{10}$",
		"Short_Name": "Unitil(MA)",
		"Business_Entity_Name": "Unitil (MA)",
		"Short_Name__1": "FGE",
		"Primary_State": "MA"
	},
	{
		"ID_Pattern": "^54\\d{9}$",
		"Short_Name": "WMECO",
		"Business_Entity_Name": "Western Massachusetts Electric Company",
		"Short_Name__1": "Western Massachusetts Electric Company",
		"Primary_State": "MA"
	}
]

const PAGE_TITLE = "Community Solar";

const MaxContentWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const Content = styled.div`
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
`

const SaveTitle = styled.div`
  width:100%;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
`
const PromoCodeContainer = styled.div`
  width: 100%;
  font-size: 23px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  background: #fafafa;
  border: 1px solid #e7e7e7;
  padding: 15px;
  border-radius: 5px;
`


const movedCopy = (utility) => {
	return `
	We were recently informed by your utility, ${utility ? `${utility},` : ""} that your electricity account was closed. As a valued member of our Community Solar network we want to 
	ensure you continue to save money on your electric bill!
	
	If you wish to continue your savings, please complete the form below
`
}

const states =['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']

async function blobToBase64(blob) {
	return new Promise((resolve, _) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.readAsDataURL(blob);
	});
}

const CdgPageSignup = () => {
	const [isValidForm,setIsValidForm] = useState(false)
	const [isSaving,setIsSaving] = useState(false)
	const [isContractCreated,setIsContractCreated] = useState(false)
	const [preAgreementBlob,setPreAgreementBlob] = useState( null)
	const [showPreContractBlob,setShowPreContractBlob] = useState( false)
	const [ipAddress,setIpAddress] = useState( null)
	const [preagreementbase64,setpreagreementbase64] = useState( null)
	const [formValues,setFormValues] = useState( null)
	const [isGeneratingPreagreement,setIsGeneratingPreagreement] = useState( false)
	const [isCompleting,setIsCompleting] = useState( false)
	const [isComplete,setIsComplete] = useState( false)
	const [isAccountNumberValid,setIsAccountNumberValid] = useState( false)
	const [showAgreementModal,setShowAgreementModal] = useState( false)
	const [agreementBlob,setAgreementBlob] = useState( null)
	const [formContext,setFormContext] = useState( null)
	const [availableLdcs,setAvailableLdcs] = useState( [])
	const [isFindingLdc,setIsFindingLdc] = useState( false)
	const [isPromoCodeValid,setIsPromoCodeValid] = useState( null)
	const [promoCodeLogo,setPromoCodeLogo] = useState( null)
	const [promoCodeMessage,setPromoCodeMessage] = useState( null)
	const [promoCodeLogoWidth,setPromoCodeLogoWidth] = useState( null)
	const [isError,setIsError] = useState( false)
	const qp = useQueryParams()
	const qparams = qp.getAll()

	const qpPromoCode = qp.get("promoCode")

	const [promoCode,setPromoCode] = useState( qparams.promoCode || "")
	const utility = qp.get("LDC")
	const fname = qp.get("FNAME")
	const lname = qp.get("LNAME")
	const email = qp.get("EMAIL")


	useEffect(() =>{
		window.location.href = "https://app.arctrade.com/app/finn/register"
	},[])

	return null

	const submit = (v) => {
		setIsGeneratingPreagreement(true)

		const values = {
			firstName: v.fname,
			lastName: v.lname,
			customerName: `${v.fname} ${v.lname}`,
			serviceAddress: `${v.serviceAddress}, ${v.serviceCity} ${v.serviceState}, ${v.serviceZipCode}`,
			address: v.serviceAddress,
			city: v.serviceCity,
			state:v.serviceState,
			zipCode: v.serviceZipCode,
			utility: v.ldcName,
			utilityAccountNumber: v.accountNumber,
			email: v.email,
			phone: v.phone,
		}
		setFormValues(values)

		var BtnTrigger = firebase.functions().httpsCallable('createAgreement');
		BtnTrigger(values).then(function(result) {
			// Read result of the Cloud Function.
			setIsGeneratingPreagreement(false)
			// setIsContractCreated(true)
			if(result.data){
				const d = Object.keys(result.data).map(k => result.data[k])
				const x = new Uint8Array(d)
				const _blob = new Blob([x], {
					type: "application/pdf",
				});
				setPreAgreementBlob(_blob)
				setShowPreContractBlob(true)
				blobToBase64(_blob)
					.then((b) => setpreagreementbase64(b))

			}
			// this will log what you have sent from Application BtnTrigger
			// { key: value}
		});
	}

	const handleCompleteAgreement = (formValues) => {
		setIsCompleting(true)

		const values = {
			...formValues,
			utility:formValues.ldcName,
			utilityAccountNumber: formValues.accountNumber,
			customerName: `${formValues.fname} ${formValues.lname}`,
			email:formValues.email,
			serviceAddress: `${formValues.serviceAddress}, ${formValues.serviceCity} ${formValues.serviceState}, ${formValues.serviceZipCode}`,
			address: formValues.serviceAddress,
			city: formValues.serviceCity,
			state:formValues.serviceState,
			zipCode: formValues.serviceZipCode,
			userAgent:window.navigator.userAgent,
			promoCode: promoCode,
			latLong: `${ipAddress?.latitude}, ${ipAddress?.longitude}`,
			ipAddress: ipAddress?.S,
			timeStamp: new Date().toTimeString(),
		}

		var BtnTrigger = firebase.functions().httpsCallable('completeAgreement');

		BtnTrigger(values).then(function(result) {
			const {data} = result

			if(data?.isError){
				setIsError(true)
			}

			setIsCompleting(false)
			setIsComplete(true)
		});
	}

	const validatePromoCode = (p) => {
		var BtnTrigger = firebase.functions().httpsCallable('validatePromoCode');

		const f = firebase
		const values = {
			promoCode: p
		}



		BtnTrigger(values).then(function(result) {
			const {data}= result
			setIsPromoCodeValid(data?.isValid)
			setPromoCodeLogo(data?.logo)
			setPromoCodeMessage(data?.message)
			setPromoCodeLogoWidth(data?.logoWidth)
		}).catch(e => {
			debugger
			console.log("ERR",e)
		})
	}

	useEffect(() => {
		if(!formValues?.serviceZipCode){
			return
		}
		setIsFindingLdc(true)
		var BtnTrigger = firebase.functions().httpsCallable('getLdcByZipCode');
		const values ={
			zipCode: parseInt(formValues.serviceZipCode)
		}
		BtnTrigger(values).then(function(result) {


			const filteredLdcs = ldcs.filter(l => {
				return result.data?.map(s =>s.Short_Name).indexOf(l.Short_Name) !== -1
			})
			setAvailableLdcs(filteredLdcs)
			setIsFindingLdc(false)
		});
	},[formValues?.serviceZipCode])

	const getIp = async () => {
		const res = await fetch('https://geolocation-db.com/json/')
		const data = await res.json()
		setIpAddress(data)
	}

	const agreement = async () => {
		const res = await fetch("https://cdn.arctrade.com/cdg/CDG+CREDIT+SUBSCRIPTION+AGREEMENTv3+DEMO.pdf")
			.then((response) => {
				const reader = response.body.getReader();
				return new ReadableStream({
					start(controller) {
						return pump();
						function pump() {
							return reader.read().then(({ done, value }) => {
								// When no more data needs to be consumed, close the stream
								if (done) {
									controller.close();
									return;
								}
								// Enqueue the next data chunk into our target stream
								controller.enqueue(value);
								return pump();
							});
						}
					},
				});
			})
			// Create a new response out of the stream
			.then((stream) => new Response(stream))
			// Create an object URL for the response
			.then((response) => response.blob())
			.then((blob) => {
				setAgreementBlob(blob)
			})
	}

	useEffect(() => {
		getIp()
		agreement()
	}, [])



	useEffect(() => {
		if(formValues?.ldcName && formValues?.accountNumber){
			const ldc = ldcs.find(l => l.Short_Name === formValues.ldcName)
			if(ldc){
				const regex = new RegExp(ldc.ID_Pattern);
				setIsAccountNumberValid(regex.test(formValues.accountNumber))
			}
		}
	}, [formValues])

	useEffect(() => {
		if(promoCode){
			validatePromoCode(promoCode)
		}
	}, [promoCode])


	return (
		<ViewTemplate pageTitle={PAGE_TITLE}>
			<PageHeader title={PAGE_TITLE}/>
			<Section>
				<MaxContentWrapper >
					<Visible visible={!isComplete && showPreContractBlob}>
						<Row center maxWidth={"500px"}>
							<Column>
								<Title>Terms and Conditions</Title>
								<p>Please review your community solar agreement below. By completing you agree to all terms and conditions outlined in the agreement.</p>
							</Column>
							<Column>
								<Button onClick={handleCompleteAgreement} isFullWidth label={"Complete Signup"}/>
							</Column>
						</Row>
						<Pdf blob={preAgreementBlob} height={"500px"}/>
					</Visible>
					<Visible visible={!isComplete && !showPreContractBlob}>
						<Row  center>

							<Column xl={6} lg={6}>
								<HowCDGWorks/>
							</Column>
							<Column xl={6} lg={6} alignCenter>
								<Visible visible={isContractCreated}>

									<SaveTitle>Community Solar Savings Agreement</SaveTitle>
									You will shortly be receiving an email containing a link to your community solar savings agreement. In order to complete your signup and to start receiving savings, please complete the agreement as soon as possible!
								</Visible>
								<Visible visible={!isContractCreated}>
									<Panel>

										<Column>
											<SaveTitle>Lets Get Started!</SaveTitle>

											<Content>
												Community solar is a great way to save on your electricity bill while helping to support the transition to green energy!
												{/*{movedCopy(utility)}*/}
											</Content>
											<Visible visible={promoCodeLogo}>
												<PromoCodeContainer>
													<Visible visible={promoCodeLogo}>
														<img alt={"promoLog"} src={promoCodeLogo} width={promoCodeLogoWidth}/>
													</Visible>
													<Visible visible={promoCodeMessage}>
														<Content>
															{promoCodeMessage}
														</Content>
													</Visible>
												</PromoCodeContainer>
											</Visible>
										</Column>
										<Form
											onMount={(d) => {
												setFormContext(d)
											}}
											onSubmit={({values}) => {
												if(isValidForm){
													handleCompleteAgreement(values)
												}
											}}
											onChange={({values}) => {
												const {email, phone, fname,lname, ldcName,accountNumber} = values
												setFormValues(values)
												let isValid = true

												if(!email
													|| !phone
													|| !fname
													|| !lname
													|| !ldcName
													|| !accountNumber
												){
													isValid = false
												}

												console.log({
													email,
													phone,
													lname,
													fname,
													ldcName,accountNumber
												})
												if(isValidForm !== isValid){
													setIsValidForm(isValid)
												}

											}} style={{width: "100%"}} initialValues={{
											email,
											fname,
											lname,
											utility
										}}>
											{(formContext) => {
												return <Row>
													<Row noPadding>
														<Column xl={6} lg={6} md={6}>
															<Textbox name={"fname"} label={"First Name"}/>
														</Column>
														<Column xl={6} lg={6} md={6}>
															<Textbox name={"lname"} label={"Last Name"}/>
														</Column>
													</Row>

													<Row noPadding>

														<Column xl={6} lg={6}>
															<Textbox name={"email"}/>
														</Column>
														<Column xl={6} lg={6}>
															<Textbox name={"phone"}/>
														</Column>
													</Row>
													<Column>
														<p>The Service Address of your Utility Account:</p>
														<Autocomplete
															apiKey={"AIzaSyCW_DNBlnD1MYCjsqrMScVBb7cLDKCzFvY"}
															style={{
																width: "100%",
																outline: "none",
																minHeight: "30px",
																display: "flex",
																padding: "5px 8px",
																borderRadius: "4px",
																border: "1px solid #dedfe0",
																fontSize: "10pt",
																fontWeight: "500",
																background: "#fff",
																color: "#1B076F",
															}}
															onPlaceSelected={(place) => {
																console.log(place);
																let address = ""
																let city= ""
																let state= ""
																let zipCode= ""

																place.address_components.forEach(c => {
																	if(c.types.indexOf('street_number') !== -1){
																		address += (c.short_name + " ")
																	}
																	if(c.types.indexOf('route') !== -1){
																		address += c.short_name
																	}
																	if(c.types.indexOf("locality") !== -1){
																		city += c.short_name
																	}
																	if(c.types.indexOf("administrative_area_level_1") !== -1){
																		state += c.short_name
																	}
																	if(c.types.indexOf("postal_code") !== -1){
																		zipCode += c.short_name
																	}
																})
																formContext.setValue("serviceAddress", address)
																formContext.setValue("serviceCity", city)
																formContext.setValue("serviceState", state)
																formContext.setValue("serviceZipCode", zipCode)
																formContext.setValue("ldcName", null)
																formContext.setValue("accountNumber", null)
															}}
															placeholder={"Service Address"}
															options={{
																fields: ["address_components", "geometry"],
																types: ["address"],
																componentRestrictions: { country: "us" },
															}}
														/>
													</Column>
													<Visible visible={formValues?.serviceState && ldcs.filter((l) => {
														return l.Primary_State === formValues?.serviceState
													}).length ===0}>
														<Column>
															We are sorry but it looks like we don't service your state yet!
														</Column>
													</Visible>
													<Visible visible={isFindingLdc}>
														<Column>
															Finding your utility...
														</Column>
													</Visible>
													<Visible visible={!isFindingLdc && formValues?.serviceZipCode}>
														<Column xl={6} lg={6}>
															<Dropdown
																isDisabled={!formValues?.serviceState}
																textField={"Business_Entity_Name"}
																valueField={"Short_Name"}
																dataSource={availableLdcs} name={"ldcName"} label={"Utility"}/>
														</Column>

														<Column xl={6} lg={6}>
															<Textbox name={"accountNumber"} label={"Utility Account Number"}/>
														</Column>
														<Visible visible={!isAccountNumberValid}>
															<Column>
																<Card type={"danger"}>
																	Please ensure the account number entered is correct
																</Card>
															</Column>
														</Visible>
													</Visible>
													<Column>
														<a style={{textAlign:"center", width:"100%",color:"blue"}} onClick={() => setShowAgreementModal(true)}>By completing the signup process you agree to the terms and conditions outlined here</a>
													</Column>
													<Column>
														<Submit
															size={"lg"}
															isFullWidth
															isDisabled={!isValidForm}
															isLoading={isSaving}
															loadingText={"Generating Agreement"}
															// disabledTooltip={"Please complete form"} isDisabled={!isValidForm}
															label={"Sign Up!"}/>
													</Column>
												</Row>
											}}

										</Form>
									</Panel>

								</Visible>
							</Column>
						</Row>
					</Visible>

					<Visible visible={isComplete && !isError}>
						<Row  center>
							<Column alignCenter>
								<div style={{fontSize:"20px",fontWeight: 600, marginBottom:"10px"}}>
									Thank you for signing up!
								</div>
								<div>We will be in  touch once you are assigned to a project</div>
							</Column>
						</Row>
					</Visible>
					<Visible visible={isComplete && isError}>
						<Row  center>
							<Column alignCenter>
								<div style={{fontSize:"20px",fontWeight: 600, marginBottom:"10px"}}>
									It looks like something went wrong.
								</div>
								<div>For more help please contact us at cdgsupport@arctrade.com</div>
							</Column>
						</Row>
					</Visible>

				</MaxContentWrapper>
			</Section>
			<Modal noTitleBar show={isGeneratingPreagreement}>
				<Row>
					<Column alignCenter justifyCenter>
						<Grid
							height="80"
							width="30"
							color="#37002d"
							ariaLabel="grid-loading"
							radius="12.5"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
						<p style={{maxWidth:"400px", textAlign:"center",fontWeight: 500,color:"#37002d"}}>Hold on tight we are generating your agreement!</p>
					</Column>
				</Row>
			</Modal>
			<Modal noTitleBar show={isCompleting}>
				<Row>
					<Column alignCenter justifyCenter>
						<Grid
							height="80"
							width="30"
							color="#37002d"
							ariaLabel="grid-loading"
							radius="12.5"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
						/>
						<p style={{fontWeight: 500,color:"#37002d"}}>
							We are completing your signup!
						</p>
					</Column>
				</Row>
			</Modal>
			<Modal noTitleBar show={false}>
				<HowCDGWorks/>
			</Modal>
			<Modal width={"800px"} maxWidth={"95vw"} title={"Community Solar Agreement"} onClose={() => setShowAgreementModal(false)} show={showAgreementModal}>
				<Pdf blob={agreementBlob} height={"600px"}/>
			</Modal>
		</ViewTemplate>
	)
}


export default CdgPageSignup

