import {motion} from "framer-motion"
import"./styles.scss"
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTheme} from "@arctrade/relay";
import { v4 as uuidv4 } from "uuid";
import {debounce} from "lodash";

export const useResizeKey = () => {
    const wait = 400
    const [key, setKey] = useState(uuidv4())


    const resize = () => {
        setKey(() => uuidv4())
    }

    useEffect(() => {
        window.addEventListener("resize", debounce(resize, wait))
        return () => {
            window.removeEventListener("resize",debounce(resize, wait))
        }
    },[])

    return [key]
}


const Box = ({initialRotate,speed,delay,boxSize,offsetX, offsetY}) => {
    const animation = {
        rotate: [initialRotate, -initialRotate,initialRotate],
    };

    const transition = {
        duration: speed,
        ease: "anticipate",
        // times: [0, 0.2, 0.5, 0.8, 1],
        loop: Infinity,
        repeatDelay: 0,
    };

    return <svg

        style={{border:"0px solid #a5a5a5",width: boxSize+"px", height:boxSize+"px"}}
        className={"box-container"}
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 176.83 121.33">
        <defs>
            {/*<style></style>*/}
        </defs>
        <motion.g
            initial={{
                rotate: initialRotate,
                x:  offsetX,
                y: -100+  offsetY,
                scale: 1 }}
            animate={animation}
            // animate={{ rotate: [initialRotate,360], }}
            transition={transition}
            // transition={{ ease: "linear", delay, duration: speed, repeat: Infinity }}

            id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Ldayer 1">
                <path className="cls-1"
                      d="M88.41,115.08c46.33-12.76,87.92-49.78,87.92-77.76,0-24.8-25.47-48.1-87.92-30.89V6.25C42.09,19,.5,56,.5,84c0,24.8,25.46,48.1,87.91,30.89"/>
            </g>
        </motion.g>
        {/*<text fill={"#fff"} x="20" y="35" className="small">[{offsetX},{offsetY},{speed}]</text>*/}
    </svg>
}

const Graphic = () => {
    const [speed, setSpeed] = useState(40)
    const [containerSize, setContainerSize] = useState([0,0])
    const [rowBoxCount, setRowCount] = useState(5)
    const ref= useRef()
    const initialRotate = Math.floor(Math.random() * (360 - 1 + 1) +1)
    const [resizeKey] = useResizeKey()
    const {theme} = useTheme()
    const {screenSizeConfig} = theme
    const {screenSizeContext} = screenSizeConfig

    const box = ""
    const renderBoxes = useCallback((style = {}) => {
        const offsetAmt = 20
        const rowCount = rowBoxCount
        const rowHeight = 5
        const boxSize = 50
        const boxContainerSize = boxSize* rowCount
        const total = rowCount * rowHeight
        let curr = 0
        let currCol = 0
        let row= 0
        const boxes= []
        let key = 0
        while (curr < total){
            key++
            const currentColumn = currCol
            let offsetX = currentColumn * offsetAmt
            let offsetY = row * offsetAmt
            const delay = currentColumn* 1
            boxes.push(<Box
                key={key}
                initialRotate={initialRotate}
                delay={delay}
                speed={speed} boxSize={boxSize} offsetX={offsetX} offsetY={offsetY} />)

            curr++
            currCol++
            if(currentColumn === rowCount -1){
                currCol = 0
                row++
            }
        }

        const container = {
            show: {
                transition: {
                    staggerChildren: 2000
                }
            }
        }

        return <motion.div
            variants={container}
            initial="hidden"
            animate="show" style={{display:"flex",alignItems:"flex-start",flexWrap: "wrap", width:boxContainerSize+"px", ...style}}>
            {
                boxes
            }
        </motion.div>
    }, [resizeKey,speed,rowBoxCount])

    const handleSetSpeed = () => {
        return
        setSpeed(prev => {
            return prev + 10
        })

        setTimeout(handleSetSpeed, 500)
        // setRowCount(Math.floor(Math.random() * (14 - 5 + 1) + 5))
        // setSpeed(Math.floor(Math.random() * (200 - 1 + 1) + 1))
    }
    const setSize = () => {

        if(ref && ref.current){
            // @ts-ignore
            const rect = ref?.current?.getBoundingClientRect()
            const {width,height} = rect
            setContainerSize([width,height])
        }
    }

    useEffect(() => {
        // setInterval(handleSetSpeed,1000)
        window.addEventListener("resize",setSize)
    },[])

    useEffect(() => {
        setSize()
        handleSetSpeed()
    },[ref.current])

    const boxes = () => {
        return  <div style={{
            borderCollapse:"collapse",width:"500px", alignItems:"flex-start", flexWrap:"wrap", display:"flex"}}>
            {renderBoxes()}
            {renderBoxes({  transform: "rotateY(180deg)"})}
            {renderBoxes({transform: "rotateX(180deg)"})}
            {renderBoxes({  transform: "rotateY(180deg) rotateX(180deg)"})}
        </div>
    }

    const renderBigBoxes = useMemo(() =>{

        const boxesSize = 500
        let columnsNeeded = Math.ceil(containerSize[0] / boxesSize)
        let rowsNeeded = Math.ceil(containerSize[1] / boxesSize)

        // if(columnsNeeded % 2 === 0){
        //     columnsNeeded++
        // }
        // if(rowsNeeded % 2 === 0){
        //     rowsNeeded++
        // }
        const total = columnsNeeded * rowsNeeded

        const bigBoxes = []
        // return
        for (let i = 0; i < total; i++) {
            bigBoxes.push(boxes())
        }
        return <div style={{
            display:'flex',
            flexWrap:"wrap",
            background:'#919191',
            position: "absolute",
            outline: "1px solid gray",
            borderCollapse: "collapse",
            width: boxesSize * columnsNeeded + "px"}}>
            {bigBoxes}
        </div>

    },[resizeKey,containerSize, speed, rowBoxCount])

    const scale = useMemo(() => {
        if(window.innerWidth < 700){
          return   1
        }
        return 1.3

    }, [resizeKey])

    return <div ref={ref} style={{
        overflow:"hidden",
        background:"#33102b",
        position:'relative',
        justifyContent:"center",
        width: "100%",
        height: screenSizeContext === "sm"
        || screenSizeContext === "xs"
            ?"100%"
            :"100vh",
        alignItems:"center",
        flexWrap:"wrap", display:"flex"}}>
        <div style={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            width:"100%", height:"100%",transform: "scale("+scale+")"}}>
            {renderBigBoxes}
        </div>

        <div style={{
            overflow:"hidden",
            background:"#33102b",
            position:'absolute',
            zIndex:99
           }}>
            {/*<div>{containerSize}</div>*/}
            {/*<div>speed {speed}</div>*/}

        </div>
    </div>
}
export default Graphic